import { Today, DescriptionOutlined, GroupAdd, SmsOutlined, InsertDriveFileOutlined } from "@mui/icons-material";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import { Box } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import ThreePIcon from "@mui/icons-material/ThreeP";
import PeopleIcon from "@mui/icons-material/People";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import DynamicFormIcon from "@mui/icons-material/DynamicForm";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";

import {
	DASHBOARDICON,
	NETWORKICON_GREY,
	NOTESICON,
	QUESTION_ICON,
	USERSICON,
	WORKSPACEICON,
	MENTOR_PROFILE,
	NAV_WORK,
	NAV_SETTINGS,
	NAV_MESSAGES,
	NAV_LOGOUT,
	NAV_EVENT,
	NAV_DISCOVERY,
	NEW_COMMUNITY_ICON,
	DISCOVERY_ICON,
	NEW_NOTE_ICON,
	UPDATED_DASHBOARD_ICON,
	UPDATED_MENTOR_ICON,
	UPDATED_WORKSPACE_ICON,
	UPDATED_STARTUP_ICON,
	UPDATED_WHITE_WORKSPACE_ICON,
	UPDATED_WHITE_DASHBOARD_ICON,
	UPDATED_WHITE_STARTUP_ICON,
	MARKETPLACEICON,
	NETWORK_WHITE_ICON,
	NOTES_ICON_NEW,
	WHITE_FEED_ICON,
	ACTIVE_HOME_ICON,
	ACTIVE_DASHBOARD_ICON,
	ACTIVE_STARTUP_PROFILE_ICON,
	ACTIVE_COMMUNITY_ICON,
	ACTIVE_FEED_ICON,
	ACTIVE_STARTUP_GRID_ICON,
	ACTIVE_DISCOVERY_ICON,
	ACTIVE_RESOURCE_CENTER_ICON,
	ACTIVE_ACCOUNT_PROFILE_ICON,
	HEART_HAND_BLACK,
	HEART_HAND_WHITE,
} from "./image";
import ROUTES_PATH from "./routes";
import { sosPrimary } from "./Theme/colors";
import SvgComponent from "../utils/SVGColor";

export const INVESTOR_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		link: "/investor/dashboard/startups",
		approvalRequired: true,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "Network",
		link: ROUTES_PATH.MENTOR_NETWORK,
		approvalRequired: true,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_RESOURCE_CENTER_ICON} sx={{ width: "40px" }} />,
		label: "Resource Center",
		link: ROUTES_PATH.RESOURCE_CENTER,
		approvalRequired: false,
	},
];

export const PITCHDECK_CUSTOMER_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_HOME_ICON} sx={{ width: "40px" }} />,
		label: "Reports",
		link: ROUTES_PATH.PITCHDECK_WORKSPACE_ROOT,
		approvalRequired: false,
	},
];

export const LOCKED_INVESTOR_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		link: "/investor/dashboard/startups",
		approvalRequired: true,
	},
];
export const STARTUP_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_HOME_ICON} sx={{ width: "40px" }} />,
		label: "Home",
		link: ROUTES_PATH.STARTUPS_HOME,
		approvalRequired: false,
	},
	// {
	// 	icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
	// 	label: "Dashboard",
	// 	link: ROUTES_PATH.FOUNDER_DASHBOARD,
	// 	approvalRequired: false,
	// },
	{
		icon: <Box component={"img"} src={ACTIVE_STARTUP_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Startup",
		link: ROUTES_PATH.MY_STARTUP_PROFILE,
		approvalRequired: false,
	},

	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "Network",
		link: ROUTES_PATH.NETWORK,
		approvalRequired: true,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_FEED_ICON} sx={{ width: "40px" }} />,
		label: "Social Feed",
		link: ROUTES_PATH.FEED_PATH,
		approvalRequired: false,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_STARTUP_GRID_ICON} sx={{ width: "40px" }} />,
		label: "Startup Grid",
		link: ROUTES_PATH.STARTUPGRID,
		approvalRequired: false,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
		approvalRequired: false,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_RESOURCE_CENTER_ICON} sx={{ width: "40px" }} />,
		label: "Resource Center",
		link: ROUTES_PATH.RESOURCE_CENTER,
		approvalRequired: false,
	},
];
export const MENTOR_LEFT_SIDEBAR_ITEMS = [
	// {
	// 	icon: <DashboardIcon />,
	// 	label: "Dashboard",
	// 	link: ROUTES_PATH.MENTOR_DASHBOARD,
	// 	approvalRequired: false,
	// },
	{
		icon: <Box component={"img"} src={ACTIVE_ACCOUNT_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Profile",
		link: ROUTES_PATH.MY_MENTOR_PROFILE,
		approvalRequired: false,
	},

	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "Network",
		link: ROUTES_PATH.MENTOR_NETWORK,
		approvalRequired: false,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_RESOURCE_CENTER_ICON} sx={{ width: "40px" }} />,
		label: "Resource Center",
		link: ROUTES_PATH.RESOURCE_CENTER,
		approvalRequired: false,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_FEED_ICON} sx={{ width: "40px" }} />,
		label: "Social Feed",
		link: ROUTES_PATH.FEED_PATH,
		approvalRequired: false,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
		approvalRequired: false,
	},
];
export const PARTNER_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "Network",
		link: ROUTES_PATH.MENTOR_NETWORK,
		approvalRequired: false,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Opportunity Dashboard",
		link: ROUTES_PATH.INVESTOR_DASHBOARD,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "Event Dashboard",
		link: ROUTES_PATH.EVENT_DASHBOARD,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "PowerupPack Dashboard",
		link: ROUTES_PATH.POWERPACK_DASHBOARD,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "Partner Admin QA",
		link: ROUTES_PATH.PARTNER_ADMIN_QA,
		approvalRequired: false,
	},
];
export const ADMIN_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Opportunity Dashboard",
		link: ROUTES_PATH.INVESTOR_DASHBOARD,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "PowerUp Builder",
		link: ROUTES_PATH.PARTNER_DASHBOARD,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "Pitch Deck Compiler",
		link: ROUTES_PATH.PITCH_DECK_COMPILER,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "Event Dashboard",
		link: ROUTES_PATH.EVENT_DASHBOARD,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "PowerupPack Dashboard",
		link: ROUTES_PATH.POWERPACK_DASHBOARD,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "Partner Admin QA",
		link: ROUTES_PATH.PARTNER_ADMIN_QA,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "Startup Stats",
		link: ROUTES_PATH.QUICK_SIGHT,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "Company and Users Import",
		link: ROUTES_PATH.ADMIN_COMPANY_USERS_IMPORT,
		approvalRequired: false,
	},
	{
		icon: <DashboardIcon />,
		label: "Admin Form Manager",
		link: ROUTES_PATH.ADMIN_FORM_MANAGER,
		approvalRequired: false,
	},
	// {
	// 	icon: <SvgComponent strokeColor={"white"} svgUrl={NOTES_ICON_NEW} style={{ height: "24px", width: "24px" }} />,
	// 	label: "Resource Center",
	// 	link: ROUTES_PATH.RESOURCE_CENTER,
	// 	approvalRequired: false,
	// },

	// {
	// 	icon: <DashboardIcon />,
	// 	label: "Startup Grid",
	// 	link: ROUTES_PATH.STARTUPGRID,
	// 	approvalRequired: false,
	// },
];

export const GLOBAL_NAVIGATION_ITEMS = [
	{
		icon: <Today />,
		label: "Events",
		link: ROUTES_PATH.EVENTS_BASEPATH,
		approvalRequired: false,
	},
	{
		icon: <GroupAdd />,
		label: "Meetings",
		link: ROUTES_PATH.MEETINGS_BASEPATH,
		approvalRequired: true,
	},
	{
		icon: <SmsOutlined />,
		label: "Messages",
		link: ROUTES_PATH.MESSAGES,
		approvalRequired: true,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
		approvalRequired: false,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_RESOURCE_CENTER_ICON} sx={{ width: "40px" }} />,
		label: "Resource Center",
		link: ROUTES_PATH.RESOURCE_CENTER,
		approvalRequired: false,
	},

	/* New Partner Page Nav Button is added in below commented code for small screens */

	// {
	// 	icon: <Box component={"img"} src={HEART_HAND_WHITE} sx={{ width: "26px" }} />,
	// 	label: "Partners",
	// 	link: ROUTES_PATH.PARTNER_BASEPATH,
	// 	approvalRequired: false,
	// },
];

/* * * * * * *  
	The Items Below Are Legacy Items
	We are moving to UI 2.0, 
	so we are keeping the lower items incase something was missed.
	Will be cleaned up eventually.
* * * * * * */

const STARTUP_MYPROFILE = ROUTES_PATH.MY_STARTUP_PROFILE;
const STARTUP_PROFILE = ROUTES_PATH.MY_STARTUP_PROFILE;

export const WORKSPACE_SIDEBAR_MAP = {
	workspace: "Workspace",
	dashboard: "Dashboard",
	network: "My Network",
	mystartup: "My Startup",
	myProfile: "My Profile",
};

export const WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_HOME_ICON} sx={{ width: "40px" }} />,
		label: "Home",
		key: "home",
		link: ROUTES_PATH.INVESTOR_WORKSPACE,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "dashboard",
		// link: ROUTES_PATH.DASHBOARD,
		link: ROUTES_PATH.FOUNDER_DASHBOARD,
	},

	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "My Network",
		key: "network",
		link: ROUTES_PATH.NETWORK,
	},
	// {
	//   icon: <ThreePIcon />,
	//   label: "Network",
	//   key: "community",
	//   external: true,
	//   link: "https://community.startupos.com",
	//   target: "_blank",
	// },
	{
		icon: <Box component={"img"} src={ACTIVE_STARTUP_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Startup",
		key: "mystartup",
		link: STARTUP_MYPROFILE,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		key: "discovery-board",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
	},
];

export const WORKSPACE_RIGHT_SIDEBAR_ITEMS = [
	{
		icon: NEW_NOTE_ICON,
		label: "Notes",
		key: "notes",
	},
];

export const DEFAULT_WORKSPACE_LEFT_SIDEBAR_ITEMS = [];

export const MENTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	// {
	// 	icon: <DashboardIcon />,
	// 	label: "Dashboard",
	// 	key: "dashboard",
	// 	link: ROUTES_PATH.MENTOR_DASHBOARD,
	// },
	{
		icon: <Box component={"img"} src={ACTIVE_ACCOUNT_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Profile",
		key: "dashboard",
		link: ROUTES_PATH.MY_MENTOR_PROFILE,
	},

	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "My Network",
		key: "network",
		link: ROUTES_PATH.MENTOR_NETWORK,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		key: "discovery-board",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
	},
];

export const MENTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	// {
	// 	icon: <DashboardIcon />,
	// 	label: "Dashboard",
	// 	key: "dashboard",
	// 	link: ROUTES_PATH.MENTOR_DASHBOARD,
	// },
	{
		icon: <Box component={"img"} src={ACTIVE_ACCOUNT_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Profile",
		key: "dashboard",
		link: ROUTES_PATH.MY_MENTOR_PROFILE,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		key: "discovery-board",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
	},
];

export const INVESTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "investor-dashboard",
		link: "/investor/dashboard/startups",
		approvalRequired: false,
	},
];

export const INVESTOR_APPROVED_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "investor-dashboard",
		link: "/investor/dashboard/startups",
	},
];

//export const INVESTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
//	{
//		icon: <Box component={"img"} src={UPDATED_WHITE_DASHBOARD_ICON} />,
//		label: "Dashboard",
//		key: "investor-dashboard",
//		link: ROUTES_PATH.INVESTOR_DASHBOARD,
//	},
//];

export const UPDATED_FOUNDER_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_HOME_ICON} sx={{ width: "40px" }} />,
		label: "Home",
		key: "home",
		link: ROUTES_PATH.INVESTOR_WORKSPACE,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "dashboard",
		// link: ROUTES_PATH.DASHBOARD,
		link: ROUTES_PATH.FOUNDER_DASHBOARD,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_STARTUP_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Startup",
		key: "mystartup",
		// link: ROUTES_PATH.STARTUP_MYPROFILE,
		link: STARTUP_PROFILE,
	},
];

export const UPDATED_MENTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	// {
	// 	icon: <Box component={"img"} src={UPDATED_WHITE_DASHBOARD_ICON} />,
	// 	label: "Dashboard",
	// 	key: "mentor-dashboard",
	// 	link: ROUTES_PATH.MENTOR_DASHBOARD,
	// },
	{
		icon: <Box component={"img"} src={ACTIVE_ACCOUNT_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Profile",
		key: "mentor-dashboard",
		link: ROUTES_PATH.MY_MENTOR_PROFILE,
	},
];

export const PARTNER_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "dashboard",
		link: ROUTES_PATH.PARTNER_DASHBOARD,
	},
];
export const STUDENT_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_HOME_ICON} sx={{ width: "40px" }} />,
		label: "Home",
		key: "home",
		link: ROUTES_PATH.INVESTOR_WORKSPACE,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "dashboard",
		// link: ROUTES_PATH.DASHBOARD,
		link: ROUTES_PATH.FOUNDER_DASHBOARD,
	},

	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "My Network",
		key: "network",
		link: ROUTES_PATH.NETWORK,
	},
	// {
	//   icon: <ThreePIcon />,
	//   label: "Network",
	//   key: "community",
	//   external: true,
	//   link: "https://community.startupos.com",
	//   target: "_blank",
	// },
	{
		icon: <Box component={"img"} src={ACTIVE_STARTUP_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Startup",
		key: "mystartup",
		link: STARTUP_MYPROFILE,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		key: "discovery-board",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
	},
];
export const INSTRUCTOR_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "dashboard",
		link: ROUTES_PATH.INSTRUCTOR_DASHBOARD,
	},

	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "My Network",
		key: "network",
		link: ROUTES_PATH.INSTRUCTOR_NETWORK,
	},
	// {
	//   icon: <ThreePIcon />,
	//   label: "Network",
	//   link: "https://community.startupos.com",
	//   key: "community",
	//   external: true,
	//   target: "_blank",
	// },
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		key: "discovery-board",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
	},
];

export const ADVISOR_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "dashboard",
		link: ROUTES_PATH.ADVISOR_DASHBOARD,
	},
];

export const CANDIDATE_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "dashboard",
		link: ROUTES_PATH.CANDIDATE_DASHBOARD,
	},
];

export const MENTOR_WORKSPACE_RIGHT_SIDEBAR_ITEMS = [
	{
		customIcon: <Today sx={{ color: sosPrimary[500] }} />,
		label: "Events",
		key: "events",
	},
	{
		customIcon: <Today sx={{ color: sosPrimary[500] }} />,
		label: "Meetings",
		key: "meetings",
	},
	{
		customIcon: <ChatBubbleOutlineOutlinedIcon sx={{ color: sosPrimary[500] }} />,
		label: "Message",
		key: "messages",
	},
	{
		customIcon: <Box component="img" src={NEW_NOTE_ICON} />,
		label: "Notes",
		key: "notes",
	},
];

export const ADVISOR_WORKSPACE_RIGHT_SIDEBAR_ITEMS = [
	{
		customIcon: <Today sx={{ color: sosPrimary[500] }} />,
		label: "Events",
		key: "events",
	},
	{
		customIcon: <Today sx={{ color: sosPrimary[500] }} />,
		label: "Meetings",
		key: "meetings",
	},
	{
		customIcon: <ChatBubbleOutlineOutlinedIcon sx={{ color: sosPrimary[500] }} />,
		label: "Message",
		key: "messages",
	},
	{
		customIcon: <Box component="img" src={NEW_NOTE_ICON} />,
		label: "Notes",
		key: "notes",
	},
];

export const CANDIDATE_WORKSPACE_RIGHT_SIDEBAR_ITEMS = [
	{
		customIcon: <Today />,
		label: "Events",
		key: "events",
	},
	{
		customIcon: <Today />,
		label: "Meetings",
		key: "meetings",
	},
	{
		customIcon: <ChatBubbleOutlineOutlinedIcon />,
		label: "messages",
		key: "messages",
	},
];

export const ADMIN_WORKSPACE_LEFT_SIDEBAR_ITEMS = [
	{
		icon: <Box component={"img"} src={ACTIVE_HOME_ICON} sx={{ width: "40px" }} />,
		label: "Home",
		key: "home",
		link: ROUTES_PATH.INVESTOR_WORKSPACE,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DASHBOARD_ICON} sx={{ width: "40px" }} />,
		label: "Dashboard",
		key: "dashboard",
		// link: ROUTES_PATH.DASHBOARD,
		link: ROUTES_PATH.FOUNDER_DASHBOARD,
	},

	{
		icon: <Box component={"img"} src={ACTIVE_COMMUNITY_ICON} sx={{ width: "40px" }} />,
		label: "My Network",
		key: "network",
		link: ROUTES_PATH.NETWORK,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_STARTUP_PROFILE_ICON} sx={{ width: "40px" }} />,
		label: "My Startup",
		key: "mystartup",
		link: STARTUP_MYPROFILE,
	},
	// {
	// 	icon: <DashboardIcon />,
	// 	label: "User Support Access",
	// 	key: "impersonate",
	// 	link: ROUTES_PATH.IMPERSONATE,
	// },
	{
		icon: <DashboardIcon />,
		label: "PowerUp Builder",
		key: "powerup-builder",
		link: ROUTES_PATH.PARTNER_DASHBOARD,
	},
	{
		icon: <DashboardIcon />,
		label: "Opportunity Dashboard",
		key: "investor-dashboard",
		link: ROUTES_PATH.INVESTOR_DASHBOARD,
	},
	{
		icon: <Box component={"img"} src={ACTIVE_DISCOVERY_ICON} sx={{ width: "40px" }} />,
		label: "Discovery",
		key: "discovery-board",
		link: ROUTES_PATH.RESEARCH_CURATOR_EXPAND,
	},
];

export const INVESTOR_WORKSPACE_RIGHT_SIDEBAR_ITEMS = [];

export const PARTNER_WORKSPACE_RIGHT_SIDEBAR_ITEMS = [];
export const INSTRUCTOR_WORKSPACE_RIGHT_SIDEBAR_ITEMS = [
	{
		customIcon: <Today sx={{ color: sosPrimary[500] }} />,
		label: "Events",
		key: "events",
	},
	{
		customIcon: <Today sx={{ color: sosPrimary[500] }} />,
		label: "Meetings",
		key: "meetings",
	},
	{
		customIcon: <ChatBubbleOutlineOutlinedIcon sx={{ color: sosPrimary[500] }} />,
		label: "Message",
		key: "messages",
	},
	{
		customIcon: <Box component="img" src={NEW_NOTE_ICON} />,
		label: "Notes",
		key: "notes",
	},
];
export const STUDENT_WORKSPACE_RIGHT_SIDEBAR_ITEMS = [];

export const USER_TYPES = {
	STARTUP_USER: "STARTUP_OWNER",
	ADVISOR_USER: "ADVISOR_USER",
	MENTOR_USER: "MENTOR_USER",
	VERIFIED_MENTOR_USER: "VERIFIED_MENTOR_USER",

	INVESTOR_USER: "INVESTOR_USER",
	CANDIDATE_USER: "CANDIDATE_USER",
	STARTUP_OWNER: "STARTUP_OWNER",
	PARTNER_USER: "PARTNER_USER",
	PROFESSOR_USER: "PROFESSOR_USER",
	STUDENT_USER: "STUDENT_USER",
};

export const NAVBAR_TOOLS = [
	{
		icon: <ShoppingCartOutlinedIcon />,
		label: "Marketplace",
		key: "marketplace",
		external: true,
		target: "_blank",
		link: "https://startupos.getproven.com/",
	},
	{
		icon: <Today />,
		label: "Events",
		key: "events",
		link: ROUTES_PATH.EVENTS_BASEPATH,
	},
	{
		icon: <Today />,
		label: "Meetings",
		key: "meetings",
		link: ROUTES_PATH.MEETINGS_BASEPATH,
	},
	{
		icon: <ChatBubbleOutlineOutlinedIcon />,
		label: "Messages",
		key: "messages",
		link: ROUTES_PATH.MESSAGES,
	},
];
