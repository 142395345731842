import {
	EggIcon,
	ChickIcon,
	MiniRunnerIcon,
	MiniRoaderRunnerIcon,
	SoftwareIcon,
	HardwareIcon,
	ServiceIcon,
	MarketPlaceIcon,
	ConsumerGoodsIcon,
	EyeVisionIcon,
	HeartIcon,
	EnergyIcon,
	FlowerIcon,
	CommunityIcon,
	GritIcon,
	NetworkIcon,
	ExecutionIcon,
	SideHustleIcon,
	PartTimeIcon,
	FullTimeIcon,
	OverTimeIcon,
	CustomerIcon,
	MarketAnalysisIcon,
	ChargeIcon,
	GlowBulbIcon,
	SmileIcon,
	FundingIcon,
	LaunchIcon,
	MentorIcon,
	AdvisorIcon,
} from "../common/icons";
import ROUTES_PATH from "./routes";
import {
	VELVET_ELECTRIC,
	BLUE_FUSION,
	SPARK_ORANGE,
	COVER_IMAGE_TEMPLATE_1,
	COVER_IMAGE_TEMPLATE_2,
	COVER_IMAGE_TEMPLATE_3,
	COVER_IMAGE_TEMPLATE_4,
	COVER_IMAGE_TEMPLATE_5,
	COVER_IMAGE_TEMPLATE_6,
	COVER_IMAGE_TEMPLATE_7,
	COVER_IMAGE_TEMPLATE_8,
	COVER_IMAGE_TEMPLATE_9,
	COVER_IMAGE_TEMPLATE_10,
	COVER_IMAGE_TEMPLATE_11,
	COVER_IMAGE_TEMPLATE_12,
	COVER_IMAGE_TEMPLATE_13,
	COVER_IMAGE_TEMPLATE_14,
	COVER_IMAGE_TEMPLATE_15,
	COVER_IMAGE_TEMPLATE_16,
	COVER_IMAGE_TEMPLATE_17,
	COVER_IMAGE_TEMPLATE_18,
	COVER_IMAGE_TEMPLATE_19,
	COVER_IMAGE_TEMPLATE_20,
	COVER_IMAGE_TEMPLATE_21,
	COVER_IMAGE_TEMPLATE_22,
	COVER_IMAGE_TEMPLATE_23,
	COVER_IMAGE_TEMPLATE_24,
	FACE_HAPPY_BLACK_ICON,
	PITCH_DECK_BLACK_ICON,
	SWITCH_BLACK_ICON,
	ACTIVITY_ICON_V1,
} from "./image";
import { Typography } from "@mui/material";
import { USER_TYPES } from "../utils/userTypes";
export * from "./image";
export * as routePath from "./text";
export * as sampleData from "./text";
export * from "./messages";
const cloudFrontUrl = process.env.REACT_APP_CLOUDFRONT_URL;

export default {
	ADVANCE_CREATIVE_URL: "https://advancecreativegroup.com/",
	CARTA_PARTNER_URL: "https://carta.com/",
	GUSTO_PARTNER_URL: "https://gusto.com/",
	VOUCH_PARTNER_URL: "https://www.vouch.us/",
	ZB_PARTNER_URL: "https://www.zenbusiness.com/",
	SVB_PARTNER_URL: "https://www.svb.com/",
	PIPEDRIVE_AD_URL:
		"https://www.pipedrive.com/en/programlp?gspk=amVmZnJleXdhZ25lcjI1NDM&gsxid=whKzhfn5fQXW&utm_campaign=tiresh&utm_medium=Affiliate&utm_source=jeffreywagner2543",
	PITCH_DECK_TEMPLATE_URL: "https://docs.google.com/presentation/d/1HzGOQjkWdddBvSceDIwd9SOxv50TV58VGXJPGfrf3Zg/edit#slide=id.g17651f5e7c6_2_35",
	EXPERIENCE_LEVELS: [
		{
			text: "Total newbie",
			cardSubText: "Eager to learn how to start",
			iconComponent: EggIcon,
			selected: false,
		},
		{
			text: "First-time founder",
			cardSubText: "I’m starting my company",
			iconComponent: ChickIcon,
			selected: false,
		},
		{
			text: "Second-time founder",
			cardSubText: "This is my second time launching a startup",
			iconComponent: MiniRunnerIcon,
			selected: false,
		},
		{
			text: "Serial entrepreneur",
			cardSubText: "I can’t stop building",
			iconComponent: MiniRoaderRunnerIcon,
			selected: false,
		},
	],
	PRODUCT_SERVICE_OFFERING_DATA: [
		{
			text: "Software",
			iconComponent: SoftwareIcon,
			selected: false,
		},
		{
			text: "Hardware",
			iconComponent: HardwareIcon,
			selected: false,
		},
		{
			text: "Marketplace",
			iconComponent: MarketPlaceIcon,
			selected: false,
		},
		{
			text: "Consumer Goods",
			iconComponent: ConsumerGoodsIcon,
			selected: false,
		},
		{
			text: "Service",
			iconComponent: ServiceIcon,
			selected: false,
		},

		{
			text: "Other",
			selected: false,
			iconComponent: ServiceIcon,
			isInput: true,
		},
	],
	FOUNDER_SUPER_POWER_DATA: [
		{
			text: "Super vision",
			iconComponent: EyeVisionIcon,
			selected: false,
		},
		{
			text: "Expert network",
			iconComponent: NetworkIcon,
			selected: false,
		},
		{
			text: "Execution",
			iconComponent: ExecutionIcon,
			selected: false,
		},
		{
			text: "Luck",
			iconComponent: FlowerIcon,
			selected: false,
		},
		{
			text: "Grit",
			iconComponent: GritIcon,
			selected: false,
		},
		{
			text: "Leadership",
			iconComponent: CommunityIcon,
			selected: false,
		},
		{
			text: "Empathy",
			iconComponent: HeartIcon,
			selected: false,
		},
		{
			text: "Energy",
			iconComponent: EnergyIcon,
			selected: false,
		},
	],

	COMMITMENT_LEVELS: [
		{
			text: "Side-hustle",
			cardSubText: "I commit as much time as I can outside of work",
			iconComponent: SideHustleIcon,
			selected: false,
		},
		{
			text: "Part-time",
			cardSubText: "I commit half my energy to my startup",
			iconComponent: PartTimeIcon,
			selected: false,
		},
		{
			text: "Full-time",
			cardSubText: "I commit nearly all my time to my startup",
			iconComponent: FullTimeIcon,
			selected: false,
		},
		{
			text: "Overtime",
			cardSubText: "I live, eat, sleep and breath my startup",
			iconComponent: OverTimeIcon,
			selected: false,
		},
	],

	STARTUP_JOURNEY_DATA: [
		{
			text: "I’ve got an idea",
			iconComponent: GlowBulbIcon,
			selected: false,
		},
		{
			text: "I’ve received some kind of funding",
			iconComponent: FundingIcon,
			selected: false,
		},
		{
			text: "I know my customer",
			iconComponent: CustomerIcon,
			selected: false,
		},
		{
			text: "I’ve sized my market",
			iconComponent: MarketAnalysisIcon,
			selected: false,
		},
		{
			text: "I’ve started hiring",
			iconComponent: SmileIcon,
			selected: false,
		},
		{
			text: "I’m building a brand",
			iconComponent: ChargeIcon,
			selected: false,
		},
		{
			text: "I’ve launched a product",
			iconComponent: LaunchIcon,
			selected: false,
		},
		{
			text: "Other",
			iconComponent: LaunchIcon,
			selected: false,
			isInput: true,
		},
	],

	NEXT_KEY_MILESTONES: [
		{
			text: "First MLP iteration",
			subText: "Product Market Fit",
			date: "",
		},
		{
			text: "First post-launch MLP iteration",
			subText: "Product Market Fit",
			date: "",
		},
		{
			text: "Traunch 1",
			subText: "Product Market Fit",
			date: "",
		},
	],
	CURRENT_STREAKS: [
		{
			id: "loginStreaks",
			text: "Log in at least once a day ",
			boldText: "",
			count: 17,
			type: "LOGIN",
		},
		{
			id: "todaysTopStreak",
			text: "Complete ",
			boldText: " Today’s Top 3",
			count: 17,
			type: "TODAYS_TOP",
		},
		{
			id: "powerUpsStreak",
			text: "Complete at least one ",
			boldText: " PowerUP",
			count: 17,
			type: "POWER_UP",
		},
	],

	STORYTELLER_CONTENTS: [
		{
			index: "01",
			title: "Act One - Establishment",
			description:
				"Write down some ideas for how you want to start your story. This has grab your listeners attention! We’d recommend starting with the moment you came up with the idea, the moment you were faced with this problem.",
		},
		{
			index: "02",
			title: "Act Two - Climax",
			description:
				"This is the moment you state your purpose: why people should be interested. Describe your product and how it addresses the problem you stated in Act One.",
		},
		{
			index: "03",
			title: "Act Three - Resolution",
			description: "Where is your product going? This is where you can tie your listener in with the story, how can they be a part of your business.",
		},
	],
	VALUE_PROP_RECAP_DATA: [
		{
			index: 1,
			title: "My Customers",
		},
		{
			index: 2,
			title: "Customer Jobs",
		},
		{
			index: 3,
			title: "Customer Pains",
		},
		{
			index: 4,
			title: "Customer Gains",
		},
	],

	DEFAULT_COMPANY_NAME: "My New Startup",

	WORKSPACE_TAB_ITEMS: [
		{
			key: "poweupPacks",
			label: "PowerUP Packs",
		},
		{
			key: "powerups",
			label: "PowerUPs",
		},
	],

	POWERUP_TAB_ITEMS: [
		{
			key: "poweupPacks",
			label: "PowerUp Packs",
			disabled: false,
		},
		{
			key: "powerups",
			label: "PowerUps - Coming Soon!",
			disabled: true,
		},
	],

	MY_STARTUP_TAB_ITEMS: [
		{
			key: "myProfile",
			label: "My Profile",
		},
		{
			key: "myWork",
			label: "My Work",
		},
	],
	VALUE_PROP_DETAILS: {
		title: "Overview",
		videoUrl: `${cloudFrontUrl}/Video/advanced-creative.mp4`,
		text: "Using this Power Up, you’ll create a value proposition (also known as an elevator pitch) that summarizes the value of your product in one sentence.",
		subTitle1: "Before you begin",
		subText1:
			"To get the most out of this module, you should be familiar with the market you’re looking to get into and your expected audience. It’s recommended you complete the Discovery and Market routes before starting your value proposition for best results. Team collaboration brings in different perspectives and is also recommended.",
		subTitle2: "What you’ll learn",
		subText2:
			"A value proposition is a sentence that sums up why a customer would choose your product. It proposes a challenge and specific call-outs as to how it can be solved. It’s also known as an elevator pitch. The value proposition allows you to describe your Value Propositions and the target Customer Segments in more detail and evaluate the “fit” between the value you intend to create and the expectations your customers have.",
		subText3:
			" You can use this excercise before, during and after developing an in-depth knowledge of your customers. If you use it before, it will highlight what you need to learn about customers and test about value propositions. If you use it after, it will help you analyze and evaluate “fit”.",
		subText4:
			" The Value Proposition Canvas can be applied to new and existing value propositions and customer segments alike. In both cases it will help you structure your thinking and make your ideas more tangible.",
	},

	OVERVIEW_DETAILS: {
		title: "Overview",
		videoUrl: `${cloudFrontUrl}/Video/sample_video.mp4`,
		videoThumbnailUrl: `${cloudFrontUrl}/Images/PowerUps/PowerUp+-+MVP+Prioritizer.jpg`,
	},

	VALUE_PROP_DETAILS_SIDEBAR: [
		{
			key: "30 min",
			label: "Time Estimate",
		},
		{
			key: "2 hours",
			label: "Time Estimate",
		},
		{
			key: "Easy",
			label: "Difficulty",
		},
		{
			key: "Yes",
			label: "Team Recommended",
		},
		{
			key: "After completing this Power Up, it is highly recommended to seek feedback with your matched Founder. If revisions are needed after feedback, you can easily edit the work you have already completed.",
			label: "Feedback Alert!",
		},
		{
			key: "0",
			label: "Ready to start this PowerUP?",
		},
		{
			key: "",
			label: "Courtesy of StartupOS",
		},
	],
	VALUE_PROP_HEADER: {
		title: "Value Proposition",
		text: "Using this Power Up, you’ll create a value proposition (also known as an elevator pitch) that summarizes the value of your product in one sentence.",
	},

	NETWORK_TAB_ITEMS: [
		{
			key: "community",
			label: "Network",
		},
		{
			key: "expertFeedback",
			label: "Expert Feedback ",
		},
		{
			key: "partners",
			label: "Partners ",
		},
		{
			key: "investorSearch",
			label: "Investor Search ",
		},
	],

	RUNWAY_CALCULATOR_TAB_ITEMS: [
		{
			key: "actual",
			label: "Actual",
		},
		{
			key: "whatIf",
			label: "What If?",
		},
	],

	PRO_TIPS: [
		"Prior to raising any capital outside of founder capital, it is important for you to determine your individual product North Star. This should be a product metric that is a leading indicator of revenue. For DocuSign it was signed envelopes, for Twilio it was API calls on initial messaging, for Zapier it was activations. For your company it could be these, monthly active users (MAUs), or something else altogether.",
		"Before approaching venture capital firms or even angel investors, founders often look to their friends and family for startup capital. At the earliest stages of a company’s journey, and maybe after you and your cofounders have put in your own cash, a relatively easy and fast way to secure a small amount of working capital is to approach your close friends and selected members of your family (that is, if people in your network have the means to contribute such funding).",
		"Now it is time to look for seed funding. In general, you want to raise enough money to give yourself 12 to 24 months of runway, since that's typically the amount of time it takes to move from one round of funding to the next.",
	],

	EXCERCISE_TAB_ITEMS: [
		{
			key: "excercise",
			label: "Exercise",
		},
		{
			key: "knowledge",
			label: "Knowledge",
		},
	],
	SUMMARY_TAB_ITEMS: [
		{
			key: "excercise",
			label: "Exercise",
		},
		{
			key: "rate",
			label: "Rate PowerUP",
		},
	],

	STORYTELLER_STEPS: [
		{
			index: "01",
			label: "Act One - Establishment",
			description:
				"Growing up, my father was an ice road trucker. After weeks of him being away he’d return home and tell us perilous stories about terrbile road conditions, unfair treatment by the higher ups, all the funny characters he’d meet going cross country, it was a really interesting lifestyle. When I was old enough I got to go on a few trips myself! I grew up in the trucking industry, and I grew up wanting to make the industry beter for the truckers who work tirelessly to keep this country running.",
		},
		{
			index: "02",
			label: "Act Two - Climax",
			description:
				"The Roadrunner Corp. is designing an all-in-one platform for truckers, so they can worry less about the road ahead, and keep the days on end of driving completely stress-free. We’ll give them alerts for road conditions, a full, constantly updated map of stops with crowd-sourced reviews, and content to keep them focused on their drive. It’s a place to share their stories, and to keep them active in sharing them as well.",
		},
		{
			index: "03",
			label: "Act Three - Resolution",
			description:
				"We’ve been working with some brilliant designers to build out a full prototype of the product, and we’ve been putting this in the hands of a least a dozen different truckers every week, gathering quantitative and qualitative data and iterating on our prototype. We’re aiming on gathering another 500,000 dollars in pre-seed over the next 30 days, and in 45 days, we’ll have an MVP that’ll be in the hands of 1,200 truckers nationwide.",
		},
	],

	PERSONA_BUILDER_STEPS: [
		{
			index: "01",
			label: "Name & Avatar",
			description: "Use this user persona template to create a profile on your ideal customer and visualize how your product will work for them.",
		},
		{
			index: "02",
			label: "Describe Your Persona",
			description: "Use this user persona template to create a profile on your ideal customer and visualize how your product will work for them.",
		},
		{
			index: "03",
			label: "Job Responsibilities",
			description: "Use this user persona template to create a profile on your ideal customer and visualize how your product will work for them.",
		},
		{
			index: "04",
			label: "Job Needs",
			description: "Use this user persona template to create a profile on your ideal customer and visualize how your product will work for them.",
		},
	],
	PROGRESS_TITLES: ["You’re behind on your progress", "You’re right on track", "You’re ahead of the pack"],
	PREREQUISITE_STEPS: [
		{
			id: 1,
			label: "Persona Builder",
			exerciseStatus: "COMPLETED",
		},
		{
			id: 2,
			label: "Value Proposition",
			exerciseStatus: "START",
		},
		{
			id: 3,
			label: "TAM/SAM/SOM",
			exerciseStatus: "COMPLETED",
		},
	],
	TAMSAMSOM_COMPLETED_STEPS: [
		{
			index: "01",
			label: "TAM",
		},
		{
			index: "02",
			label: "SAM",
		},
		{
			index: "03",
			label: "SOM",
		},
		{
			index: "04",
			label: "Market Temperature",
		},
	],

	REVENUE_MODELER_STEPS: [
		{
			index: "01",
			label: "Evaluate revenue model options",
		},
		{
			index: "02",
			label: "Adapt and adjust",
		},
	],
	BRANDSTORMING_RECAP_DATA: [
		{
			index: 1,
			title: "Brand Descriptors",
		},
		{
			index: 2,
			title: "Brand Detractors",
		},
		{
			index: 3,
			title: "Ideal Customer",
		},
	],
	QUESTIONS_DATA: [
		{
			id: 1,
			statement: "Determine initial desirability of solution.",
			goal: "Do people care about my solution?",
			selected: false,
		},
		{
			id: 2,
			statement: "Gauge importance of need.",
			goal: "Understand how large or important the need for my offering is.",
			selected: false,
		},
		{
			id: 3,
			statement: "Identify substitutes.",
			goal: "Determine alternative or workaround is available and learn whether it is having a positive or negative impact on customer.",
			selected: false,
		},
		{
			id: 4,
			statement: "Screen early adaptors.",
			goal: "Identify potential early adaptors who would be willing to try my offering out and provide feedback.",
			selected: false,
		},
		{
			id: 5,
			statement: "Delighters and detractors.",
			goal: "Learn which aspects of my offering are most appealing and unappealing and why.",
			selected: false,
		},
		{
			id: 6,
			statement: "Customers willingness to pay.",
			goal: "Determine if/how much the subject would be is willingness to pay for my offering.",
			selected: false,
		},
		{
			id: 7,
			statement: "Identify missing ingredients.",
			goal: "Determine whether there’s anything I might be missing from my offering.",
			selected: false,
		},
		{
			id: 8,
			statement: "What is most critical to making a sale.",
			goal: "Understand how the customer might make a buying decisions.",
			selected: false,
		},
		{
			id: 9,
			statement: "How would people talk about it.",
			goal: "How would they describe my offering to others?",
			selected: false,
		},
		{
			id: 10,
			statement: "Identify niches.",
			goal: " Do the participants fall into distinct groups with similar characteristics, needs or behaviors?",
			selected: false,
		},
	],
	INTERVIEW_STATUS: ["Scheduled", "Completed", "Incomplete", "Cancelled", "No Show"],
	INVITE_STATUS: ["Sent", "Accepted", "Declined", "No Response"],
	INCORPORATE_STATUS: ["Yes", "No", "In Progress"],

	PROBLEM_SOLUTION_LIST: [
		{
			id: 1,
			title: "Problem 1",
			description: "Faucibus id viverra ipsum neque massa lacus ac.",
			symptoms: "Faucibus id viverra ipsum neque massa lacus ac.",
			existingAlternatives: "Faucibus id viverra ipsum neque massa lacus ac.",
			solutionList: [
				"Faucibus id viverra ipsum neque massa lacus ac.",
				"Faucibus id viverra ipsum neque massa lacus ac.",
				"Faucibus id viverra ipsum neque massa lacus ac.",
			],
		},
		{
			id: 2,
			title: "Problem 2",
			description: "Faucibus id viverra ipsum neque massa lacus ac.",
			symptoms: "Faucibus id viverra ipsum neque massa lacus ac.",
			existingAlternatives: "Faucibus id viverra ipsum neque massa lacus ac.",
			solutionList: [
				"Faucibus id viverra ipsum neque massa lacus ac.",
				"Faucibus id viverra ipsum neque massa lacus ac.",
				"Faucibus id viverra ipsum neque massa lacus ac.",
			],
		},
		{
			id: 3,
			title: "Problem 3",
			description: "Faucibus id viverra ipsum neque massa lacus ac.",
			symptoms: "Faucibus id viverra ipsum neque massa lacus ac.",
			existingAlternatives: "Faucibus id viverra ipsum neque massa lacus ac.",
			solutionList: [
				"Faucibus id viverra ipsum neque massa lacus ac.",
				"Faucibus id viverra ipsum neque massa lacus ac.",
				"Faucibus id viverra ipsum neque massa lacus ac.",
				"Faucibus id viverra ipsum neque massa lacus ac.",
				"Faucibus id viverra ipsum neque massa lacus ac.",
			],
		},
	],
	AREA_OF_EXPERTISE: [
		"AR/VR",
		"Artificial intelligence",
		"B2B",
		"B2C",
		"Bootstrapping",
		"Content marketing",
		"Continuous delivery",
		"Conversion rate optimisation",
		"Copywriting",
		"Customer development",
		"Customer discovery",
		"Customer success",
		"Data science",
		"Design / UX",
		"Email marketing",
		"Fundraising",
		"Go to market strategy",
		"Growth marketing",
		"Idea validation",
		"Investor psychology",
		"Marketing automation",
		"Mindset coaching",
		"Minimum viable product",
		"Networking",
		"No-code",
		"Pay per click strategies",
		"Pricing strategy",
		"Product analytics",
		"Product launches",
		"Product management",
		"Product market fit",
		"Product marketing",
		"Productized services",
		"Public relations",
		"Recruiting/Team building",
		"SaaS/Cloud",
		"Sales",
		"SEO",
		"Social media",
		"Technology and tools",
	],
	REQUEST_USER_TYPE: [
		{
			type: "ADVISOR_USER",
			path: ROUTES_PATH.ADVISOR_DASHBOARD,
		},
		{
			type: "MENTOR_USER",
			path: ROUTES_PATH.MY_MENTOR_PROFILE,
		},
		{
			type: "CANDIDATE_USER",
			path: ROUTES_PATH.CANDIDATE_DASHBOARD,
		},
		{
			type: "STARTUP_USER",
			path: ROUTES_PATH.FOUNDER_WORKSPACE,
		},
		{
			type: "STARTUP_OWNER",
			path: ROUTES_PATH.FOUNDER_WORKSPACE,
		},
		{
			type: "INVESTOR_USER",
			path: ROUTES_PATH.INVESTOR_DASHBOARD,
		},
		{
			type: "PARTNER_USER",
			path: ROUTES_PATH.PARTNER_ADMIN_DASHBOARD,
		},
		{
			type: "PROFESSOR_USER",
			path: ROUTES_PATH.INSTRUCTOR_DASHBOARD,
		},
		{
			type: "STUDENT_USER",
			path: ROUTES_PATH.WORKSPACE,
		},
		{
			type: USER_TYPES.CORPORATE_USER,
			path: ROUTES_PATH.INVESTOR_DASHBOARD,
		},
		{
			type: "ADMIN",
			path: ROUTES_PATH.IMPERSONATE,
		},
	],
	MENTOR_PERSONA_TAB_ITEMS: [
		{
			key: "founders",
			label: "FOUNDERS",
			icon: VELVET_ELECTRIC,
			type: "STARTUP_USER",
		},
		{
			key: "mentors",
			label: "MENTORS",
			icon: BLUE_FUSION,
			type: "MENTOR_USER",
		},
		{
			key: "advisors",
			label: "Advisors",
			icon: SPARK_ORANGE,
			type: "ADVISOR_USER",
		},
	],
	HOME_PAGE_DATA: [
		{
			key: "founders",
			landingTitle: "Empowering all founders to be unstoppable",
			landingDescription:
				"StartupOS allows founders to access tools, guidance, mentoring and capital to streamline the process of starting a new company.",
			caption: "Help your startup go further, faster",
			subcaption:
				"Quickly get to fun parts of founding a company with learn-by-doing PowerUps and get the directional guidance to help validate your ideas. Access a valuable community of like-minded founders and interested investors.",
		},
		{
			key: "mentors",
			landingTitle: "Guiding empowered founders towards the future",
			landingDescription:
				"StartupOS makes it easy to nudge passionate entrepreneurs in the right direction and help their wonderful ideas become successful businesses.",
			caption: "Foster future innovation",
			subcaption:
				"First-time and serial entrepreneurs have varied needs and experience, so personalized coaching and complementary expertise is essential to early-stage startups.",
		},
		{
			key: "advisors",
			landingTitle: "Helping visionary founders when and where they need it most",
			landingDescription:
				"The StartupOS platform and community allows founders to get relevant and valuable advice at critical points in their journeys.",
			caption: "Share valuable tactical expertise",
			subcaption:
				"Some founders need a little push and some need a complete course correction, but all benefit from the experience and wisdom from experienced, serial entrepreneurs.",
		},
	],
	USER_ROLE_DATA: [
		{
			text: "Founder",
			iconComponent: LaunchIcon,
			selected: false,
		},
		{
			text: "Mentor",
			iconComponent: MentorIcon,
			selected: false,
		},
		{
			text: "Advisor",
			iconComponent: AdvisorIcon,
			selected: false,
		},
	],

	PRODUCT_OFFERING: [
		"AI/ML",
		"Data",
		"SaaS",
		"Collaboration",
		"Edtech",
		"Climate",
		"Cloud Infrastructure",
		"Security",
		"Digital Health",
		"Fintech",
		"Marketplace",
		"Social",
		"Crypto",
		"Blockchain",
		"Services",
		"Consumer Goods",
		"Hardware",
	],
};

export const TRAILING_RANGES = ["Last 30 Days", "Last 180 Days", "Last 1 Year"];
export const SELECT_INTERVIEW_POPUP_DATA = {
	startupName: "abc",
	responses: [
		{
			date: "Monday,May 17 2022",
			timeZone: "US-AT",
			times: ["8:00 AM - 9:00 AM", "7:00 AM - 8:00 AM", "6:00 AM - 7:00 AM", "4:00 AM - 5:00 AM"],
		},
		{
			date: "Monday,May 18 2022",
			timeZone: "US-AT",
			times: ["8:00 AM - 9:00 AM", "7:00 AM - 8:00 AM", "6:00 AM - 7:00 AM", "4:00 AM - 5:00 AM"],
		},
		{
			date: "Monday,May 19 2022",
			timeZone: "US-AT",
			times: ["8:00 AM - 9:00 AM", "7:00 AM - 8:00 AM", "6:00 AM - 7:00 AM", "4:00 AM - 5:00 AM"],
		},
	],
};

export const RATING_LABELS = {
	1: "Useless",
	2: "Poor",
	3: "Ok",
	4: "Good",
	5: "Excellent",
};
export const IN_PROGRESS_POWERUPS_DATA = [
	{
		name: "Persona 2",
		step: "2 of 3",
		modifiedDate: "May 07,2022",
		modifiedBy: "Abhishek",
	},
	{
		name: "Persona 5",
		step: "2 of 3",
		modifiedDate: "May 07,2022",
		modifiedBy: "Abhishek",
	},
];

export const MEETING_DAYS_OBJECT_LIST = [
	{
		label: "Sunday",
		value: false,
		timeList: [
			{
				startTime: new Date("1970-01-01T09:00:00").getTime(),
				endTime: new Date("1970-01-01T17:00:00").getTime(),
			},
		],
		copiedValue: false,
		day: 7,
	},
	{
		label: "Monday",
		value: false,
		timeList: [
			{
				startTime: new Date("1970-01-01T09:00:00").getTime(),
				endTime: new Date("1970-01-01T17:00:00").getTime(),
			},
		],
		copiedValue: false,
		day: 1,
	},
	{
		label: "Tuesday",
		value: false,
		timeList: [
			{
				startTime: new Date("1970-01-01T09:00:00").getTime(),
				endTime: new Date("1970-01-01T17:00:00").getTime(),
			},
		],
		copiedValue: false,
		day: 2,
	},

	{
		label: "Wednesday",
		value: false,
		timeList: [
			{
				startTime: new Date("1970-01-01T09:00:00").getTime(),
				endTime: new Date("1970-01-01T17:00:00").getTime(),
			},
		],
		copiedValue: false,
		day: 3,
	},
	{
		label: "Thursday",
		value: false,
		timeList: [
			{
				startTime: new Date("1970-01-01T09:00:00").getTime(),
				endTime: new Date("1970-01-01T17:00:00").getTime(),
			},
		],
		copiedValue: false,
		day: 4,
	},
	{
		label: "Friday",
		value: false,
		timeList: [
			{
				startTime: new Date("1970-01-01T09:00:00").getTime(),
				endTime: new Date("1970-01-01T17:00:00").getTime(),
			},
		],
		copiedValue: false,
		day: 5,
	},
	{
		label: "Saturday",
		value: false,
		timeList: [
			{
				startTime: new Date("1970-01-01T09:00:00").getTime(),
				endTime: new Date("1970-01-01T17:00:00").getTime(),
			},
		],
		copiedValue: false,
		day: 6,
	},
];

export const FUNDING_TYPES = ["Angel", "Corporate Venture Capital", "Family Office", "Philanthropic", "Venture Capital", "Personal Investor"];

export const INDUSTRY_TYPES = [
	"Media & Entertainment",
	"Information Technology Services",
	"Telecommunication",
	"Financial Services",
	"Networking & Equipment",
	"Software",
	"Biotechnology",
	"Industrial Energy",
	"Medical Devices & Equipment",
	"Consumer Products & Services",
	"Cleantech & Sustainability",
	"Hardware & Frontier Tech",
	"Life Science and Healthcare",
	"Insurance",
	"Payroll & Benefits",
	"Real estate",
	"Supply Chain, Logistics and Delivery",
	"Consumer Internet Services",
	"E-commerce",
	"Travel",
	"Space",
	"Games",
	"Auto & Transportation",
];

export const INVESTOR_TYPES = [
	"Accelerator/Incubator",
	"Individual Angel",
	"Angel Group",
	"Corporate",
	"Family Office",
	"Foundation",
	"Private Equity (PE)",
	"Venture Capital (VC)",
	"Other",
];

export const INVESTMENT_STAGE_PREFERENCES = ["Pre-Seed", "Seed Round", "Series A", "Growth"];
export const GEOGRAPHICAL_PREFERENCES = [
	"Africa",
	"North America",
	"Central America",
	"South America",
	"Asia",
	"Canada",
	"Europe",
	"Middle East",
	"Oceania",
	"United States",
];

export const REVENUE_MODEL_TYPE = [
	"Subscription",
	"Pay per User",
	"Advertising or Affiliate Model",
	"Product-as-a-service Model",
	"Retailer Model",
	"Fee-for-Service",
	"Leasing Model",
	"Franchise model",
];
export const BUSINESS_MODEL_TYPE = ["B2B", "B2C", "B2B2C"];
export const POWERUP_TEST_DATA = {
	title: "Test this PowerUp",
	description:
		"Congrats on completing your PowerUp! Now it’s time to validate these findings with a targeted market test. Tests increase your confidence and credibility with investors and future partners. ",
};

export const POWERUP_IDS = [
	{
		value: "md-value-prop",
		id: 1,
	},
	{
		value: "md-story",
		id: 2,
	},
	{
		value: "md-persona",
		id: 3,
	},
	{
		value: "md-prob-sol",
		id: 5,
	},
	{
		value: "md-brandstorm",
		id: 6,
	},
	{
		value: "md-tam",
		id: 7,
	},
	{
		value: "md-revenue",
		id: 9,
	},
];

export const COVER_IMAGE_TEMPLATE_LIST = [
	{
		img: COVER_IMAGE_TEMPLATE_1,
		title: "10 - Now Fundraising",
	},
	{
		img: COVER_IMAGE_TEMPLATE_2,
		title: "10.2 - Now Fundraising",
	},
	{
		img: COVER_IMAGE_TEMPLATE_3,
		title: "11 - Mentor Magic",
	},
	{
		img: COVER_IMAGE_TEMPLATE_4,
		title: "11.2 - Mentor Magic",
	},
	{
		img: COVER_IMAGE_TEMPLATE_5,
		title: "12 - Success from Adversity",
	},
	{
		img: COVER_IMAGE_TEMPLATE_6,
		title: "13 - Find Your Inner Strength",
	},
	{
		img: COVER_IMAGE_TEMPLATE_7,
		title: "14 - Unstoppable Drive",
	},
	{
		img: COVER_IMAGE_TEMPLATE_8,
		title: "14.2 - Unstoppable Drive",
	},
	{
		img: COVER_IMAGE_TEMPLATE_9,
		title: "17 - Net Promoter Score-17",
	},
	{
		img: COVER_IMAGE_TEMPLATE_10,
		title: "17.2 - Net Promoter Score-17",
	},
	{
		img: COVER_IMAGE_TEMPLATE_11,
		title: "21 - SW Customer Feedback_NPS Survey-22",
	},
	{
		img: COVER_IMAGE_TEMPLATE_12,
		title: "21.2 - SW Customer Feedback_NPS Survey-22",
	},
	{
		img: COVER_IMAGE_TEMPLATE_13,
		title: "3 - Celebrating Latinx Voices",
	},
	{
		img: COVER_IMAGE_TEMPLATE_14,
		title: "4 - Black-Owned Business",
	},
	{
		img: COVER_IMAGE_TEMPLATE_15,
		title: "4.2 - Black-Owned Business",
	},
	{
		img: COVER_IMAGE_TEMPLATE_16,
		title: "5 - Be an Ally",
	},
	{
		img: COVER_IMAGE_TEMPLATE_17,
		title: "5.2 - Be an Ally",
	},
	{
		img: COVER_IMAGE_TEMPLATE_18,
		title: "6 - Ally",
	},
	{
		img: COVER_IMAGE_TEMPLATE_19,
		title: "7 Diversity Inclusion",
	},
	{
		img: COVER_IMAGE_TEMPLATE_20,
		title: "7.2 - Disability Inclusion",
	},
	{
		img: COVER_IMAGE_TEMPLATE_21,
		title: "7.3 - Disability Inclusion",
	},
	{
		img: COVER_IMAGE_TEMPLATE_22,
		title: "8 - Founders Wear Many Hats",
	},
	{
		img: COVER_IMAGE_TEMPLATE_23,
		title: "9 - Think Independently Together",
	},
	{
		img: COVER_IMAGE_TEMPLATE_24,
		title: "9.2 - Think Independently Together",
	},
];

// export const userOnboarding = {
// 	email: "",
// 	onboardingStatus: "",
// 	firstName: "",
// 	lastName: "",
// 	profession: "",
// 	linkedInUrl: "",
// 	about: "",
// 	userType: "",
// 	zipcode: "",
// 	headline: "",
// 	videoUrl: "",
// 	coverImgUrl: "",
// 	experienceDetails: [],
// 	educationDetails: [],
// 	onboardingDetail: {
// 		companyName: "",
// 		companyType: "",
// 		currentCommitment: "",
// 		experienceDetails: "",
// 		superpower: "",
// 		journeyDetails: "",
// 		whyStartupOs: "",
// 		expertise: "",
// 		fundingDetails: "",
// 		interests: "",
// 		industryFocus: "",
// 		group: "",
// 		state: "",
// 		city: "",
// 		incorporatedStatus: "",
// 		foundedDate: "",
// 		investorType: "",
// 		investments: "",
// 		investmentRange: "",
// 		noOfInvestmentDeals: "",
// 		skillsAndExpertise: "",
// 		firstThingInStartup: "",
// 		certifications: "",
// 		zipcode: "",
// 	},
// };

export const INVESTOR_DASHBOARD_SORT_LIST = [
	{ value: "Newest", key: "createdAt" },
	{ value: "Score", key: "currentScore" },
	{ value: "Company Name", key: "companyName" },
	{ value: "Founded Year", key: "foundedYear" },
	{ value: "Company Size", key: "companySize" },
	{ value: "Financing", key: "financing" },
	{ value: "Current Stage", key: "currentStage" },
	{ value: "Seeking", key: "seeking" },
	{ value: "Ask", key: "ask" },
	{ value: "Revenue", key: "revenue" },
];

export const DEGREE_TYPES = [
	"High School Degree or Equivalent",
	"Professional Certification",
	"Associates Degree",
	"Bachelors Degree",
	"Masters Degree",
	"Doctoral Degree",
];

export const FUNDING_STAGES = [
	{
		id: 1,
		name: "Bootstrap Definition",
		description: `Startup has started a business with minimal external funding, relying instead on 
			personal savings, credit cards, or revenue generated from the business to fund 
			growth.`,
	},
	{
		id: 2,
		name: "Pre-Seed Definition",
		description: `Startup has raised through pre-seed funding to support costs for product development, 
			market research, and initial operations. The startup focus is on validating their 
			business idea, attracting initial customers, and building a minimum viable product.`,
	},
	{
		id: 3,
		name: "Seed Definition",
		description: `Startup is in earliest stage of funding and usually involves raising money from friends 
			and family, angel investors, or early-stage venture capital firms. The goal of the seed 
			round is to help the startup develop its product or service, validate the business 
			concept, and start generating revenue.`,
	},
	{
		id: 4,
		name: "Series A Definition",
		description: `Startup has validated the business model and has obtained some traction in the 
			market. They may have a minimum viable product, early customers, and a clear path 
			to growth`,
	},
	{
		id: 5,
		name: "Growth Definition",
		description: `Startup has established a viable business model, a growing customer base, and a 
			clear path to profitability. The focus for the startup is on scaling the business and 
			increasing revenue while continuing to refine the product or service and improving 
			customer retention`,
	},
];

export const PROFILE_EDIT_LIST = [
	{ id: 0, title: "My Details", disabled: false },
	{ id: 1, title: "Availability", disabled: false },
	{ id: 2, title: "My Photo", disabled: false },
	{ id: 3, title: "My Video", disabled: false },
	{ id: 4, title: "Experience & Education", disabled: false },
];

export const INVESTOR_PROFILE_EDIT_LIST = [
	{ id: 0, title: "My Details", disabled: false },
	{ id: 1, title: "Availability", disabled: false },
	{ id: 2, title: "My Photo", disabled: false },
	{ id: 3, title: "Experience & Education", disabled: false },
];
export const STARTUP_EDIT_LIST = [
	{ id: 0, title: "Startup Details", disabled: false },
	{ id: 1, title: "Logo", disabled: false },
	{ id: 2, title: "Founder Story", disabled: false },
	{ id: 3, title: "Elevator Pitch", disabled: false },
	{ id: 4, title: "Pitch Deck", disabled: false },
	{ id: 5, title: "Team", disabled: false },
];
export const CALCULATOR_LIST = [
	{
		id: 0,
		name: "Selected Customer Acquisition Cost",
		description: `CAC (Customer Acquisition Cost) is calculated on the total costs of sales and marketing efforts divided by the number of new customers acquired during a specific time period.`,
	},
	{
		id: 1,
		name: "Profit Margin Calculator",
		description: `Profit margin is a financial metric used to measure the profitability of a business. It is the ratio of the net profit earned by a company to its total revenue over a specific period of time, expressed as a percentage.`,
	},
	{
		id: 2,
		name: "Break-Even Analysis Calculator",
		description: `Break-even analysis is a financial analysis that determines the sales volume required for a business to break even and cover all of its costs. To calculate break-even point, you need to know the fixed costs, unit selling price, and variable costs per unit.`,
	},
	{
		id: 3,
		name: "Sales Margin Calculator",
		description: `Sales margin, also known as gross profit margin, is a financial ratio that measures the percentage of sales revenue that is left after deducting the cost of goods sold (COGS). It is a useful metric for evaluating a company's profitability and efficiency in managing its cost of goods. To calculate the sales margin, you need to know the sales revenue and cost of goods sold for a given period, such as a month, quarter, or year. `,
	},
	{
		id: 4,
		name: "Net Margin Calculator",
		description: `Net margin, also known as net profit margin or net income margin, is a financial ratio that measures the percentage of net income or profit a company generates from its revenue. It is a useful metric for evaluating a company's overall profitability and efficiency in managing its expenses.`,
	},
	{
		id: 5,
		name: "Lifetime Value Calculator",
		description: `Lifetime value (LTV) is a metric that measures the total amount of revenue a customer is expected to generate over the entire duration of their relationship with a company. It is a useful metric for businesses to understand the long-term value of their customers and to make informed decisions about customer acquisition and retention strategies.`,
	},
	{
		id: 6,
		name: "Return on Investment Calculator",
		description: `Return on investment (ROI) is a financial ratio that measures the profitability of an investment relative to its cost. It is a widely used metric for evaluating the efficiency of an investment and making informed decisions about allocating resources.`,
	},
	{
		id: 7,
		name: "Runway Calculator",
		description: ``,
	},
];

export const TIMEZONE_LIST = [
	{ name: "(GMT-10:00) Hawaii Time", zone: "Pacific/Honolulu" },
	{ name: "(GMT-09:00) Alaska Time", zone: "America/Anchorage" },
	{ name: "(GMT-08:00) Pacific Time", zone: "America/Los_Angeles" },
	{ name: "(GMT-07:00) Mountain Time", zone: "America/Denver" },
	{ name: "(GMT-06:00) Central Time", zone: "America/Chicago" },
	{ name: "(GMT-05:00) Eastern Time", zone: "America/New_York" },
	{ name: "(GMT-03:30) Newfoundland Time", zone: "America/St_Johns" },
	{ name: "(GMT-04:00) Atlantic Time", zone: "America/Halifax" },
	{ name: "(GMT-05:00) Eastern Time", zone: "US/Eastern" },
	{ name: "(GMT+05:30) India Standard Time", zone: "Asia/Calcutta" },
];

export const INVESTOR_FILTER_DATA = [
	{ sort: "", filter: "companyName", active: false },
	{ sort: "", filter: "createdAt", active: false },
	{ sort: "", filter: "foundedYear", active: false },
	{ sort: "", filter: "companySize", active: false },
	{ sort: "DESC", filter: "currentScore", active: false },
	{ sort: "", filter: "financing", active: false },
	{ sort: "", filter: "currentStage", active: false },
	{ sort: "", filter: "seeking", active: false },
	{ sort: "", filter: "ask", active: false },
	{ sort: "", filter: "revenue", active: false },
];

export const POWERPACK_DETAILS_DATA = [
	{
		id: "perfect-your-pitch",
		mentorSectionTitle: "Need Guidance and Feedback?",
		mentorSectionDescription: `Have you completed your work or need some guidance? Connect with a Mentor on the StartupOS platform to practice your pitch or obtain guidance to help prepare for your next investor meeting!`,
		showTopMentors: true,
	},
	{
		id: "build-mvp",
		mentorSectionTitle: "Ready to Review Your Idea?",
		mentorSectionDescription: `Once you've finished the initial stages of your idea validation process, it's time to schedule a customized Idea Validation Assessment with our experienced team at StartupOS. During this assessment, you'll dive deep into the core aspects of your concept and its potential in the market.
Prepare to present the tangible merits and advantages of your idea. This is a pivotal moment to gain insights and clarity on the viability of your concept, and it could be the catalyst to propel your business forward.`,
		showTopMentors: true,
	},
	{
		id: "are-you-fundable",
		mentorSectionTitle: "Review your assessment with StartupOS",
		mentorSectionDescription: `Schedule a personalized session with our StartupOS team. During the session, we will review your startup assessment. Don't miss this opportunity to explore your current stage, and whether you are ready for funding today!`,
		showTopMentors: true,
	},
	{
		id: "complete-profile",
		mentorSectionTitle: "Need Help?",
		mentorSectionDescription: `Are you feeling stuck or have questions about setting up your startup and personal account profiles? Don't worry; we've got your back! Our StartupOS Support team is here to help you every step of the way. Whether you're a seasoned entrepreneur or just starting your journey, we're committed to ensuring your experience is seamless and enjoyable. If you have any questions about using our platform, setting up your startup profile, or optimizing your personal account, we're here to provide answers. Just click on the StartupOS Chat Icon in the bottom right corner below. button below`,
		showTopMentors: false,
	},
];

export const HISTORY_OF_SUCCESS_LIST = [
	{ id: 19, label: "Prior raise of any amount", helperText: "Has a member of your founding team rasied capital for a prior company?", checked: false },
	{ id: 20, label: "Prior raise of $1M+", helperText: "Has a member of your founding team rasied $1M+ in capital for a prior company?", checked: false },

	// { id: 2, label: "Prior raise of $10M+", helperText: "", checked: false },
	// { id: 3, label: "Prior raise of $15M+", helperText: "", checked: false },
	// { id: 4, label: "Prior raise of $25M+", helperText: "", checked: false },
	{
		id: 5,
		label: "Prior VC Backing",
		helperText: "Has a member of your founding team recieved VC backing as an executive at a prior company?",
		checked: false,
	},
	{ id: 6, label: "Prior Exit", helperText: "Has the founding team successfully exited (sold) a company before?", checked: false },
];

export const FOUNDING_TEAM_EXP_LIST = [
	{
		id: 7,
		label: "Strong Network",
		helperText: "Does the founding team have a strong network (advisors and access to capital) in the industry?",
		checked: false,
	},
	{
		id: 8,
		label: "Elite Industry Experience",
		helperText: "Has a member of your founding team served a prominent role at a prestigious company in the industry?",
		checked: false,
	},
	// {
	// 	id: 9,
	// 	label: "Seasoned Advisor",
	// 	helperText: "",
	// 	checked: false,
	// },
	{
		id: 10,
		label: "Seasoned Executive",
		helperText: "Has a member of your founding team held senior executive roles at established companies?",
		checked: false,
	},
	{ id: 11, label: "Seasoned Founder", helperText: "Has the founder of your company founded and built multiple companies?", checked: false },
	// { id: 12, label: "Seasoned Operator", helperText: "", checked: false },
];

export const FOUNDING_TEAM_SKILLS_LIST = [
	{
		id: 13,
		label: "Top University Grad",
		helperText: "Has a member of your founding team studied at a prestigious, highly-ranked academic institution?",
		checked: false,
	},
	{
		id: 14,
		label: "Specialty Technical Skill",
		helperText: "Does a member of your founding team have specialty technical skills relevant to your company?",
		checked: false,
	},
	// { id: 15, label: "Leadership", helperText: "", checked: false },
	{
		id: 16,
		label: "Grit",
		helperText: "Has the founding team persisted through multiple significant failures to reach eventual success at prior companies?",
		checked: false,
	},
	{
		id: 17,
		label: "Diverse Skill Sets",
		helperText: "Is the founding team diversified and comprehensive (e.g., technical, business, marketing)?",
		checked: false,
	},
	{ id: 18, label: "Legacy Leadership Team", helperText: "Has your founding team achieved success working together at prior companies?", checked: false },
];

export const INVESTOR_PITCH_PRESENTATION_DICT = {
	"Weak - Lack of clarity, unconvincing value proposition, weak storytelling.": "Weak",
	"Poor - Inadequate market research, unclear business model, limited traction.": "Poor",
	"Fair - Clear problem and solution, reasonable market opportunity, satisfactory team introduction.": "Fair",
	"Good - Compelling value proposition, convincing market research, strong business model, demonstrated traction.": "Good",
	"Strong - Exceptional storytelling, clear financials, strong team presentation, comprehensive due diligence.": "Strong",
};

export const CURRENT_PRODUCT_STATE_DICT = {
	"Idea (or low-fidelity mockup/prototype): A basic visual representation of a product's concept with minimal details, used for early-stage design exploration and feedback.":
		"Idea (or low-fidelity mockup/prototype)",
	"Minimum Viable Product: An initial version of a product with just enough features to gather feedback and validate the idea in the market.":
		"Minimum Viable Product",
	"Launched (or sellable product): A fully developed and market-ready product that is ready for sale and use by customers.": "Launched (or sellable product)",
};

export const STARTUP_SCALABLE_DICT = {
	"Yes, my business model can easily accommodate growth without significant additional resources or major restructuring": "Yes",
	"Partially, we have a product/service with potential, but scaling might require refining processes and optimizing resources.": "Partially",
	"No, our current business model is not designed for rapid scaling; growth would likely lead to operational challenges.": "No",
};

export const STAGE_STEPPER_LIST = [
	{ label: "Pre-Seed", color: "rgba(222, 0, 133, 1)", bgColor: "rgba(254, 246, 251, 1)" },
	{ label: "Seed", color: "rgba(0, 206, 126, 1)", bgColor: "rgba(229, 250, 242, 1)" },
	{ label: "Series A", color: "rgba(123, 97, 255, 1)", bgColor: "rgba(242, 239, 255, 1)" },
	{ label: "Growth", color: "rgba(9, 92, 237, 1)", bgColor: "rgba(230, 239, 253, 1)" },
];

export const PITCH_DECK_SECTION_TAB_ITEMS = [
	{ id: 1, label: "Pitch Deck", icon: PITCH_DECK_BLACK_ICON, isActive: true },
	{ id: 2, label: "Founder Story", icon: FACE_HAPPY_BLACK_ICON, isActive: false },
	{ id: 3, label: "Elevator Pitch", icon: SWITCH_BLACK_ICON, isActive: false },
	{ id: 4, label: "Platform Activity", icon: ACTIVITY_ICON_V1, isActive: false },
];
export const TEMP_COUNTRIES = [
	{
		countryName: "Europe",
		type: "Europe",

		value: false,
		id: 3,
		countryCode: "",
		currency: "",
		continent: "",
		states: [],
	},
	{
		countryName: "Asia",
		type: "Asia",

		value: false,
		id: 3,
		countryCode: "",
		currency: "",
		continent: "",
		states: [],
	},
	{
		countryName: "Africa",
		type: "Africa",

		value: false,
		id: 3,
		countryCode: "",
		currency: "",
		continent: "",
		states: [],
	},
	{
		countryName: "Middle East",
		type: "Middle East",

		value: false,
		id: 3,
		countryCode: "",
		currency: "",
		continent: "",
		states: [],
	},
	{
		countryName: "Oceania",
		type: "Oceania",

		value: false,
		id: 3,
		countryCode: "",
		currency: "",
		continent: "",
		states: [],
	},
];

export const USA_REGION_DATA = {
	id: 1,
	countryName: "USA Regions",
	countryCode: "USA Regions",
	currency: "USD",
	continent: "North America",
	states: [
		{
			id: 1,
			stateName: "Mid Atlantic",
			countryName: "Mid Atlantic",
		},
		{
			id: 2,
			stateName: "New England",
			countryName: "New England",
		},
		{
			id: 3,
			stateName: "Southeast",
			countryName: "Southeast",
		},
		{
			id: 4,
			stateName: "South",
			countryName: "South",
		},
		{
			id: 5,
			stateName: "Midwest",
			countryName: "Midwest",
		},
		{
			id: 6,
			stateName: "Great Lakes",
			countryName: "Great Lakes",
		},
		{
			id: 7,
			stateName: "Mountain",
			countryName: "Mountain",
		},
		{
			id: 8,
			stateName: "West Coast",
			countryName: "West Coast",
		},
	],
};

export const regionStateObj = {
	"Mid Atlantic": ["Delaware", "District of Columbia", "Maryland", "New Jersey", "New York", "Pennsylvania", "Virginia", "West Virginia"],
	"New England": ["Connecticut", "Maine", "Massachusetts", "New Hampshire", "Rhode Island", "Vermont"],
	Southeast: ["Alabama", "Florida", "Georgia", "Kentucky", "Mississippi", "North Carolina", "Puerto Rico", "South Carolina", "Tennessee", "Virgin Islands"],

	South: ["Arkansas", "Louisiana", "Oklahoma", "Texas"],
	Midwest: ["Iowa", "Kansas", "Missouri", "Nebraska", "North Dakota", "South Dakota"],
	"Great Lakes": ["Illinois", "Indiana", "Michigan", "Minnesota", "Ohio", "Wisconsin"],
	Mountain: ["Arizona", "Colorado", "Idaho", "Montana", "Nevada", "New Mexico", "Utah", "Wyoming"],
	"West Coast": ["Alaska", "California", "Guam", "Hawaii", "Oregon", "Washington"],
};

export const USA_STATES = {
	AL: "Alabama",
	AK: "Alaska",
	AZ: "Arizona",
	AR: "Arkansas",
	CA: "California",
	CO: "Colorado",
	CT: "Connecticut",
	DE: "Delaware",
	DC: "District of Columbia",
	FL: "Florida",
	GA: "Georgia",
	HI: "Hawaii",
	ID: "Idaho",
	IL: "Illinois",
	IN: "Indiana",
	IA: "Iowa",
	KS: "Kansas",
	KY: "Kentucky",
	LA: "Louisiana",
	ME: "Maine",
	MT: "Montana",
	NE: "Nebraska",
	NV: "Nevada",
	NH: "New Hampshire",
	NJ: "New Jersey",
	NM: "New Mexico",
	NY: "New York",
	NC: "North Carolina",
	ND: "North Dakota",
	OH: "Ohio",
	OK: "Oklahoma",
	OR: "Oregon",
	MD: "Maryland",
	MA: "Massachusetts",
	MI: "Michigan",
	MN: "Minnesota",
	MS: "Mississippi",
	MO: "Missouri",
	PA: "Pennsylvania",
	RI: "Rhode Island",
	SC: "South Carolina",
	SD: "South Dakota",
	TN: "Tennessee",
	TX: "Texas",
	UT: "Utah",
	VT: "Vermont",
	VA: "Virginia",
	WA: "Washington",
	WV: "West Virginia",
	WI: "Wisconsin",
	WY: "Wyoming",
};

export const CANADA_STATES = {
	AB: "ALBERTA",
	BC: "BRITISH COLUMBIA",
	MB: "MANITOBA",
	NB: "NEW BRUNSWICK",
	NL: "NEWFOUNDLAND and LABRADOR",
	NT: "NORTHWEST TERRITORIES",
	NS: "NOVA SCOTIA",
	NU: "NUNAVUT",
	ON: "ONTARIO",
	PE: "PRINCE EDWARD ISLAND",
	QC: "QUEBEC",
	SK: "SASKATCHEWAN",
	YT: "YUKON",
};

export const MRR_BENCHMARKS = { "Pre-Seed": null, Seed: 25000, "Series A": 200000, Growth: 200000 };
export const CAC_BENCHMARKS = { "Pre-Seed": null, Seed: 12, "Series A": 12, Growth: 12 };
export const BURN_RUNWAY_BENCHMARKS = { "Pre-Seed": 18, Seed: 18, "Series A": 18, Growth: 18 };
export const BURN_MULTIPLE_BENCHMARKS = { "Pre-Seed": null, Seed: 1.5, "Series A": 1.5, Growth: 1.5 };
export const MAGIC_NO_BENCHMARKS = { "Pre-Seed": null, Seed: 1, "Series A": 1, Growth: 1 };

export const PARTNER_SEGMENTATIONS = [
	{ id: 1, label: "Segment my Startups from StartupOS", checked: false },
	{ id: 2, label: "Segment my Founders from StartupOS", checked: false },
	{ id: 3, label: "Segment my Mentors from StartupOS", checked: false },
	{ id: 4, label: "Segment my Investors from StartupOS", checked: false },
	{ id: 5, label: "Allow StartupOS Events", checked: false },
];

export const OPP_DASH_SLIDER_OPTIONS = [
	{
		value: 0,
		label: "$0",
	},
	{
		value: 250000,
		label: "$250k",
	},
	{
		value: 500000,
		label: "$500k",
	},
	{
		value: 750000,
		label: "$750k",
	},
	{
		value: 1000000,
		label: "$1M+",
	},
];

export const RUNWAY_SLIDER_OPTIONS = [
	{
		value: 0,
		label: "0",
	},
	{
		value: 6,
		label: "6",
	},
	{
		value: 12,
		label: "12",
	},
	{
		value: 18,
		label: "18",
	},
	{
		value: 24,
		label: "24",
	},
	{
		value: 30,
		label: "30",
	},
	{
		value: 36,
		label: "36+",
	},
];

export const FUNDING_RAISED_SLIDER_OPTIONS = [
	{
		value: 0,
		label: "$0",
	},
	{
		value: 500000,
		label: "$500k",
	},
	{
		value: 1000000,
		label: "$1M",
	},
	{
		value: 1500000,
		label: "$1.5M",
	},
	{
		value: 2000000,
		label: "$2M",
	},
	{
		value: 2500000,
		label: "$2.5M",
	},
	{
		value: 3000000,
		label: "$3M+",
	},
];

export const ASK_SLIDER_OPTIONS = [
	{
		value: 0,
		label: "$0",
	},
	{
		value: 3000000,
		label: "$3M",
	},
	{
		value: 6000000,
		label: "$6M",
	},
	{
		value: 9000000,
		label: "$9M",
	},
	{
		value: 12000000,
		label: "$12M",
	},

	{
		value: 15000000,
		label: "$15M+",
	},
];

export const CREATE_ACCOUNT_TEXT = [
	"Access powerful AI tools and resources",
	"Connect with mentors, investors, and a thriving network of founders",
	"Explore our interactive chat, social feed and more",
];

export const CREATE_ACCOUNT_TEXT_FOR_GENAI = [
	<>
		<Typography variant="Text/sm/Semibold">Innovation at the Speed of AI: </Typography>
		Gain entry into our StartupOS AI Accelerator, where generative AI empowers your startup to disrupt industries and captivate markets.
	</>,
	<>
		<Typography variant="Text/sm/Semibold">Strategic Alliances for Growth: </Typography>
		Propel your brand into the spotlight with valuable exposure and credibility. Leverage our established enterprise connections for a broader customer
		reach.
	</>,
	<>
		<Typography variant="Text/sm/Semibold">Expand Horizons, Realize Potential: </Typography>
		Cultivate expertise, seize scaling possibilities, and weave into a network rich with possibility.
	</>,
	<>
		<Typography variant="Text/sm/Semibold">One Year Free Premium Subscription: </Typography>
		Gain exclusive access to Enterprises, Investors, and AI Powerup Tools. Our platform not only connects startups with a substantial customer base and
		investors, but also offers guidance and a mentor network, equipping your venture with all the tools necessary for dynamic growth and success{" "}
	</>,
];

export const CREATE_ACCOUNT_TEXT_FOR_SVB = [
	<>
		<Typography variant="Text/sm/Semibold">AI-Powered Resources: </Typography>
		Tackle key startup challenges like Lean Canvas, Generating Personas, Market Opportunity Analysis, Pricing Modeling, and more.
	</>,
	<>
		<Typography variant="Text/sm/Semibold">AI Agent (Bolt): </Typography>
		Get personalized guidance and feedback on your startup or pitch deck.
	</>,
	<>
		<Typography variant="Text/sm/Semibold">Startup Network: </Typography>
		Connect with mentors and fellow founders, share experiences, and seek valuable guidance.
	</>,
	<>
		<Typography variant="Text/sm/Semibold">Pitch Deck Generator: </Typography>
		Craft a pitch-perfect presentation with our easy to use pitch deck generator.{" "}
	</>,
];

export const CREATE_ACCOUNT_TEXT_FOR_SVBBHHM = [
	<>Access powerful AI tools and resources</>,
	<>Connect with mentors, investors, and a thriving network of founders</>,
	<>Explore our interactive chat, social feed and more</>,
];

export const PARTNER_QA_DATA = [
	{
		question: "What types of media do you think are relevant to your medium?",
		answer: "Providing access to tools, guidance, and capital for all founders at every stage of growth. The ultimate all-in-one platform for all founders. Providing access to tools, guidance, and capital for all founders at every stage of growth. The ultimate all-in-one platform for all founders.",
	},
	{
		question:
			"What types of media do you think are relevant to your medium? What types of media do you think are relevant to your medium? What types of media do you think are relevant to your medium?",
		answer: "Providing access to tools, guidance, and capital for all founders at every stage of growth. The ultimate all-in-one platform for all founders. Providing access to tools, guidance, and capital for all founders at every stage of growth. The ultimate all-in-one platform for all founders.",
	},
	{
		question: "What types of media do you think are relevant to your medium? What types of media do you think are relevant to your medium?",
		answer: "Providing access to tools, guidance, and capital for all founders at every stage of growth. The ultimate all-in-one platform for all founders. Providing access to tools, guidance, and capital for all founders at every stage of growth. The ultimate all-in-one platform for all founders.",
	},
	{
		question: "What types of media do you think are relevant to your medium?",
		answer: "Providing access to tools, guidance, and capital for all founders at every stage of growth. The ultimate all-in-one platform for all founders. Providing access to tools, guidance, and capital for all founders at every stage of growth. The ultimate all-in-one platform for all founders.",
	},
];

export const LOADING_ITEMS = [1, 2, 3, 4, 5, 6];
