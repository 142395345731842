export default {
	USER_ALREADY_EXISTS: "User already exists",
	EMAIL_ADDRESS_UPDATED: "Email address updated",
	RESET_PASSWORD: "Reset Password email successfully sent",
	VERIFICATION_EMAIL: "Verification email successfully sent",
	COMPANY_NAME_LENGTH_INVALID: "Name should be between 2-225 characters",
	COMPANY_NAME_CHARACTERS_INVALID: "Invalid characters only (,.& ) are allowed",
	AUTOCOMPLETE_INPUT_INVALID: "Input should be between 2-{{maxLength}} characters",
	LOCATION_INVALID:
		"StartupOS is currently open to founders building companies headquartered in the United States. Applications for companies based outside of the US is expected to open later this year.",
	UPLOAD_SIZE: "Maximum upload size exceeded.",
	COMPANY_NAME_BLANK: "Please enter company name",
	TAGLINE_BLANK: "Please enter tagline",
	PRODUCT_OFFERING_BLANK: "Please enter product offering",
	TARGET_MARKET_BLANK: "Please enter product offering",
	INCORPORATION_BLANK: "Please enter product offering",
	LOCATION_BLANK: "Please enter the location",
	STARTUP_SIZE_BLANK: "Please enter startup size",
	FOUNDING_DATE_BLANK: "Please enter valid founding date",
	FOUNDING_DATE_INVALID: "Please enter valid year",
	COMPANY_SITE_BLANK: "Please enter company site",
	ABOUT_BLANK: "Please enter about",
	LOGO_BLANK: "Please attach logo",
	MULTITAG_INPUT_INVALID: "Invalid input, should contain 1-30 characters",
	MULTITAG_INPUT_INVALID_100: "Invalid input, should contain 1-100 characters",
	UNSAVED_CHANGES_PROMPT_MESSAGE: "Are you sure you want to discard the changes you made?",
	EMAIL_VERIFIED: "Email verified successfully. Please login again.",
	API_SUBMIT_ERROR: "Something went wrong!",
	EXCERCISE_STORE_ERROR: "Error while storing excercise details",
	TEXTBOX_VALIDATION: "Answer should be between 30-300 characters",
	STORYTELLER_TEXTBOX_VALIDATION: "Input should not exceed 2000 characters",
	LOCATION_INVALID: "Please enter valid zip code",
	ACV_INPUT_INVALID: "Invalid input, expected is $1 and above",
	TAM_SAM_SOM_TEXTFIELD: "Input should between 4-80 characters",
	TAM_SAM_SOM_NUMBERFIELD: "Input should between 3-15 characters",
	PERSONANAME_INPUT_INVALID: "Name should be between 3-300 characters",
	PERSONA_AVATAR_INVALID: "Please select Avatar",
	FILE_UPLOAD_ERROR: "File upload fail, please try again",
	NAME_VALID: "Please enter valid name",
	INVALID_EMAIL: "Please enter valid email",
	MEETING_SCHEDULED_SUCCESS: "Meeting scheduled successfully",
	MEETING_SCHEDULED_ERROR: "Error scheduling meeting",
	SAVE_ERROR: "Error occured while saving",
	SAVE_SUCCESS: "Saved successfully",
	CHANGES_SAVE_SUCCESS: "Changes saved successfully",
	QUESTION_ADDED_SUCCESS: "Question added successfully",

	PROBLEM_INPUT_INVALID: "Invalid input, input should contain 3-200 characters",
	INVALID_TAGS: "Keep in mind Google Trends only allows 5 or less tags",
	SESSION_LOGOUT: "Session Expired. Please login again.",
	INVALID_URL: "Please enter valid URL",
	INVALID_LINKEDIN_COMPANY_URL: "Please enter valid company profile URL",
	DESCRIPTION: "Invalid Input, Input Should contain 500 characters max",
	LOCATION_LENGTH_INVALID: "Location should be between 2-100 characters",
	SCHOOL_LENGTH_INVALID: "School name should be between 2-100 characters",
	ACCOLADE_LENGTH_INVALID: "Accolade should be between 2-100 characters",
	TITLE_LENGTH_INVALID: "Title should be between 2-100 characters",
	LINK_TITLE_LENGTH_INVALID: "Title should be between 0-500 characters",

	START_DATE_ERROR: "Start Date should be less than the End Date",
	END_DATE_ERROR: "End Date should be greater than the Start Date",
	UNSAVE_SUCCESS: "Unsaved successfully",
	MESSAGE_EXCEED: "Message should not exceed 200 characters.",
	MENTOR_FIRST_NAME_BLANK: "Please enter mentor's first name",
	MENTOR_LAST_NAME_BLANK: "Please enter mentor's last name",
	MENTOR_PROFESSION: "Please enter position",
	ADD_SUCCESS: "Added successfully",
	EMAIL_SUBJECT_LENGTH_INVALID: "Email Subject should be between 3-80 characters",
	EMAIL_MESSAGE_LENGTH_INVALID: "Email Message should be between 3-500 characters",
	CANDIDATE_PROFESSION: "Please enter profession",
	ARCHIVE_SUCCESS: "Archived powerup version",
	ARCHIVE_ERROR: "Error occured while archive",
	COMPLETE_ONBOARDING_WARNING: "Please complete onboarding process if you are signing up as a founder.",
	MULTI_TAG_INSTRUCTION: "Use enter key to separate tags, or hit [+ Add] button.",
	TAG_INSTRUCTION: "Use enter key to separate tags.",
	MULTI_TAG_SELECT: "Use enter key to separate tags.",
	INVALID_INVITE: "The invite you are using is no longer valid",
	BIO_LENGTH: "The bio should be between 3-1000",
	INVALID_PHONE_NUMBER: "Phone number format is invalid ,  valid format :- XXX-XXX-XXXX",
	SEND_SURVEY_SUCCESS: "The survey has been sent, check back for results",
	SEND_SURVEY_ERROR: "There was an error sending the survey",
	SURVEY_LOGO_ERROR: "Please upload your logo",
	LINKEDIN_ERROR: "We were not able to collect your data from Linkedin at this time.Please check back in few hours.",

	STARTUP_NAME_BLANK: "Please enter startup name",
	COUNTRY_NAME_BLANK: "Please select country name",
	STATE_NAME_BLANK: "Please select state name",
	CITY_NAME_BLANK: "Please enter city name",
	ZIPCODE_BLANK: "Please enter zipcode",
	PHONE_BLANK: "Please enter phone number",
	WEBSITE_BLANK: "Please enter website URL",
	TWITTER_BLANK: "Please enter twitter URL",
	LINKEDIN_BLANK: "Please enter linkedin URL",
	HEADLINE_BLANK: "Please enter headline",

	PHONE_VALID: "Please enter valid phone number",
	WEBSITE_VALID: "Please enter valid website URL",
	LINKEDIN_VALID: "Please enter valid linkedin URL",
	TWITTER_VALID: "Please enter valid twitter URL",

	CHECKOUT_SUCCESS: "Your subscription is active. Welcome aboard!",
	CHECKOUT_ERROR: "There was a problem creating your subscription.",

	FILE_UPLOAD_SUCCESS: "File uploaded successfully.",
	FILE_CREATED_SUCCESS: "File created successfully.",
	INVALID_FILE_TYPE: "Invalid file type. Please select an XLS file.",
	GOOGLE_SHEET_DATA_FECTHED: "Google Sheet Data Fetched Successfully",

	FOUNDER_STORY_SUCCESS: "Founder story updated successfully.",
	ELEVATOR_PITCH_SUCCESS: "Elevator pitch updated successfully.",

	PITCH_EVENT_SUCESS: "Successfully Applied to Pitch Event",
	MIISING_FIELDS: "Please fill out all required fields",
	REQUIRED_FIELD: "This field is required",
	EMPTY_STARTUP: "Enter a name for your Startup",
	EMPTY_EMAIL: "Enter a valid email address",
	EMPTY_ABOUT: "Enter a description for your Startup",
	EMPTY_STAGE: "Select the stage of your startup",
	EVENT_INVITATION_SENT: "Event invitation email successfully sent",
	EVENT_CREATED_SUCCESS: "Event created successfully.",
	EVENT_UPDATED_SUCCESS: "Event updated successfully.",
	VOTING_SCORECARD_CREATED_SUCCESS: "Scorecard created successfully.",
	VOTING_SCORECARD_UPDATED_SUCCESS: "Scorecard updated successfully.",
	CREATE_AVAILABILITY_SUCCESS: "Availability created successfully",
	CREATE_AVAILABILITY_ERROR: "Create availability error",
	QUESTION_DELETED_SUCCESS: "Question deleted successfully.",
	POWERUP_PACK_CREATED_SUCCESS: "PowerPack created successfully.",
	POWERUP_PACK_UPDATED_SUCCESS: "PowerPack updated successfully.",

	POWERUP_PACK_DELETED_SUCCESS: "PowerPack deleted successfully.",

	POWERUP_PACKS_UPDATED: "PowerPacks updated successfully.",
	POWERUP_PACKS_UPDATED_ERROR: "Error updating Powerup Pack.",
};
