import React from "react";
import { Box, Chip, Typography } from "@mui/material";

function TableHeader({
	title,
	description,
	icon,
	showCount,
	showSelectedCount,
	countLabel = { singular: "", plural: "" },
	sortComponent,
	filterComponent,
	rowCount,
	rowSelectionCount,
	searchComponent,
}) {
	return (
		<Box
			sx={{
				display: "flex",
				padding: "20px 24px",
				alignItems: "flex-start",
				gap: "16px",
				alignSelf: "stretch",
				borderBottom: "1px solid var(--gray-200, #EAECF0)",
				flexWrap: "wrap",
			}}
		>
			<Box
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 1,
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "flex-start",
						gap: "16px",
					}}
				>
					{icon}
					<Typography variant="Text/lg/Semibold">{title}</Typography>
					{showCount && (
						<Chip
							variant="DS1"
							label={rowCount > 1 || rowCount <= 0 ? `${rowCount <= 0 ? "0" : rowCount} ${countLabel?.plural}` : `1 ${countLabel?.singular}`}
						/>
					)}
					{showSelectedCount && (
						<Chip
							variant="DS1"
							label={
								rowSelectionCount > 1 || rowSelectionCount <= 0
									? `${rowSelectionCount <= 0 ? "0" : rowSelectionCount} of ${rowCount} ${countLabel?.plural} Selected`
									: `1 of ${rowCount} ${countLabel?.singular} Selected`
							}
						/>
					)}
				</Box>
				{description && <Typography variant="Text/xs/Regular">{description}</Typography>}
			</Box>
			{(sortComponent || filterComponent || searchComponent) && (
				<Box
					sx={{
						display: "flex",
						flex: 1,
						justifyContent: "flex-end",
						alignItems: "center",
						flexWrap: "wrap",
						gap: 2,
					}}
				>
					{searchComponent}
					{sortComponent}
					{filterComponent}
				</Box>
			)}
		</Box>
	);
}

export default TableHeader;
