import * as actionTypes from "../../actions/actionTypes";
import { updateObject } from "../../../utils/updateObject";

const initialState = {
	show: false,
	message: "",
};

const modal406 = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SHOW_ACCOUNT_406_MODAL: {
			if (action.payload === null) return state;
			return updateObject(state, { show: action.payload?.show, message: action.payload?.message });
		}
		case actionTypes.DISMISS_ACCOUNT_406_MODAL: {
			return updateObject(initialState);
		}
		default:
			return state;
	}
};
export default modal406;
