import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Grid, Stack, Box } from "@mui/material";
import LoadingButton from "../Loadingbutton";
import { CLOSE_ICON_V2 } from "../../constants";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		px: theme.spacing(1),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

const BootstrapDialogTitle = (props) => {
	const { children, onClose, ...other } = props;

	return (
		<DialogTitle {...other}>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				{" "}
				{children}
				{onClose ? (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							color: (theme) => theme.palette.secondary.dark,
						}}
					>
						<Box component={"img"} src={CLOSE_ICON_V2} alt="back" />
					</IconButton>
				) : // <IconButton
				// 	aria-label="close"
				// 	onClick={onClose}
				// 	sx={{
				// 		position: "absolute",
				// 		right: 8,
				// 		top: 8,
				// 		color: (theme) => theme.palette.text.atomic,
				// 	}}
				// >
				// 	<CloseIcon />
				// </IconButton>
				null}
			</Box>
		</DialogTitle>
	);
};

BootstrapDialogTitle.propTypes = {
	children: PropTypes.node,
	onClose: PropTypes.func.isRequired,
};

export default function MessageHandlerModal({
	isOpen,
	onCancel,
	onOk,
	text,
	okText,
	cancelText,
	messageType,
	style,
	heading,
	disableBackDropClick = true,
	icon,
}) {
	const [loader, setLoader] = useState(false);
	const handleClose = () => {
		setLoader(false);
		onCancel();
	};
	const handleOk = () => {
		setLoader(true);
		onOk();
	};

	useEffect(() => {
		if (isOpen) {
			setLoader(false);
		}
	}, [isOpen]);

	return (
		<div>
			<BootstrapDialog
				onClose={disableBackDropClick ? () => {} : handleClose}
				aria-labelledby="customized-dialog-title"
				open={isOpen}
				PaperProps={{
					sx: (theme) => ({
						borderRadius: theme.shape.standard13.borderRadius,
						boxShadow: "0px 5px 16px rgba(0, 0, 0, 0.3)",
						p: 3,
						...style,
					}),
				}}
			>
				<BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
					<Box sx={{ display: "flex", flex: 1, gap: 2 }}>
						{icon && <Box>{icon}</Box>}
						<Typography variant="Text/md/Semibold" color="secondary.korophKoal">
							{heading}
						</Typography>
					</Box>
				</BootstrapDialogTitle>
				<DialogContent>
					<Typography variant="Text/sm/Regular" color="secondary.korophKoal">
						{text}
					</Typography>
				</DialogContent>
				<DialogActions>
					<Grid container spacing={2} sx={{ display: "flex", width: "max-content", alignItems: "center" }}>
						<Grid item>
							<Button variant="noOutlineNew" onClick={onCancel}>
								{cancelText}
							</Button>
						</Grid>
						<Grid item>
							<LoadingButton onClick={handleOk} variant="DS1" color={messageType} loading={loader} loadingStyles={{ px: 3 }}>
								{okText}
							</LoadingButton>
						</Grid>
					</Grid>
				</DialogActions>
			</BootstrapDialog>
		</div>
	);
}
