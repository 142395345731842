import { Box, Button, Typography, Divider, Grid, useMediaQuery, Chip, Stack } from "@mui/material";
import React, { useContext, useEffect, useMemo, useRef } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Card } from "../Card";
import ROUTES_PATH from "../../../../../constants/routes";
import { generatePath, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";
import { MB_LOGO_ICON } from "../../../../../constants";

function PowerupCourseCard({ cardDetails }) {
	const cardType = cardDetails?.cardType;
	const title = cardDetails?.title;
	const cardStatus = cardDetails?.cardStatus;
	const linkedEntityId = cardDetails?.linkedEntityId;
	const uuid = cardDetails?.uuid;
	const taskStatus = cardDetails?.cardProgressDetails?.taskStatus;
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const history = useHistory();

	const getSurveyId = () => {
		let pluginUuidData = uuid ? uuid : linkedEntityId;

		return pluginUuidData.replace("SURVEY_", "");
	};
	const btnTitle = useMemo(() => {
		if (title === "Meet a mentor") {
			return "Schedule";
		} else if (title === "Incorporate with Firstbase.io") {
			return "View";
		} else if (title === "Explore Our Marketplace") {
			return "View";
		} else if (title === "Message an Investor") {
			return "Message";
		} else if (title === "Sign Up for an Event") {
			return "View";
		} else if (title === "Explore Our Resource Center") {
			return "View";
		} else if (title === "Explore Our Network") {
			return "View";
		} else {
			if (cardStatus === "PUBLISHED") {
				if (taskStatus === "STARTED") {
					return "In Progress";
				} else if (taskStatus === "COMPLETED") {
					return "Completed";
				} else {
					return "Start";
				}
			} else if (cardStatus === "COMING_SOON") {
				return "Coming Soon";
			}
		}

		return "Start";
	}, [cardStatus]);

	const isSurvey = () => {
		return (
			title === "Net Promoter Score Survey" ||
			title === "Product Market Fit Survey" ||
			title === "Category Awareness Survey" ||
			title === "Likert Scale Survey" ||
			title === "Logo Testing Survey" ||
			title === "Ad Testing Survey"
		);
	};
	const handleRedirect = () => {
		if (cardType === "POWERUP_BUILDER") {
			if (cardStatus && cardStatus !== "COMING_SOON" && cardStatus !== "NOT_READY") {
				history.push({
					pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_BUILDER_DETAILS, {
						id: linkedEntityId,
					}),
				});
			}
		} else if (cardType === "POWERUP") {
			if (cardStatus && cardStatus !== "COMING_SOON" && cardStatus !== "NOT_READY") {
				history.push({
					pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
						id: linkedEntityId,
					}),
				});
			}
		} else if (cardType === "SURVEY") {
			if (title === "Price Sensitive Survey" || title === "Price Sensitivity Survey") {
				// do nothing
			} else if (title === "Mentor Checkin") {
				history.push({
					pathname: generatePath("/workspace/plugin/mentor-check-in"),
				});
			} else if (title === "Mentor Plugin: Fine Tuning" || title === "Fine Tuning") {
				history.push({
					pathname: generatePath("/workspace/plugin/mentor-check-in"),
				});
			} else if (title === "Idea Validation Interview" || title === "Market Test: Persona Interview" || title === "Market Test: Discovery Interview") {
				history.push({
					pathname: generatePath(ROUTES_PATH.MARKET_TEST, {
						id: linkedEntityId,
					}),
				});
			} else if (isSurvey(title)) {
				history.push({
					pathname: generatePath(ROUTES_PATH.TOOL_CONTROLLER, {
						id: getSurveyId(),
					}),
				});
			}
		} else if (cardType === "ACTIVITY") {
			window.location.href = cardDetails?.redirectUrl;
		}
	};
	const BtnPowerPack = ({ status, handleRedirect }) => {
		if (status === "Completed") {
			return (
				<Button
					onClick={handleRedirect}
					sx={{
						borderRadius: "100px",
						background: "#F2F4F7 !important",
						fontSize: 14,
						color: "#344054 !important",
						"&:hover": { background: "#F2F4F7", color: "#344054" },
					}}
					endIcon={<CheckIcon sx={{ fontSize: 14, color: "#344054" }} />}
				>
					Completed
				</Button>
			);
		} else if (status === "In Progress") {
			return (
				<Button onClick={handleRedirect} sx={{ borderRadius: "100px", background: "#7B61FF" }} endIcon={<ArrowForwardIcon sx={{ fontSize: 20 }} />}>
					Complete Now
				</Button>
			);
		} else if (status === "Coming Soon") {
			return (
				<Button variant="text" onClick={handleRedirect} sx={{ borderRadius: "100px" }}>
					Coming Soon
				</Button>
			);
		} else if (status === "schedule") {
			return (
				<Button onClick={handleRedirect} sx={{ borderRadius: "100px", background: "#7B61FF" }} endIcon={<ArrowForwardIcon sx={{ fontSize: 20 }} />}>
					Schedule Now
				</Button>
			);
		}

		return (
			<Button onClick={handleRedirect} sx={{ borderRadius: "100px", background: "#7B61FF" }} endIcon={<ArrowForwardIcon sx={{ fontSize: 20 }} />}>
				Start
			</Button>
		);
	};

	const getStatus = () => {
		if (cardStatus === "COMING_SOON") {
			return "coming";
		} else if (cardDetails?.activityType === "SCHEDULE_PITCH_SESSION" && cardType === "ACTIVITY") {
			return "schedule";
		} else if (cardStatus === "PUBLISHED" && cardType === "POWERUP") {
			if (cardDetails?.cardProgressDetails?.taskStatus === "STARTED") {
				return "progress";
			} else if (cardDetails?.cardProgressDetails?.taskStatus === "COMPLETED") {
				return "completed";
			} else {
				return "in-complete";
			}
		}

		return "progress";
	};
	return (
		<React.Fragment>
			{/* <Grid container spacing={2} alignItems={"flex-start"}>
				<Grid item md={2} lg={2} display={"flex"} alignItems={"center"}>
					<Box sx={{ width: 1 }}>
						<Box width={"inherit"} component="img" src={cardDetails?.imageUrl} alt="PowerPack" />
					</Box>
				</Grid>
				<Grid item md={10} lg={10}>
					<Box>
						<Box
							display="flex"
							flexDirection={isSmallScreen ? "column" : "row"}
							alignItems={isSmallScreen ? "flex-start" : "flex-end"}
							justifyContent="space-between"
							gap={2}
							mb={2}
						>
							<Typography sx={{ maxWidth: isSmallScreen ? 1 : "50%" }} variant="Text/md/Semibold">
								{title}
							</Typography>
							<Box>
								<BtnPowerPack status={btnTitle} handleRedirect={handleRedirect} />
							</Box>
						</Box>

						<Typography variant="Text/sm/Regular">{cardDetails?.shortDescription}</Typography>
					</Box>
				</Grid>
			</Grid> */}

			<Box display={"flex"} gap={2} flexWrap={isSmallScreen && "wrap"}>
				<Box sx={{ width: "200px", position: "relative" }}>
					<Box width={"inherit"} component="img" src={cardDetails?.imageUrl} alt="PowerPack" />
					{cardDetails?.isAiPowered && (
						<Box
							sx={{
								position: "absolute",
								top: "1rem",
								left: "1rem",
							}}
						>
							<Chip
								sx={{ background: "white" }}
								label={
									<Typography variant="Text/xs/Medium" color={"#344054"}>
										AI Powered
									</Typography>
								}
							/>
						</Box>
					)}
				</Box>
				<Box sx={{ width: 1 }}>
					<Box
						display="flex"
						flexDirection={isSmallScreen ? "column" : "row"}
						alignItems={isSmallScreen ? "flex-start" : "flex-end"}
						justifyContent="space-between"
						gap={2}
						mb={2}
					>
						<Typography sx={{ maxWidth: isSmallScreen ? 1 : "50%" }} variant="Text/md/Semibold">
							{title}
						</Typography>
						<Box>
							<BtnPowerPack status={btnTitle} handleRedirect={handleRedirect} />
						</Box>
					</Box>

					<Typography variant="Text/sm/Regular">{cardDetails?.shortDescription}</Typography>
					{/* {title === "MVP Prioritizer" && (
						<Stack direction={"row"} spacing={1} alignItems={"flex-end"} sx={{ justifyContent: "end" }}>
							<Typography variant="label_large">Brought to you by</Typography>
							<Box component={"img"} sx={{ height: "40px" }} src={MB_LOGO_ICON} alt="mb" />
						</Stack>
					)} */}
				</Box>
			</Box>
			<Divider sx={{ mt: 3 }} />
		</React.Fragment>
	);
}

export default PowerupCourseCard;
