import { Avatar, Typography, Box, Stack, Button, Checkbox, CircularProgress } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import React, { Fragment, useRef } from "react";
import { EventTooltip } from "../style";
import moment from "moment";

const columns = [
	{
		field: "name",
		headerName: "Company",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 200,
		renderCell: (params) => (
			<Fragment>
				<Avatar src={params.value?.img} sx={{ mr: 1 }} />
				<Typography variant="Text/xs/Regular">{params.value?.name}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "foundedYear",
		headerName: "Founded",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "industry",
		headerName: "Industry",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value?.[0]}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "productOffering",
		headerName: "Product Category",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "incorporatedStatus",
		headerName: "Incorporated",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "currentScore",
		headerName: "StartupOS Score",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => (
			<Typography variant="Text/xs/Medium" sx={{ whiteSpace: "wrap" }}>
				{params.colDef.headerName}
			</Typography>
		),
	},
];
const formatCreatedAt = (createdAt) => {
	const date = new Date(createdAt);
	const options = { month: "2-digit", day: "2-digit", year: "numeric" };
	const dateString = date.toLocaleDateString("en-US", options);
	const timeString = date.toLocaleTimeString("en-US", { hour: "2-digit", minute: "2-digit", hour12: true });
	return `${dateString} at ${timeString}`;
};
const getEventDetails = (eventNames, createdDates) => (
	<Box sx={{ padding: "5px" }}>
		<Typography variant="Text/sm/Semibold">Invite Dates & Time</Typography>
		<Stack spacing={1} mt={1}>
			{eventNames.map((eventName, index) => (
				<Box display={"flex"} alignItems={"center"} gap={3}>
					<Typography component={"p"} variant="Text/sm/Regular" sx={{ width: "400px" }}>
						{eventName}
					</Typography>
					<Typography variant="Text/sm/Regular">{createdDates[index]}</Typography>
				</Box>
			))}
		</Stack>
	</Box>
);
const prospectColumns = [
	{
		field: "name",
		headerName: "Company",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 200,
		renderCell: (params) => (
			<Fragment>
				<Avatar src={params.value?.img} sx={{ mr: 1 }} />
				<Typography variant="Text/xs/Regular">{params.value?.name}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "foundedYear",
		headerName: "Founded",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "industry",
		headerName: "Industry",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value?.[0]}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "productOffering",
		headerName: "Product Category",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "incorporatedStatus",
		headerName: "Incorporated",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "currentScore",
		headerName: "StartupOS Score",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => (
			<Typography variant="Text/xs/Medium" sx={{ whiteSpace: "wrap" }}>
				{params.colDef.headerName}
			</Typography>
		),
	},
	{
		field: "invitedTo",
		headerName: "Invited To",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 500,
		renderCell: (params) => {
			const eventNames = params?.row?.pitchEventInvitationModels.map((invitation) => invitation.eventName);
			const createdDates = params?.row?.pitchEventInvitationModels.map((invitation) => formatCreatedAt(invitation.createdAt));

			return (
				<>
					{eventNames.length > 0 ? (
						<>
							<EventTooltip title={getEventDetails(eventNames, createdDates)} placement={"right"} arrow={true}>
								<Typography
									variant="Text/xs/Regular"
									sx={{ maxWidth: "400px", textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" }}
								>
									{eventNames.join(", ")}
								</Typography>
							</EventTooltip>

							{/* <EventTooltip title={getEventDetails(eventNames, createdDates)} placement={"right"} arrow={true}>
								<MoreVertIcon sx={(theme) => ({ fontSize: 18, color: theme.palette.secondary.main, ml: "auto" })} />
							</EventTooltip> */}
						</>
					) : (
						<Typography variant="Text/xs/Regular">No Data</Typography>
					)}
				</>
			);
		},
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
];

const acceptedStartupColumns = [
	{
		field: "name",
		headerName: "Company",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 200,
		renderCell: (params) => (
			<Fragment>
				<Avatar src={params.value?.img} sx={{ mr: 1 }} />
				<Typography variant="Text/xs/Regular">{params.value?.name}</Typography>
			</Fragment>
		),
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "foundedYear",
		headerName: "Founded",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 100,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "industry",
		headerName: "Industry",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value?.[0]}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "productOffering",
		headerName: "Product Category",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "currentScore",
		headerName: "StartupOS Score",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => (
			<Typography variant="Text/xs/Medium" sx={{ whiteSpace: "wrap" }}>
				{params.colDef.headerName}
			</Typography>
		),
	},
	{
		field: "incorporatedStatus",
		headerName: "Incorporated",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 150,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "pitchDeckLink",
		headerName: "Pitch Deck Uploaded",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 180,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value ? "Yes" : "No"}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
];

const eventApplicationColumns = [
	{
		field: "name",
		headerName: "Applicant Name",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 200,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	{
		field: "createdAt",
		headerName: "Submitted",
		sortable: false,
		filterable: false,
		wrapText: true,
		width: 250,
		renderCell: (params) => <Typography variant="Text/xs/Regular">{params?.value ? formatCreatedAt(params?.value) : ""}</Typography>,
		renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	},
	//{
	//	field: "previewForm",
	//	headerName: "Preview",
	//	sortable: false,
	//	filterable: false,
	//	wrapText: true,
	//	width: 250,
	//	renderCell: (params) => (
	//		<Button
	//			iconButton={true}
	//			small={true}
	//			variant="DS1"
	//			onClick={(e) => {
	//				e.preventDefault();
	//				e.stopPropagation();
	//				if (params?.row?.formStructure)
	//					setpreviewForm({
	//						open: true,
	//						formString: params?.row?.formStructure,
	//					});
	//			}}
	//		>
	//			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
	//				<path
	//					d="M8 22.6667C8 23.1351 8 23.3693 8.02089 23.5729C8.19433 25.2632 9.41681 26.6595 11.0694 27.0548C11.2685 27.1025 11.5007 27.1334 11.9649 27.1953L20.7542 28.3672C23.2561 28.7008 24.507 28.8676 25.4779 28.4815C26.3304 28.1426 27.0404 27.521 27.489 26.7208C28 25.8094 28 24.5474 28 22.0234V9.97663C28 7.45257 28 6.19054 27.489 5.27917C27.0404 4.47902 26.3304 3.85738 25.4779 3.51844C24.507 3.13239 23.2561 3.29918 20.7542 3.63277L11.9649 4.80467C11.5006 4.86657 11.2685 4.89753 11.0694 4.94514C9.41681 5.34046 8.19433 6.73676 8.02089 8.42711C8 8.63072 8 8.86492 8 9.33332M16 10.6667L21.3333 16M21.3333 16L16 21.3333M21.3333 16H4"
	//					stroke="white"
	//					stroke-width="2"
	//					stroke-linecap="round"
	//					stroke-linejoin="round"
	//				/>
	//			</svg>
	//		</Button>
	//	),
	//	renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
	//},
];

export function ScorecardColumns(openEdit) {
	return [
		{
			field: "name",
			headerName: "Scorecard",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "scorecardStatus",
			headerName: "Active",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 100,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value && params.value == "PUBLISHED" ? "Yes" : "Draft"}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "startDate",
			headerName: "Start Date",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => (
				<Typography variant="Text/xs/Regular">{params?.value ? moment(params?.value, "YYYY-MM-DD").format("MMMM D, YYYY") : ""}</Typography>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "closeDate",
			headerName: "Close Date",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => (
				<Typography variant="Text/xs/Regular">{params?.value ? moment(params?.value, "YYYY-MM-DD").format("MMMM D, YYYY") : ""}</Typography>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "numberOfJudges",
			headerName: "Number of Judges",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "votingPercentage",
			headerName: "% Voted",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 200,
			renderCell: (params) => <Typography variant="Text/xs/Regular">{params.value}</Typography>,
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		{
			field: "editScorecard",
			headerName: "Edit",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 100,
			renderCell: (params) => (
				<Button
					iconButton={true}
					small={true}
					variant="DS1"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if (params?.row?.uuid) openEdit(params?.row?.uuid);
					}}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26" fill="none">
						<path
							d="M17.0673 9H4.33333C2.49238 9 1 7.50762 1 5.66667C1 3.82572 2.49238 2.33333 4.33333 2.33333H17.0673M8.93266 23.6667H21.6667C23.5076 23.6667 25 22.1743 25 20.3333C25 18.4924 23.5076 17 21.6667 17H8.93266M1 20.3333C1 22.9107 3.08934 25 5.66667 25C8.244 25 10.3333 22.9107 10.3333 20.3333C10.3333 17.756 8.244 15.6667 5.66667 15.6667C3.08934 15.6667 1 17.756 1 20.3333ZM25 5.66667C25 8.244 22.9107 10.3333 20.3333 10.3333C17.756 10.3333 15.6667 8.244 15.6667 5.66667C15.6667 3.08934 17.756 1 20.3333 1C22.9107 1 25 3.08934 25 5.66667Z"
							stroke="white"
							stroke-width="1.5"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>
				</Button>
			),
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
	];
}

export function AppliedStartupColumns(handleAccept, checkboxStates) {
	return [
		{
			field: "isAccepted",
			headerName: "Accepted",
			sortable: false,
			filterable: false,
			wrapText: true,
			width: 100,
			renderCell: (params) => {
				const state = checkboxStates?.[params?.row?.id] || { checked: false, disabled: false, loading: false };
				return !state?.loading ? (
					<Checkbox
						color="primary"
						sx={{ margin: "0 auto" }}
						checked={state?.checked || false}
						disabled={state?.disabled || false}
						onClick={(e) => e.stopPropagation()}
						onChange={(e) => {
							if (typeof handleAccept === "function") {
								handleAccept(params?.row?.id, e?.target?.checked);
							}
						}}
					/>
				) : (
					<CircularProgress size={24} sx={{ margin: "0 auto" }} onClick={(e) => e.stopPropagation()} />
				);
			},
			renderHeader: (params) => <Typography variant="Text/xs/Medium">{params.colDef.headerName}</Typography>,
		},
		...columns,
	];
}
export function ProspectsColumns() {
	return prospectColumns;
}

export function AcceptedStartupColumns() {
	return acceptedStartupColumns;
}

export function EventApplicationColumns() {
	return eventApplicationColumns;
}

export const AppliedStartupSortOptions = [
	{ name: "Company Name", colName: "companyName" },
	{ name: "Founded Year", colName: "foundedYear" },
	{ name: "Score", colName: "currentScore" },
];
