import React, { useState, useEffect } from "react";
import {
	Alert,
	AlertTitle,
	Avatar,
	Box,
	Button,
	Chip,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	LinearProgress,
	Slider,
	Typography,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Table from "@mui/material/Table";
import Paper from "@mui/material/Paper";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";
import PageTitle from "../../../common/PageTitle";
import services from "../../../services";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { STAGE_STEPPER_LIST } from "../../../constants";
import _ from "lodash"; // Import Lodash
import MessageHandlerModal from "../../../common/modal/MessageHandler";
import { ChatBubbleOutlineRounded, ChatRounded } from "@mui/icons-material";
import InfoHelper from "../../../components/Founder/InfoHelper";

const BREAK_POINTS = {
	120: {
		slidesPerView: 1,
		spaceBetween: 10,
	},

	712: {
		slidesPerView: 2,
		spaceBetween: 10,
	},
	1031: {
		slidesPerView: 2,
		spaceBetween: 10,
	},
	1336: {
		slidesPerView: 3,
		spaceBetween: 10,
	},
	1505: {
		slidesPerView: 3,
		spaceBetween: 10,
	},
	1705: {
		slidesPerView: 4,
		spaceBetween: 10,
	},
};

const ScoreBar = ({ startupname, percent, key }) => (
	<Grid
		container
		rowGap={1}
		//sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 3 }}
	>
		<Grid item xs={12} md={2}>
			<Typography variant="Text/xs/Regular">{startupname}</Typography>
		</Grid>
		<Grid item xs={11} md={9}>
			<LinearProgress
				variant="determinate"
				value={percent}
				sx={{
					flex: 1,
					height: "18px",
					borderRadius: "10px",
				}}
			/>
		</Grid>
		<Grid item xs={1} md={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
			<Typography variant="Text/xs/Regular">{percent}</Typography>
		</Grid>
	</Grid>
);

const CompanyStageChip = ({ stage }) => {
	const chipObj = STAGE_STEPPER_LIST.find((obj) => obj?.label === stage);

	return (
		<Chip
			sx={{ background: chipObj?.bgColor ? chipObj?.bgColor : "transparent" }}
			label={
				<Typography sx={{ color: chipObj?.color ? chipObj?.color : "rgba(152, 162, 179, 1)" }} variant="Text/xs/Regular">
					{chipObj?.label}
				</Typography>
			}
		/>
	);
};

const CompanyTile = ({ company, onClickHandler, isSelected }) => {
	const getIndustry = (industry) => {
		if (!industry) return "";

		try {
			const parsed = JSON.parse(industry);
			if (parsed && Array.isArray(parsed)) return parsed?.join(", ");
			else return industry;
		} catch (e) {
			console.log("Failed to process company industry field with error:", e);
			return industry;
		}
	};

	return (
		<Box
			onClick={() => onClickHandler()}
			sx={{
				cursor: "pointer",
				borderRadius: "24px",
				backgroundColor: (theme) => theme?.palette?.secondary?.white,
				p: 3,
				pb: 4,
				border: "4px solid",
				borderColor: "transparent",
				...(isSelected && {
					borderColor: "#7B61FF",
					boxShadow: "0px 10px 25px 0px rgba(0, 0, 0, 0.05)",
				}),
			}}
		>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2 }}>
				<Box sx={{ display: "flex", alignItems: "center" }}>
					<Avatar src={company?.logoUrl} sx={{ mr: 1 }} />
					<Typography variant="Text/sm/Semibold">{company?.companyName}</Typography>
				</Box>
				<Box>
					<CompanyStageChip stage={company?.currentStage} />
				</Box>
			</Box>
			<Box sx={{ mb: 4 }}>
				<Typography component={"p"} variant="Text/sm/Regular">
					{company?.tagLine}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path
						d="M11.8352 17.4605L6.26846 10.5021C5.92193 10.069 5.74866 9.85238 5.62545 9.6112C5.51614 9.39724 5.43625 9.16948 5.38794 8.93411C5.3335 8.66882 5.3335 8.39146 5.3335 7.83675V6.93341C5.3335 5.43994 5.3335 4.6932 5.62415 4.12277C5.87981 3.62101 6.28776 3.21306 6.78952 2.9574C7.35995 2.66675 8.10669 2.66675 9.60016 2.66675H22.4002C23.8936 2.66675 24.6404 2.66675 25.2108 2.9574C25.7126 3.21306 26.1205 3.62101 26.3762 4.12277C26.6668 4.6932 26.6668 5.43994 26.6668 6.93341V7.83675C26.6668 8.39146 26.6668 8.66882 26.6124 8.93411C26.5641 9.16948 26.4842 9.39724 26.3749 9.6112C26.2517 9.85238 26.0784 10.069 25.7319 10.5021L20.1652 17.4605M6.6669 4.00007L16.0002 16.0001L25.3334 4.00007M20.7142 17.9527C23.3177 20.5562 23.3177 24.7773 20.7142 27.3808C18.1107 29.9843 13.8896 29.9843 11.2861 27.3808C8.68262 24.7773 8.68262 20.5562 11.2861 17.9527C13.8896 15.3492 18.1107 15.3492 20.7142 17.9527Z"
						stroke="#98A2B3"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<Typography component={"p"} variant="Text/sm/Regular">
					SOS Score: {company?.currentScore}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path
						d="M13 27.6667L14.964 28.7578C15.3421 28.9679 15.5312 29.0729 15.7314 29.1141C15.9086 29.1505 16.0914 29.1505 16.2686 29.1141C16.4688 29.0729 16.6579 28.9679 17.036 28.7578L19 27.6667M7 24.3334L5.0973 23.2763C4.69794 23.0544 4.49824 22.9435 4.35284 22.7857C4.22421 22.6461 4.12686 22.4807 4.06731 22.3005C4 22.0967 4 21.8683 4 21.4114V19.3334M4 12.6667V10.5886C4 10.1318 4 9.90334 4.06731 9.69961C4.12686 9.51938 4.22421 9.35393 4.35284 9.21435C4.49825 9.05656 4.69793 8.94563 5.09729 8.72376L7 7.6667M13 4.33336L14.964 3.24227C15.3421 3.0322 15.5312 2.92716 15.7314 2.88598C15.9086 2.84953 16.0914 2.84953 16.2686 2.88598C16.4688 2.92716 16.6579 3.0322 17.036 3.24227L19 4.33336M25 7.6667L26.9027 8.72376C27.3021 8.94563 27.5018 9.05656 27.6472 9.21434C27.7758 9.35393 27.8731 9.51937 27.9327 9.69961C28 9.90334 28 10.1318 28 10.5886V12.6667M28 19.3334V21.4114C28 21.8683 28 22.0967 27.9327 22.3005C27.8731 22.4807 27.7758 22.6461 27.6472 22.7857C27.5018 22.9435 27.3021 23.0544 26.9027 23.2763L25 24.3334M13 14.3334L16 16M16 16L19 14.3334M16 16V19.3334M4 9.33336L7 11M25 11L28 9.33336M16 26V29.3334"
						stroke="#98A2B3"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<Typography component={"p"} variant="Text/sm/Regular">
					Product Offering: {company?.productOffering}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path
						d="M9.99984 14.6667H6.13317C5.38643 14.6667 5.01307 14.6667 4.72785 14.812C4.47697 14.9398 4.27299 15.1438 4.14516 15.3947C3.99984 15.6799 3.99984 16.0533 3.99984 16.8V28M21.9998 14.6667H25.8665C26.6132 14.6667 26.9866 14.6667 27.2718 14.812C27.5227 14.9398 27.7267 15.1438 27.8545 15.3947C27.9998 15.6799 27.9998 16.0533 27.9998 16.8V28M21.9998 28V8.26667C21.9998 6.77319 21.9998 6.02646 21.7092 5.45603C21.4535 4.95426 21.0456 4.54631 20.5438 4.29065C19.9734 4 19.2266 4 17.7332 4H14.2665C12.773 4 12.0263 4 11.4559 4.29065C10.9541 4.54631 10.5461 4.95426 10.2905 5.45603C9.99984 6.02646 9.99984 6.77319 9.99984 8.26667V28M29.3332 28H2.6665M14.6665 9.33333H17.3332M14.6665 14.6667H17.3332M14.6665 20H17.3332"
						stroke="#98A2B3"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<Typography component={"p"} variant="Text/sm/Regular">
					Industry: {getIndustry(company?.industry)}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path
						d="M4 28H28M8 24V13.3333M13.3333 24V13.3333M18.6667 24V13.3333M24 24V13.3333M26.6667 9.3333L16.5653 3.01997C16.3602 2.89179 16.2577 2.8277 16.1477 2.80271C16.0505 2.78061 15.9495 2.78061 15.8523 2.80271C15.7423 2.8277 15.6398 2.89179 15.4347 3.01997L5.33333 9.3333H26.6667Z"
						stroke="#98A2B3"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<Typography component={"p"} variant="Text/sm/Regular">
					Business Model: {company?.businessModel}
				</Typography>
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}>
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path
						d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M4 9.33333C4 11.5425 5.79086 13.3333 8 13.3333C10.2091 13.3333 12 11.5425 12 9.33333C12 11.5425 13.7909 13.3333 16 13.3333C18.2091 13.3333 20 11.5425 20 9.33333C20 11.5425 21.7909 13.3333 24 13.3333C26.2091 13.3333 28 11.5425 28 9.33333M8.26667 28H23.7333C25.2268 28 25.9735 28 26.544 27.7094C27.0457 27.4537 27.4537 27.0457 27.7094 26.544C28 25.9735 28 25.2268 28 23.7333V8.26667C28 6.77319 28 6.02646 27.7094 5.45603C27.4537 4.95426 27.0457 4.54631 26.544 4.29065C25.9735 4 25.2268 4 23.7333 4H8.26667C6.77319 4 6.02646 4 5.45603 4.29065C4.95426 4.54631 4.54631 4.95426 4.29065 5.45603C4 6.02646 4 6.77319 4 8.26667V23.7333C4 25.2268 4 25.9735 4.29065 26.544C4.54631 27.0457 4.95426 27.4537 5.45603 27.7094C6.02646 28 6.77319 28 8.26667 28Z"
						stroke="#98A2B3"
						stroke-width="1.5"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
				<Typography component={"p"} variant="Text/sm/Regular">
					Is Incorporated: {company?.incorporatedStatus}
				</Typography>
			</Box>
		</Box>
	);
};

const VoteItems = ({ sections, companyId, initialVotes, eventScorecardCode, scorecardUuid, onVoteChange }) => {
	const [scores, setScores] = useState({});
	const [submittedScores, setSubmittedScores] = useState({}); // Tracks which scores were submitted
	const [hasChanges, setHasChanges] = useState(false); // Tracks if any changes exist
	const [isLoading, setisLoading] = useState(false);

	useEffect(() => {
		if (!initialVotes[companyId]) {
			setScores({});
			setSubmittedScores({});
			setHasChanges(false);
			return;
		}

		// Extract the votes for the selected company
		const existingVotes = initialVotes[companyId] || {};

		// Populate default scores from voteCriterias and previously voted scores
		const defaultScores = sections.reduce((acc, section) => {
			acc[section.uuid] = existingVotes[section.uuid] ?? section.minScore; // Load previous votes if available
			return acc;
		}, {});

		setScores(defaultScores);
		setSubmittedScores(existingVotes); // Mark existing votes as submitted
		setHasChanges(false);
	}, [companyId, sections, initialVotes]);

	// Handle Slider Change
	const handleSliderChange = (criteriaUuid, newValue) => {
		setScores((prev) => ({
			...prev,
			[criteriaUuid]: newValue,
		}));

		setHasChanges(true); // ✅ Track unsubmitted changes
		onVoteChange(companyId, true, false); // ✅ Notify parent that there are changes (no actual vote data!)
	};

	// Submit Votes
	const submitVotes = async () => {
		if (!companyId) return;

		setisLoading(true);

		const payload = {
			eventScorecardCode,
			scorecardUuid,
			targetCompanyId: companyId,
			scores: sections.map((section) => ({
				criteriaUuid: section.uuid,
				score: scores[section.uuid] ?? section.minScore, // ✅ Ensure all values (including untouched ones) are submitted
				comment: "",
			})),
		};

		try {
			await services.submitVoteToScorecard(payload);

			// ✅ Mark all scores as submitted
			setSubmittedScores(scores);
			// ✅ Clear unsubmitted state
			setHasChanges(false);
			onVoteChange(companyId, false, scores); // ✅ Tell parent "no more unsubmitted changes"
		} catch (error) {
			console.error("Error submitting votes:", error);
		}
		setisLoading(false);
	};

	return (
		<div>
			{sections.map((section) => (
				<Grid key={section.uuid} container sx={{ borderBottom: "1px solid var(--gray-200, #EAECF0)", padding: "16px 0" }}>
					{/* Left Column - Section Title & Vote Factors */}
					<Grid item xs={12} lg={4} sx={{ px: "24px", py: "16px" }}>
						<Typography component="p" variant="Text/sm/Semibold" sx={{ fontWeight: "600" }}>
							{section.name}
						</Typography>
						<Typography component="p" variant="Text/xs/Regular">
							<ul style={{ paddingInlineStart: "16px", marginBlockStart: "10px" }}>
								{section.voteFactors.map((factor) => (
									<li key={factor.uuid}>
										{factor.name} ({factor.scoreRange})
									</li>
								))}
							</ul>
						</Typography>
					</Grid>

					{/* Middle Column - Slider */}
					<Grid item xs={10} lg={7} sx={{ display: "flex", alignItems: "center", px: "24px" }}>
						<Slider
							step={1}
							min={section?.minScore}
							max={section?.maxScore}
							value={scores[section.uuid] ?? section.minScore}
							onChange={(e, newValue) => handleSliderChange(section.uuid, newValue)}
							marks
							disabled={submittedScores.hasOwnProperty(section.uuid)} // ✅ Disables AFTER submission
						/>
					</Grid>

					{/* Right Column - Display Score */}
					<Grid item xs={2} lg={1} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
						<Typography component="p" variant="Text/2xl/Regular" color="primary">
							{scores[section.uuid] || section.minScore}
						</Typography>
					</Grid>
				</Grid>
			))}
			{/* Submit Button (Hidden if already voted) */}
			{!initialVotes[companyId] && (
				<Box sx={{ p: "16px", display: "flex", flex: 1, justifyContent: "flex-end" }}>
					<Button
						disabled={isLoading}
						variant="DS1"
						color="primary"
						onClick={submitVotes}
						sx={{ mt: 3 }}
						startIcon={isLoading && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
					>
						Submit Votes
					</Button>
				</Box>
			)}
		</div>
	);
};

const AdminVotesTable = ({ votes, selectedCompanyId }) => {
	const [filteredVotes, setFilteredVotes] = useState([]);
	const [criteriaNames, setCriteriaNames] = useState([]);
	const [columnTotals, setColumnTotals] = useState({});
	const [columnAverages, setColumnAverages] = useState({});
	const [totalAverage, setTotalAverage] = useState(0);
	const [criteriaComments, setCriteriaComments] = useState({});

	useEffect(() => {
		if (votes && Array.isArray(votes) && selectedCompanyId) {
			processVotes();
		}
	}, [votes, selectedCompanyId]);

	const processVotes = () => {
		// **Filter votes for the selected company only**
		const votesForCompany = votes.filter((vote) => vote.targetCompanyId === selectedCompanyId);

		// **Update state with filtered votes**
		setFilteredVotes(votesForCompany);

		// **Handle case where no votes exist for selected company**
		if (votesForCompany.length === 0) {
			setCriteriaNames([]);
			setColumnTotals({});
			setColumnAverages({});
			setTotalAverage(0);
			setCriteriaComments({});
			return;
		}

		// **Extract unique criteria names**
		const uniqueCriteriaNames = _.uniq(_.flatMap(votesForCompany, (vote) => vote.voteScores.map((score) => score.criteriaName)));
		setCriteriaNames(uniqueCriteriaNames);

		// **Compute column-wise totals**
		const totals = uniqueCriteriaNames.reduce((acc, criteria) => {
			acc[criteria] = votesForCompany.reduce((sum, vote) => {
				const scoreEntry = vote.voteScores.find((score) => score.criteriaName === criteria);
				return sum + (scoreEntry ? scoreEntry.score : 0);
			}, 0);
			return acc;
		}, {});
		setColumnTotals(totals);

		// **Compute column-wise averages**
		const averages = uniqueCriteriaNames.reduce((acc, criteria) => {
			const total = _.sumBy(votesForCompany, (vote) => {
				const scoreEntry = vote.voteScores.find((score) => score.criteriaName === criteria);
				return scoreEntry ? scoreEntry.score : 0;
			});
			acc[criteria] = total / votesForCompany.length;
			return acc;
		}, {});
		setColumnAverages(averages);

		// **Calculate total of all averages**
		setTotalAverage(_.sum(Object.values(averages)));

		const commentsByCriteria = votesForCompany.reduce((acc, vote) => {
			vote.voteScores.forEach((score) => {
				if (score.comment) {
					if (!acc[score.criteriaName]) {
						acc[score.criteriaName] = [];
					}
					acc[score.criteriaName].push({
						judge: vote.judgeLabel,
						comment: score.comment,
					});
				}
			});
			return acc;
		}, {});
		setCriteriaComments(commentsByCriteria);
	};

	return !votes || !Array.isArray(votes) || votes.length === 0 || filteredVotes.length === 0 ? (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				alignItems: "center",
				gap: "16px",
				alignSelf: "stretch",
				flex: 1,
				justifyContent: "center",
				"& > svg": {
					stroke: (theme) => theme?.palette?.primary?.main,
				},
				height: "250px",
			}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
				<path
					d="M27.2667 28.3332C27.64 28.3332 27.8267 28.3332 27.9693 28.2605C28.0948 28.1966 28.1967 28.0946 28.2607 27.9692C28.3333 27.8266 28.3333 27.6399 28.3333 27.2665V13.3998C28.3333 13.0265 28.3333 12.8398 28.2607 12.6972C28.1967 12.5717 28.0948 12.4698 27.9693 12.4058C27.8267 12.3332 27.64 12.3332 27.2667 12.3332L24.0667 12.3332C23.6933 12.3332 23.5066 12.3332 23.364 12.4058C23.2386 12.4697 23.1366 12.5717 23.0727 12.6972C23 12.8398 23 13.0265 23 13.3998V16.5998C23 16.9732 23 17.1599 22.9273 17.3025C22.8634 17.4279 22.7614 17.5299 22.636 17.5938C22.4934 17.6665 22.3067 17.6665 21.9333 17.6665H18.7333C18.36 17.6665 18.1733 17.6665 18.0307 17.7392C17.9052 17.8031 17.8032 17.9051 17.7393 18.0305C17.6667 18.1731 17.6667 18.3598 17.6667 18.7332V21.9332C17.6667 22.3065 17.6667 22.4932 17.594 22.6358C17.5301 22.7613 17.4281 22.8633 17.3026 22.9272C17.16 22.9998 16.9734 22.9998 16.6 22.9998H13.4C13.0266 22.9998 12.8399 22.9998 12.6973 23.0725C12.5719 23.1364 12.4699 23.2384 12.406 23.3638C12.3333 23.5064 12.3333 23.6931 12.3333 24.0665V27.2665C12.3333 27.6399 12.3333 27.8266 12.406 27.9692C12.4699 28.0946 12.5719 28.1966 12.6973 28.2605C12.8399 28.3332 13.0266 28.3332 13.4 28.3332L27.2667 28.3332Z"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M12.3333 8.0665C12.3333 7.69314 12.3333 7.50645 12.406 7.36384C12.4699 7.2384 12.5719 7.13641 12.6973 7.0725C12.8399 6.99984 13.0266 6.99984 13.4 6.99984H16.6C16.9734 6.99984 17.16 6.99984 17.3026 7.0725C17.4281 7.13641 17.5301 7.2384 17.594 7.36384C17.6667 7.50645 17.6667 7.69314 17.6667 8.0665V11.2665C17.6667 11.6399 17.6667 11.8266 17.594 11.9692C17.5301 12.0946 17.4281 12.1966 17.3026 12.2605C17.16 12.3332 16.9734 12.3332 16.6 12.3332H13.4C13.0266 12.3332 12.8399 12.3332 12.6973 12.2605C12.5719 12.1966 12.4699 12.0946 12.406 11.9692C12.3333 11.8266 12.3333 11.6399 12.3333 11.2665V8.0665Z"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M2.99999 16.0665C2.99999 15.6931 2.99999 15.5065 3.07265 15.3638C3.13657 15.2384 3.23855 15.1364 3.364 15.0725C3.5066 14.9998 3.69329 14.9998 4.06666 14.9998H7.26666C7.64002 14.9998 7.82671 14.9998 7.96932 15.0725C8.09476 15.1364 8.19674 15.2384 8.26066 15.3638C8.33332 15.5065 8.33332 15.6931 8.33332 16.0665V19.2665C8.33332 19.6399 8.33332 19.8266 8.26066 19.9692C8.19674 20.0946 8.09476 20.1966 7.96932 20.2605C7.82671 20.3332 7.64002 20.3332 7.26666 20.3332H4.06666C3.69329 20.3332 3.5066 20.3332 3.364 20.2605C3.23855 20.1966 3.13657 20.0946 3.07265 19.9692C2.99999 19.8266 2.99999 19.6399 2.99999 19.2665V16.0665Z"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M1.66666 2.73317C1.66666 2.3598 1.66666 2.17312 1.73932 2.03051C1.80323 1.90507 1.90522 1.80308 2.03066 1.73917C2.17327 1.6665 2.35995 1.6665 2.73332 1.6665H5.93332C6.30669 1.6665 6.49338 1.6665 6.63598 1.73917C6.76142 1.80308 6.86341 1.90507 6.92733 2.03051C6.99999 2.17312 6.99999 2.3598 6.99999 2.73317V5.93317C6.99999 6.30654 6.99999 6.49322 6.92733 6.63583C6.86341 6.76127 6.76142 6.86326 6.63598 6.92717C6.49338 6.99984 6.30669 6.99984 5.93332 6.99984H2.73332C2.35995 6.99984 2.17327 6.99984 2.03066 6.92717C1.90522 6.86326 1.80323 6.76127 1.73932 6.63583C1.66666 6.49322 1.66666 6.30654 1.66666 5.93317V2.73317Z"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
			<Typography variant="Text/sm/Semibold">
				{(!votes || !Array.isArray(votes) || votes.length === 0) && filteredVotes.length === 0
					? "Votes Not Yet Available"
					: "No votes available for this company"}
			</Typography>
		</Box>
	) : (
		<Box>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "flex-start",
					my: 2,
					background: "#f5f5f5",
					borderRadius: "8px",
					flexWrap: "wrap",
				}}
			>
				{criteriaNames.map((criteria) => (
					<Box key={criteria} sx={{ display: "flex", flexDirection: "column", textAlign: "center", flex: 1, p: 2, alignSelf: "stretch" }}>
						<Typography variant="Text/xs/Regular" sx={{ mt: "auto" }}>
							{criteria}
						</Typography>
						<Typography variant="Text/md/Semibold" sx={{ mt: 1 }}>
							{columnAverages[criteria].toFixed(1)}
						</Typography>
					</Box>
				))}
				{/* Total Average Box */}

				<Box sx={{ display: "flex", flexDirection: "column", textAlign: "center", flex: 1, p: 2, alignSelf: "stretch" }}>
					<Typography variant="Text/xs/Regular" sx={{ mt: "auto" }}>
						Total
					</Typography>
					<Typography variant="Text/md/Semibold" sx={{ mt: 1 }}>
						{totalAverage.toFixed(1)}
					</Typography>
				</Box>
			</Box>
			<TableContainer sx={{ mt: 4, borderRadius: "8px" }}>
				<Table sx={{ minWidth: 650 }} aria-label="admin votes table">
					<TableHead>
						<TableRow sx={{ backgroundColor: "#d2d2d2" }}>
							<TableCell>
								<Typography variant="Text/xs/Semibold">Judge</Typography>
							</TableCell>
							{criteriaNames.map((criteria) => (
								<TableCell key={criteria} align="center">
									<Typography variant="Text/xs/Semibold">{criteria}</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{filteredVotes.map((vote) =>
							vote?.judgeLabel == "BoltAI" ? (
								<TableRow key={vote.uuid} sx={{ backgroundColor: "#efebff" }}>
									<TableCell component="th" scope="row">
										<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
												<path
													d="M5.99984 29.3334V22.6667M5.99984 9.33341V2.66675M2.6665 6.00008H9.33317M2.6665 26.0001H9.33317M17.3332 4.00008L15.0209 10.0119C14.6449 10.9895 14.4569 11.4784 14.1645 11.8895C13.9054 12.2539 13.587 12.5723 13.2226 12.8315C12.8114 13.1238 12.3226 13.3118 11.345 13.6878L5.33317 16.0001L11.345 18.3123C12.3226 18.6883 12.8114 18.8763 13.2226 19.1687C13.587 19.4278 13.9054 19.7462 14.1645 20.1106C14.4569 20.5218 14.6449 21.0106 15.0209 21.9883L17.3332 28.0001L19.6454 21.9883C20.0214 21.0106 20.2094 20.5218 20.5018 20.1106C20.7609 19.7462 21.0793 19.4278 21.4437 19.1687C21.8549 18.8763 22.3437 18.6883 23.3214 18.3123L29.3332 16.0001L23.3214 13.6878C22.3437 13.3118 21.8549 13.1238 21.4437 12.8315C21.0793 12.5723 20.7609 12.2539 20.5018 11.8895C20.2094 11.4784 20.0214 10.9895 19.6454 10.0119L17.3332 4.00008Z"
													stroke="#7B61FF"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
											<Typography variant="Text/xs/Semibold" sx={{ color: "#5644B2" }}>
												Bolt AI
											</Typography>
											<InfoHelper
												title={"Bolt AI Scoring"}
												body={
													"Bolt AI has reviewed this startup's pitch deck and voted using the same criteria as the other judges on this scorecard."
												}
											/>
										</Box>
									</TableCell>
									{criteriaNames.map((criteria) => {
										const scoreEntry = vote.voteScores.find((score) => score.criteriaName === criteria);
										return (
											<TableCell key={criteria} align="center">
												<Typography variant="Text/xs/Semibold" sx={{ color: "#5644B2" }}>
													{scoreEntry ? scoreEntry.score : "-"}
												</Typography>
											</TableCell>
										);
									})}
								</TableRow>
							) : (
								<TableRow key={vote.uuid}>
									<TableCell component="th" scope="row">
										<Typography variant="Text/xs/Regular">{vote.judgeLabel}</Typography>
									</TableCell>
									{criteriaNames.map((criteria) => {
										const scoreEntry = vote.voteScores.find((score) => score.criteriaName === criteria);
										return (
											<TableCell key={criteria} align="center">
												<Typography variant="Text/xs/Regular">{scoreEntry ? scoreEntry.score : "-"}</Typography>
											</TableCell>
										);
									})}
								</TableRow>
							)
						)}
						{/* Total Row */}
						<TableRow sx={{ backgroundColor: "#f5f5f5" }}>
							<TableCell>
								<Typography variant="Text/xs/Semibold">Total</Typography>
							</TableCell>
							{criteriaNames.map((criteria) => (
								<TableCell key={criteria} align="center">
									<Typography variant="Text/xs/Semibold">{columnTotals[criteria]}</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
			{Object.keys(criteriaComments).length > 0 && (
				<Box sx={{ mt: 4 }}>
					<Typography variant="Text/md/Semibold">Comments</Typography>
					{Object.values(criteriaComments).some((comments) => comments.some((comment) => comment?.judge === "BoltAI")) && (
						<Box sx={{ display: "flex", alignItems: "center", gap: 1, my: 2 }}>
							<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
								<path
									d="M5.99984 29.3334V22.6667M5.99984 9.33341V2.66675M2.6665 6.00008H9.33317M2.6665 26.0001H9.33317M17.3332 4.00008L15.0209 10.0119C14.6449 10.9895 14.4569 11.4784 14.1645 11.8895C13.9054 12.2539 13.587 12.5723 13.2226 12.8315C12.8114 13.1238 12.3226 13.3118 11.345 13.6878L5.33317 16.0001L11.345 18.3123C12.3226 18.6883 12.8114 18.8763 13.2226 19.1687C13.587 19.4278 13.9054 19.7462 14.1645 20.1106C14.4569 20.5218 14.6449 21.0106 15.0209 21.9883L17.3332 28.0001L19.6454 21.9883C20.0214 21.0106 20.2094 20.5218 20.5018 20.1106C20.7609 19.7462 21.0793 19.4278 21.4437 19.1687C21.8549 18.8763 22.3437 18.6883 23.3214 18.3123L29.3332 16.0001L23.3214 13.6878C22.3437 13.3118 21.8549 13.1238 21.4437 12.8315C21.0793 12.5723 20.7609 12.2539 20.5018 11.8895C20.2094 11.4784 20.0214 10.9895 19.6454 10.0119L17.3332 4.00008Z"
									stroke="#7B61FF"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Typography variant="Text/sm/Regular">
								Bolt AI has added comments on the sections it evaluated to justify the scores it assigned.
							</Typography>
						</Box>
					)}
					{Object.entries(criteriaComments).map(([criteria, comments]) => (
						<Box key={criteria} sx={{ mt: 2, p: 2, border: "1px solid #ccc", borderRadius: "8px" }}>
							<Typography variant="Text/md/Semibold">{criteria}</Typography>
							{comments.map((comment, index) =>
								comment?.judge == "BoltAI" ? (
									<Alert
										key={index}
										severity="info"
										sx={{ mt: 1, backgroundColor: "#efebff" }}
										icon={
											<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none">
												<path
													d="M5.99984 29.3334V22.6667M5.99984 9.33341V2.66675M2.6665 6.00008H9.33317M2.6665 26.0001H9.33317M17.3332 4.00008L15.0209 10.0119C14.6449 10.9895 14.4569 11.4784 14.1645 11.8895C13.9054 12.2539 13.587 12.5723 13.2226 12.8315C12.8114 13.1238 12.3226 13.3118 11.345 13.6878L5.33317 16.0001L11.345 18.3123C12.3226 18.6883 12.8114 18.8763 13.2226 19.1687C13.587 19.4278 13.9054 19.7462 14.1645 20.1106C14.4569 20.5218 14.6449 21.0106 15.0209 21.9883L17.3332 28.0001L19.6454 21.9883C20.0214 21.0106 20.2094 20.5218 20.5018 20.1106C20.7609 19.7462 21.0793 19.4278 21.4437 19.1687C21.8549 18.8763 22.3437 18.6883 23.3214 18.3123L29.3332 16.0001L23.3214 13.6878C22.3437 13.3118 21.8549 13.1238 21.4437 12.8315C21.0793 12.5723 20.7609 12.2539 20.5018 11.8895C20.2094 11.4784 20.0214 10.9895 19.6454 10.0119L17.3332 4.00008Z"
													stroke="#7B61FF"
													stroke-width="2"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
										}
									>
										<AlertTitle>
											<Typography variant="Text/xs/Semibold">Bolt AI</Typography>
										</AlertTitle>
										<Typography variant="Text/xs/Regular">{comment.comment}</Typography>
									</Alert>
								) : (
									<Alert key={index} severity="info" sx={{ mt: 1 }} icon={<ChatBubbleOutlineRounded fontSize="inherit" />}>
										<AlertTitle>
											<Typography variant="Text/xs/Semibold">{comment.judge}</Typography>
										</AlertTitle>
										<Typography variant="Text/xs/Regular">{comment.comment}</Typography>
									</Alert>
								)
							)}
						</Box>
					))}
				</Box>
			)}
		</Box>
	);
};

const VotingScorecardPage = () => {
	const [scorecardObj, setscorecardObj] = useState({});
	const [selectedStartup, setselectedStartup] = useState({});
	const [votes, setVotes] = useState({});
	const [hasUnsubmittedVotes, setHasUnsubmittedVotes] = useState(false);
	const [pendingCompanyChange, setPendingCompanyChange] = useState(null);
	const [showConfirmModal, setShowConfirmModal] = useState(false);

	const [isLoading, setisLoading] = useState(false);

	const { scorecardId, eventScorecardCode } = useParams();

	useEffect(() => {
		getScorecardDetails();
	}, []);

	const getScorecardDetails = async () => {
		setisLoading(true);
		if (scorecardId) {
			console.log("ADMIN SCORECARD ID:", scorecardId);
			await services
				.votingScorecardDetailsAdmin(scorecardId)
				.then((resp) => {
					if (resp?.data?.data) {
						setscorecardObj(resp?.data?.data);
						if (resp?.data?.data?.companies && Array.isArray(resp?.data?.data?.companies)) setselectedStartup(resp?.data?.data?.companies[0]); // Set the initial selected company to the 1st one by default

						if (resp?.data?.data?.votes && Array.isArray(resp?.data?.data?.votes)) {
							//const parsedVotes = {};
							//resp?.data?.data?.votes?.forEach((vote) => {
							//	const companyId = vote.targetCompanyId;
							//	parsedVotes[companyId] = {};
							//	vote.voteScores.forEach((voteScore) => {
							//		parsedVotes[companyId][voteScore.criteriaUuid] = voteScore.score;
							//	});
							//});
							//console.log(parsedVotes);
							//setVotes(parsedVotes);
							setVotes(resp?.data?.data?.votes);
						}
					}
				})
				.catch((e) => {
					console.log("ERROR grabbing scorecard details. Failed with error:", e);
				});
		} else if (eventScorecardCode) {
			console.log("PUBLIC SCORECARD CODE:", eventScorecardCode);
			await services
				.votingScorecardDetailsPublic(eventScorecardCode)
				.then((resp) => {
					if (resp?.data?.data) {
						setscorecardObj(resp?.data?.data);
						if (resp?.data?.data?.companies && Array.isArray(resp?.data?.data?.companies)) setselectedStartup(resp?.data?.data?.companies[0]); // Set the initial selected company to the 1st one by default
						if (resp?.data?.data?.votes && Array.isArray(resp?.data?.data?.votes)) {
							const parsedVotes = {};
							resp?.data?.data?.votes
								?.filter((vote) => vote.isCurrentJudge)
								?.forEach((vote) => {
									const companyId = vote.targetCompanyId;
									parsedVotes[companyId] = {};
									vote.voteScores.forEach((voteScore) => {
										parsedVotes[companyId][voteScore.criteriaUuid] = voteScore.score;
									});
								});
							setVotes(parsedVotes);
						}
					}
				})
				.catch((e) => {
					console.log("ERROR grabbing scorecard details. Failed with error:", e);
				});
		}
		setisLoading(false);
	};

	const handleVoteChange = (companyId, hasUnsubmittedChanges, submittedScores) => {
		setHasUnsubmittedVotes(hasUnsubmittedChanges);
		if (submittedScores)
			setVotes((prevVotes) => ({
				...prevVotes,
				[companyId]: submittedScores, // Persist only submitted votes
			}));
	};

	// Handle Company Change with Confirmation
	const handleCompanyChange = (company) => {
		if (hasUnsubmittedVotes) {
			setPendingCompanyChange(company);
			setShowConfirmModal(true);
		} else {
			setselectedStartup(company);
			setHasUnsubmittedVotes(false); // ✅ Reset unsubmitted state on switch
		}
	};

	// Confirm Company Change
	const confirmCompanyChange = () => {
		setselectedStartup(pendingCompanyChange);
		setPendingCompanyChange(null);
		setShowConfirmModal(false);

		setHasUnsubmittedVotes(false); // ✅ Reset because a new company is selected
	};

	return (
		<>
			<Box
				sx={{
					px: {
						lg: 8,
						md: 4,
						xs: 2,
					},
					pb: "30px",
					display: "flex",
					flexDirection: "column",
					gap: "32px",
					maxWidth: "1920px",
					margin: "0 auto",
				}}
			>
				<Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between", alignItems: "center", rowGap: 2, columnGap: 1 }}>
					<PageTitle
						iconComp={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M23.6605 21.3332C24.7149 19.8215 25.3334 17.9829 25.3334 16C25.3334 15.3134 25.2592 14.6442 25.1186 13.9999M8.33961 21.3333C7.28511 19.8215 6.66669 17.983 6.66669 16C6.66669 10.8453 10.8454 6.66663 16 6.66663C16.5629 6.66663 17.1141 6.71645 17.6495 6.81192M21.9999 9.99996L15.9999 16M29.3334 16C29.3334 23.3638 23.3638 29.3333 16 29.3333C8.63622 29.3333 2.66669 23.3638 2.66669 16C2.66669 8.63616 8.63622 2.66663 16 2.66663C23.3638 2.66663 29.3334 8.63616 29.3334 16ZM17.3334 16C17.3334 16.7363 16.7364 17.3333 16 17.3333C15.2636 17.3333 14.6667 16.7363 14.6667 16C14.6667 15.2636 15.2636 14.6666 16 14.6666C16.7364 14.6666 17.3334 15.2636 17.3334 16Z"
									stroke="black"
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						label={"Voting Scorecard"}
						showTour={false}
					/>
				</Box>
				<Grid container gap={4}>
					<Grid item xs={12}>
						<Box display={"flex"} alignItems={"center"} gap={2}>
							<Typography variant="Text/lg/Semibold">{scorecardObj?.name}</Typography>
							<Divider sx={{ flexGrow: 1 }} />
						</Box>
					</Grid>

					{scorecardId && !eventScorecardCode && (
						<Grid item xs={12}>
							<Box sx={{ borderRadius: "24px", backgroundColor: (theme) => theme?.palette?.secondary?.white, p: 3, pb: 4 }}>
								<Box sx={{ mb: 3 }}>
									<Typography variant="Text/lg/Semibold">Aggregated Startup Scores</Typography>
								</Box>

								<Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
									<Box>
										<Box>
											<Typography variant="Text/xs/Regular">{scorecardObj?.name}</Typography>
										</Box>
										<Box sx={{ mb: 2 }}>
											<Typography variant="Text/sm/Semibold">Avg. Total Score by Startup</Typography>
										</Box>
									</Box>

									<Box>
										<Box>
											<Typography component={"p"} variant="Text/xs/Regular" textAlign={"right"}>
												Total Judges Voted:
											</Typography>
										</Box>
										<Box sx={{ mb: 2 }}>
											<Typography component={"p"} variant="Text/sm/Semibold" textAlign={"right"}>
												{scorecardObj?.totalJudgesVoted || 0}
											</Typography>
										</Box>
									</Box>
								</Box>

								<Box sx={{ mb: 3 }}>
									<Divider />
								</Box>

								<Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
									{scorecardObj && scorecardObj?.aggregatedCompanyScore && Array.isArray(scorecardObj?.aggregatedCompanyScore) ? (
										scorecardObj?.aggregatedCompanyScore?.map((company) => (
											<ScoreBar startupname={company?.companyName} percent={company?.percentageScore} />
										))
									) : (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												gap: "16px",
												alignSelf: "stretch",
												flex: 1,
												justifyContent: "center",
												"& > svg": {
													stroke: (theme) => theme?.palette?.primary?.main,
												},
											}}
										>
											<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
												<path
													d="M27.2667 28.3332C27.64 28.3332 27.8267 28.3332 27.9693 28.2605C28.0948 28.1966 28.1967 28.0946 28.2607 27.9692C28.3333 27.8266 28.3333 27.6399 28.3333 27.2665V13.3998C28.3333 13.0265 28.3333 12.8398 28.2607 12.6972C28.1967 12.5717 28.0948 12.4698 27.9693 12.4058C27.8267 12.3332 27.64 12.3332 27.2667 12.3332L24.0667 12.3332C23.6933 12.3332 23.5066 12.3332 23.364 12.4058C23.2386 12.4697 23.1366 12.5717 23.0727 12.6972C23 12.8398 23 13.0265 23 13.3998V16.5998C23 16.9732 23 17.1599 22.9273 17.3025C22.8634 17.4279 22.7614 17.5299 22.636 17.5938C22.4934 17.6665 22.3067 17.6665 21.9333 17.6665H18.7333C18.36 17.6665 18.1733 17.6665 18.0307 17.7392C17.9052 17.8031 17.8032 17.9051 17.7393 18.0305C17.6667 18.1731 17.6667 18.3598 17.6667 18.7332V21.9332C17.6667 22.3065 17.6667 22.4932 17.594 22.6358C17.5301 22.7613 17.4281 22.8633 17.3026 22.9272C17.16 22.9998 16.9734 22.9998 16.6 22.9998H13.4C13.0266 22.9998 12.8399 22.9998 12.6973 23.0725C12.5719 23.1364 12.4699 23.2384 12.406 23.3638C12.3333 23.5064 12.3333 23.6931 12.3333 24.0665V27.2665C12.3333 27.6399 12.3333 27.8266 12.406 27.9692C12.4699 28.0946 12.5719 28.1966 12.6973 28.2605C12.8399 28.3332 13.0266 28.3332 13.4 28.3332L27.2667 28.3332Z"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M12.3333 8.0665C12.3333 7.69314 12.3333 7.50645 12.406 7.36384C12.4699 7.2384 12.5719 7.13641 12.6973 7.0725C12.8399 6.99984 13.0266 6.99984 13.4 6.99984H16.6C16.9734 6.99984 17.16 6.99984 17.3026 7.0725C17.4281 7.13641 17.5301 7.2384 17.594 7.36384C17.6667 7.50645 17.6667 7.69314 17.6667 8.0665V11.2665C17.6667 11.6399 17.6667 11.8266 17.594 11.9692C17.5301 12.0946 17.4281 12.1966 17.3026 12.2605C17.16 12.3332 16.9734 12.3332 16.6 12.3332H13.4C13.0266 12.3332 12.8399 12.3332 12.6973 12.2605C12.5719 12.1966 12.4699 12.0946 12.406 11.9692C12.3333 11.8266 12.3333 11.6399 12.3333 11.2665V8.0665Z"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M2.99999 16.0665C2.99999 15.6931 2.99999 15.5065 3.07265 15.3638C3.13657 15.2384 3.23855 15.1364 3.364 15.0725C3.5066 14.9998 3.69329 14.9998 4.06666 14.9998H7.26666C7.64002 14.9998 7.82671 14.9998 7.96932 15.0725C8.09476 15.1364 8.19674 15.2384 8.26066 15.3638C8.33332 15.5065 8.33332 15.6931 8.33332 16.0665V19.2665C8.33332 19.6399 8.33332 19.8266 8.26066 19.9692C8.19674 20.0946 8.09476 20.1966 7.96932 20.2605C7.82671 20.3332 7.64002 20.3332 7.26666 20.3332H4.06666C3.69329 20.3332 3.5066 20.3332 3.364 20.2605C3.23855 20.1966 3.13657 20.0946 3.07265 19.9692C2.99999 19.8266 2.99999 19.6399 2.99999 19.2665V16.0665Z"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
												<path
													d="M1.66666 2.73317C1.66666 2.3598 1.66666 2.17312 1.73932 2.03051C1.80323 1.90507 1.90522 1.80308 2.03066 1.73917C2.17327 1.6665 2.35995 1.6665 2.73332 1.6665H5.93332C6.30669 1.6665 6.49338 1.6665 6.63598 1.73917C6.76142 1.80308 6.86341 1.90507 6.92733 2.03051C6.99999 2.17312 6.99999 2.3598 6.99999 2.73317V5.93317C6.99999 6.30654 6.99999 6.49322 6.92733 6.63583C6.86341 6.76127 6.76142 6.86326 6.63598 6.92717C6.49338 6.99984 6.30669 6.99984 5.93332 6.99984H2.73332C2.35995 6.99984 2.17327 6.99984 2.03066 6.92717C1.90522 6.86326 1.80323 6.76127 1.73932 6.63583C1.66666 6.49322 1.66666 6.30654 1.66666 5.93317V2.73317Z"
													stroke-width="1.5"
													stroke-linecap="round"
													stroke-linejoin="round"
												/>
											</svg>
											<Typography variant="Text/sm/Semibold">Scores Not Yet Available</Typography>
										</Box>
									)}
								</Box>
							</Box>
						</Grid>
					)}
					<Grid item xs={12}>
						<Box display={"flex"} alignItems={"center"} gap={2}>
							<Typography variant="Text/lg/Semibold">Selected Startups</Typography>
						</Box>
					</Grid>

					<Grid item xs={12}>
						{isLoading && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
									height: "200px",
								}}
							>
								<CircularProgress />
								<Typography variant="Text/sm/Semibold">Loading Startups</Typography>
							</Box>
						)}
						{!isLoading && scorecardObj && scorecardObj?.companies && Array.isArray(scorecardObj?.companies) && (
							<Box sx={{ position: "relative" }}>
								<IconButton
									sx={{ position: "absolute", left: 0, zIndex: 1, top: "50%", background: "#F2F4F7" }}
									className="custom-swiper-button-prev"
								>
									<ArrowBackRoundedIcon sx={{ color: "#344054" }} />
								</IconButton>
								<IconButton
									sx={{ position: "absolute", right: 0, zIndex: 1, top: "50%", background: "#F2F4F7" }}
									className="custom-swiper-button-next"
								>
									<ArrowForwardRoundedIcon sx={{ color: "#344054" }} />
								</IconButton>
								<Box px={6}>
									<Swiper
										breakpoints={BREAK_POINTS}
										allowTouchMove={false}
										spaceBetween={30}
										slidesPerView={4}
										grabCursor={false}
										initialSlide={0}
										rewind={false}
										navigation={{
											prevEl: ".custom-swiper-button-prev",
											nextEl: ".custom-swiper-button-next",
										}}
										loop={false}
										centeredSlides={false}
										fade={true}
										modules={[Pagination, Navigation]}
									>
										{scorecardObj?.companies?.map((company) => (
											<SwiperSlide key={company?.id}>
												<CompanyTile
													company={company}
													onClickHandler={() => handleCompanyChange(company)}
													isSelected={selectedStartup?.id == company?.id}
												/>
											</SwiperSlide>
										))}
									</Swiper>
								</Box>
							</Box>
						)}
					</Grid>
					{scorecardId && !eventScorecardCode && (
						<Grid item xs={12}>
							<Box sx={{ borderRadius: "24px", backgroundColor: (theme) => theme?.palette?.secondary?.white, p: 3, pb: 4 }}>
								<Typography variant="Text/lg/Semibold">Current Startup Scores</Typography>
								<AdminVotesTable votes={votes} selectedCompanyId={selectedStartup?.id} />
							</Box>
						</Grid>
					)}
					{!scorecardId && eventScorecardCode && (
						<Grid item xs={12}>
							<Box
								sx={{
									width: "100%",
									borderRadius: "12px",
									border: "1px solid var(--gray-200, #EAECF0)",
									background: "var(--base-white, #FFF)",
									boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
								}}
							>
								<Box
									sx={{
										display: "flex",
										padding: "20px 24px",
										alignItems: "flex-start",
										gap: "16px",
										alignSelf: "stretch",
										borderBottom: "1px solid var(--gray-200, #EAECF0)",
										flexWrap: "wrap",
									}}
								>
									<Box
										sx={{
											display: "flex",
											flexDirection: "column",
											gap: 1,
										}}
									>
										<Typography variant="Text/lg/Semibold">StartupOS Scorecard</Typography>
										<Typography variant="Text/xs/Regular">Enter your score for the selected startups below!</Typography>
									</Box>
								</Box>
								<Box
									sx={{
										pb: 4,
									}}
								>
									<Grid container sx={{ borderBottom: "1px solid var(--gray-200, #EAECF0)", background: "#F9FAFB" }}>
										<Grid item xs={12} sx={{ px: "24px", py: "16px" }}>
											<Typography component={"p"} variant="Text/sm/Semibold">
												Score Criteria
											</Typography>
										</Grid>
									</Grid>
									{scorecardObj?.voteCriterias && Array.isArray(scorecardObj?.voteCriterias) && (
										<VoteItems
											sections={scorecardObj?.voteCriterias}
											companyId={selectedStartup?.id}
											initialVotes={votes}
											eventScorecardCode={eventScorecardCode}
											scorecardUuid={scorecardObj?.uuid}
											onVoteChange={handleVoteChange}
										/>
									)}
								</Box>
							</Box>
						</Grid>
					)}
				</Grid>
			</Box>
			<MessageHandlerModal
				isOpen={showConfirmModal}
				onCancel={() => setShowConfirmModal(false)}
				onOk={() => {
					confirmCompanyChange();
				}}
				okText="Switch Anyway"
				cancelText={"Stay on this Company"}
				heading={`Unsaved Votes`}
				text={`You have unsaved votes for ${selectedStartup?.companyName}. If you switch companies, these votes will be lost.`}
				messageType="primary"
			/>
		</>
	);
};

export default VotingScorecardPage;
