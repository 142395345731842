import * as actionTypes from "./actionTypes";

export const showAccount406Modal = (payload) => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.SHOW_ACCOUNT_406_MODAL,
			payload,
		});
	};
};

export const dismissAccount406Modal = () => {
	return (dispatch) => {
		dispatch({
			type: actionTypes.DISMISS_ACCOUNT_406_MODAL,
		});
	};
};
