import { Box, Button, CircularProgress, Switch, Tab, Tabs, ToggleButton, ToggleButtonGroup, Typography, useTheme } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { debounce } from "lodash";
import { ARROW_UP_RIGHT_WHITE_ICON } from "../../../../constants";
import TableController from "../../../../components/Tables/TableController";
import { StartupTableColumns } from "../../../Partner/Dashboard/Admin/StartupTableColumns";
import SortComponent from "../../../../components/Tables/SortComponent";
import {
	AppliedStartupColumns,
	AppliedStartupSortOptions,
	ProspectsColumns,
	EventApplicationColumns,
	AcceptedStartupColumns,
	ScorecardColumns,
} from "./AppliedStartupColumns";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import MessageHandlerModal from "../../../../common/modal/MessageHandler";
import SearchComponent from "../../../../components/Tables/SearchComponent";
import { useDispatch } from "react-redux";
import { setSlidingProfileData } from "../../../../modules/actions/slidingProfileActions";
import { Can } from "../../../../services/abilities/context";
import axios from "axios";
import { formSubmissionsBaseUrl, getAssignedFormByEntityIdUrl } from "../../../../utils/urls";
import ViewFormFlyout from "../../../../components/Forms/Utilities/ViewFormFlyout";
import CreateScoreCardModal from "./CreateScoreCardModal";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import ROUTES_PATH from "../../../../constants/routes";

const AppliedStartups = ({ eventList, selectedSlide }) => {
	const [currentTab, setCurrentTab] = useState(0);
	const [sortCriteriaStartups, setSortCriteriaStartups] = useState({ colName: "", direction: "" });
	const [selectedRows, setSelectedRows] = useState([]);
	const [inviteLoader, setInviteLoader] = useState(false);
	const [inviteConfirmPopup, setInviteConfirmPopup] = useState(false);
	const [createScorecardPopup, setCreateScorecardPopup] = useState(false);
	const [tableKey, setTableKey] = useState(0);
	const [searchText, setSearchText] = useState("");
	const [showFormTable, setshowFormTable] = useState(false);
	const [eventForm, setEventForm] = useState(null);
	const [showOnlyAccepted, setshowOnlyAccepted] = useState("all");
	const [applicationsExist, setapplicationsExist] = useState(false);
	const [requestingApplicationsExport, setrequestingApplicationsExport] = useState(false);
	const [openScorecardEdit, setopenScorecardEdit] = useState(false);
	const [scoreCardObj, setscoreCardObj] = useState(null);
	const theme = useTheme();

	const [checkboxStates, setCheckboxStates] = useState({});

	const [APPLIED_STARTUPS, setAPPLIED_STARTUPS] = useState([
		{ id: 0, title: "Applied Startups", visible: true },
		{ id: 1, title: "Prospects", visible: true },
		{ id: 2, title: "Submitted Applications", visible: true },
		{ id: 3, title: "Accepted Startups", visible: true },
		{ id: 4, title: "Scorecards", visible: true },
	]);
	const [showFormSubmission, setshowFormSubmission] = useState({ data: null, open: false });

	const dispatch = useDispatch();
	const history = useHistory();

	const handleRowSelection = (selectedIds) => {
		setSelectedRows(selectedIds);
	};
	const handleChange = (event, newValue) => {
		setCurrentTab(newValue);
	};
	const handleSortChange = (newSortCriteria) => {
		setSortCriteriaStartups(newSortCriteria);
		setTableKey((prevKey) => prevKey + 1);
	};

	const handleSendEventInvite = async () => {
		try {
			const payload = {
				eventUuid: eventList[selectedSlide]?.uuid,
				companyIds: selectedRows,
			};
			setInviteLoader(true);
			const response = await services.sendEventInvitationApi(payload);
			if (response) {
				setInviteLoader(false);
				toastContainer(messages.EVENT_INVITATION_SENT);
				setInviteConfirmPopup(false);
			}
		} catch (error) {
			setInviteLoader(false);
			setInviteConfirmPopup(false);

			console.error("Error in sent event invite:", error);
		}
	};

	const getPitchCompanies = async (pageNo, perPage, searchText = "") => {
		try {
			const isApplied = currentTab === 0;
			const eventUuid = eventList[selectedSlide]?.uuid;
			const filterAccepted = showOnlyAccepted == "accepted" ? true : false;
			let page = pageNo + 1;
			const response = await services.getPitchCompaniesApi({
				page,
				perPage,
				isApplied,
				eventUuid,
				filter: sortCriteriaStartups?.colName,
				sort: sortCriteriaStartups?.direction,
				searchText,
				isAccepted: filterAccepted,
			});
			if (response) {
				const { totalElements, models } = response?.data?.data;

				if (!models) {
					setCheckboxStates({});
					return {
						data: {
							totalElements: totalElements,
							models: [],
						},
					};
				}

				const newModels = models.map((model) => ({
					...model,
					name: {
						name: model.companyName,
						img: model.logoUrl,
					},
					location: model?.city && model?.state ? `${model?.city}, ${model?.state}` : "",
					industry: model?.industry && JSON.parse(model?.industry),
				}));

				setCheckboxStates(
					newModels.reduce((acc, model) => {
						acc[model.id] = { checked: model?.isAccepted || false, disabled: false, loading: false }; // Initialize state
						return acc;
					}, {})
				);

				return {
					data: {
						totalElements: totalElements,
						models: newModels,
					},
				};
			}
		} catch (error) {
			console.error("Error fetching pitch companies:", error);
		}
	};

	const getAcceptedCompanies = async (pageNo, perPage, searchText = "") => {
		try {
			const eventUuid = eventList[selectedSlide]?.uuid;
			let page = pageNo + 1;
			const response = await services.getPitchCompaniesApi({
				page,
				perPage,
				isApplied: true,
				eventUuid,
				filter: sortCriteriaStartups?.colName,
				sort: sortCriteriaStartups?.direction,
				searchText,
				isAccepted: true,
			});
			if (response) {
				const { totalElements, models } = response?.data?.data;

				if (!models) {
					return {
						data: {
							totalElements: totalElements,
							models: [],
						},
					};
				}

				const newModels = models.map((model) => ({
					...model,
					name: {
						name: model.companyName,
						img: model.logoUrl,
					},
					location: model?.city && model?.state ? `${model?.city}, ${model?.state}` : "",
					industry: model?.industry && JSON.parse(model?.industry),
				}));

				return {
					data: {
						totalElements: totalElements,
						models: newModels,
					},
				};
			}
		} catch (error) {
			console.error("Error fetching accepted companies:", error);
		}
	};

	const getEventApplications = async (pageNo, perPage) => {
		try {
			let page = pageNo + 1;
			const response = await axios.get(formSubmissionsBaseUrl() + `/form/${eventForm?.id}`, { params: { page, perPage } });

			if (response) {
				const { totalElements, models } = response?.data?.data;

				if (totalElements && totalElements > 0) setapplicationsExist(true);
				else setapplicationsExist(false);

				if (!models) {
					return {
						data: {
							totalElements: totalElements,
							models: [],
						},
					};
				}

				return {
					data: {
						totalElements: totalElements,
						models: models,
					},
				};
			}
		} catch (error) {
			console.error("Error fetching event applications:", error);
		}
	};

	const getEventScorecards = async (pageNo, perPage) => {
		try {
			const eventUuid = eventList[selectedSlide]?.uuid;
			let page = pageNo + 1;
			const response = await services.votingEventScorecardList({
				eventUuid,
				page,
				perPage,
				//filter: sortCriteriaStartups?.colName,
				//sort: sortCriteriaStartups?.direction,
			});
			if (response) {
				const { totalElements, models } = response?.data?.data;

				if (!models) {
					return {
						data: {
							totalElements: totalElements,
							models: [],
						},
					};
				}

				const newModels = models.map((model) => ({
					...model,
					id: model?.uuid,
				}));

				return {
					data: {
						totalElements: totalElements,
						models: newModels,
					},
				};
			}
		} catch (error) {
			console.error("Error fetching event scorecards:", error);
		}
	};

	const searchPitchCompanies = debounce((e) => {
		setSearchText(e.target.value);
		setTableKey((prevKey) => prevKey + 1);
	}, 600);

	const openStartupProfile = (id) => {
		dispatch(
			setSlidingProfileData({
				open: true,
				profileType: "STARTUP_PROFILE",
				profileId: id,
			})
		);
	};

	useEffect(() => {
		setshowFormTable(false);
		setEventForm(null);
		setshowFormSubmission({ data: null, open: false });
		if (eventList[selectedSlide]?.id) getEventForm();
		setTableKey((prevKey) => prevKey + 1);
	}, [selectedSlide]);

	const getEventForm = async () => {
		await axios
			.get(getAssignedFormByEntityIdUrl({ entityType: "EVENT", entityId: eventList[selectedSlide]?.id }))
			.then((resp) => {
				setshowFormTable(true);
				setEventForm(resp?.data);
			})
			.catch((e) => {
				setshowFormTable(false);
				setshowFormSubmission({ data: null, open: false });
			});
	};

	useEffect(() => {
		if (showFormTable) setAPPLIED_STARTUPS((prevState) => prevState.map((item) => (item.id === 2 ? { ...item, visible: true } : item)));
		else setAPPLIED_STARTUPS((prevState) => prevState.map((item) => (item.id === 2 ? { ...item, visible: false } : item)));

		if (currentTab === 2 && !showFormTable) setCurrentTab(0);
	}, [showFormTable]);

	const handleAccept = async (id, checked) => {
		setCheckboxStates((prev) => ({
			...prev,
			[id]: { ...prev[id], checked, disabled: true, loading: true },
		}));

		try {
			await services.acceptStartupEventApplication({
				companyId: id,
				uuid: eventList[selectedSlide]?.uuid,
				isAccepted: checked,
			});
		} catch (error) {
			console.error(`Failed to process acceptance for company ID ${id}:`, error);

			// Update the state to revert the accepted value
			setCheckboxStates((prev) => ({
				...prev,
				[id]: { ...prev[id], checked: !checked, disabled: false, loading: false }, // Revert checked state
			}));
		} finally {
			// Re-enable the checkbox regardless of success or failure
			setCheckboxStates((prev) => ({
				...prev,
				[id]: { ...prev[id], disabled: false, loading: false },
			}));
		}
	};

	const handleExportApplications = async () => {
		setrequestingApplicationsExport(true);
		try {
			await services
				.formSubmissionsDownload({
					entityId: eventList[selectedSlide]?.id,
					entityType: "EVENT",
				})
				.then((result) => {
					const contentDisposition = result?.headers["content-disposition"];
					let filename = "application-list.csv"; // Default name
					if (contentDisposition) {
						const match = contentDisposition.match(/filename="?([^"]+)"?/);
						if (match && match.length > 1) {
							filename = match[1];
						}
					}

					// Create a Blob and trigger the download
					const url = window.URL.createObjectURL(new Blob([result?.data]));
					const a = document.createElement("a");
					a.href = url;
					a.download = filename;
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					window.URL.revokeObjectURL(url);
				});
		} catch (error) {
			console.error(`Failed to download event applications.`, error);
		} finally {
			setrequestingApplicationsExport(false);
		}
	};

	const handleAcceptedFilterChange = (value) => {
		setshowOnlyAccepted(value);
	};
	useEffect(() => {
		getPitchCompanies(0, 10, "");
		setTableKey((prevKey) => prevKey + 1);
	}, [showOnlyAccepted]);

	const openEventScoreCardEdit = async (id) => {
		try {
			setopenScorecardEdit(true);
			await getEventScorecard(id).then((res) => {
				if (res?.data?.data) setscoreCardObj({ ...res?.data?.data, scorecardUuid: id });
				else setopenScorecardEdit(false);
			});
		} catch (e) {
			console.log("Unable to get scorecard data; failed with error: ", e);
			setopenScorecardEdit(false);
		}
	};
	const getEventScorecard = async (id) => {
		return await services.votingEventScorecardGet(id);
	};

	return (
		<>
			<Box display={"flex"} justifyContent={"space-between"}>
				{" "}
				<Tabs
					variant="scrollable"
					scrollButtons="auto"
					allowScrollButtonsMobile
					value={currentTab}
					onChange={handleChange}
					sx={{
						alignItems: "stretch",
						"& .MuiTabs-flexContainer": {
							gap: 5,
						},
						"& .MuiTabs-indicator": {
							backgroundColor: (theme) => theme.palette.primary.main,
							borderRadius: "5px",
							height: "4px",
						},
						"& .MuiTabs-scrollButtons": {
							color: (theme) => theme.palette.primary.main,
							"& > svg": {
								height: "30px",
								width: "30px",
							},
						},
					}}
				>
					{APPLIED_STARTUPS.map((item, index) => (
						<Tab
							selected={currentTab === item.id}
							onClick={(event) => {
								handleChange(event, index);
							}}
							disableRipple
							key={item.id}
							sx={{
								textTransform: "unset",
								textAlign: "left",
								p: 0,
								pb: 1,
								whiteSpace: "nowrap",
								maxWidth: "unset",
								opacity: ".6",
								filter: "grayscale(100%)",
								"&.Mui-selected": {
									opacity: 1,
									filter: "grayscale(0%)",
								},
								...(!item?.visible && {
									display: "none",
								}),
							}}
							label={
								<Typography
									variant="Text/xs/Semibold"
									sx={{
										color: (theme) => theme.palette.primary.main,
									}}
								>
									{item.title}
								</Typography>
							}
						/>
					))}
				</Tabs>
				{currentTab === 1 && (
					<Can I="manage" a="EventApplicants">
						<Button
							startIcon={<Box component={"img"} src={ARROW_UP_RIGHT_WHITE_ICON} alt="arrow" />}
							disabled={selectedRows?.length < 1}
							onClick={() => setInviteConfirmPopup(true)}
							variant="DS1"
							small={true}
						>
							Send Invite to Selected Prospects
						</Button>
					</Can>
				)}
				{currentTab === 3 && (
					<Can I="manage" a="EventApplicants">
						<Button
							startIcon={<Box component={"img"} src={ARROW_UP_RIGHT_WHITE_ICON} alt="arrow" />}
							disabled={selectedRows?.length < 1}
							onClick={() => setCreateScorecardPopup(true)}
							variant="DS1"
							small={true}
						>
							Create Voting Scorecard
						</Button>
					</Can>
				)}
			</Box>

			{currentTab === 0 && (
				<Box>
					<TableController
						key={`applied_startups_${tableKey}`}
						autoHeight={false}
						requestData={(currentPage, pageSize, searchText) => getPitchCompanies(currentPage, pageSize, searchText)}
						columnDefs={AppliedStartupColumns((id, checked) => handleAccept(id, checked), checkboxStates)}
						title="Applied Startups"
						showCount
						countLabel={{ singular: "Applied Startup", plural: "Applied Startups" }}
						contextKey={selectedSlide}
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						noResultsMessage={
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
										stroke={theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<Typography variant="Text/sm/Semibold">No applied startups found</Typography>
							</Box>
						}
						sortComponent={
							<Box sx={{ alignSelf: "flex-end" }}>
								<SortComponent
									sortOptions={AppliedStartupSortOptions}
									onSortChange={(obj) => handleSortChange(obj)}
									currentSort={sortCriteriaStartups}
								/>
							</Box>
						}
						onRowClick={(res) => openStartupProfile(res?.id)}
						searchText={searchText}
						searchComponent={
							<Box sx={{ alignSelf: "flex-end" }}>
								<SearchComponent searchText={searchText} handleChange={searchPitchCompanies} />
							</Box>
						}
						dataGridProps={{ disableColumnMenu: true }}
						filterComponent={
							<Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
								<Typography variant="Text/xs/Semibold">Filter Accepted</Typography>
								<ToggleButtonGroup
									value={showOnlyAccepted}
									exclusive
									onChange={(e) => handleAcceptedFilterChange(e?.target?.value)}
									variant={"DS1"}
								>
									<ToggleButton value={"all"}>All</ToggleButton>
									<ToggleButton value={"accepted"}>Accepted</ToggleButton>
								</ToggleButtonGroup>
							</Box>
						}
					/>
				</Box>
			)}
			{currentTab === 1 && (
				<Box>
					<TableController
						key={`prospects_${tableKey}`}
						autoHeight={false}
						requestData={(currentPage, pageSize, searchText) => getPitchCompanies(currentPage, pageSize, searchText)}
						columnDefs={ProspectsColumns()}
						title="Prospects"
						showCount
						countLabel={{ singular: "Prospect", plural: "Prospects" }}
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						noResultsMessage={
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
										stroke={theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<Typography variant="Text/sm/Semibold">No prospects found</Typography>
							</Box>
						}
						sortComponent={
							<SortComponent
								sortOptions={AppliedStartupSortOptions}
								onSortChange={(obj) => handleSortChange(obj)}
								currentSort={sortCriteriaStartups}
							/>
						}
						searchText={searchText}
						searchComponent={<SearchComponent searchText={searchText} handleChange={searchPitchCompanies} />}
						checkboxSelection={true}
						rowSelectionModel={selectedRows}
						setRowSelectionModel={handleRowSelection}
						dataGridProps={{
							disableColumnMenu: true,
						}}
						onRowClick={(res) => openStartupProfile(res?.id)}
					/>
				</Box>
			)}
			{currentTab === 2 && (
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<TableController
						key={`event_applications_${tableKey}`}
						autoHeight={false}
						requestData={(currentPage, pageSize) => getEventApplications(currentPage, pageSize)}
						columnDefs={EventApplicationColumns()}
						title="Submitted Applications"
						showCount
						countLabel={{ singular: "Application", plural: "Applications" }}
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						noResultsMessage={
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
										stroke={theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<Typography variant="Text/sm/Semibold">No event applications found</Typography>
							</Box>
						}
						//sortComponent={
						//	<SortComponent
						//		sortOptions={SubmittedApplicationsSortOptions}
						//		onSortChange={(obj) => handleSortChange(obj)}
						//		currentSort={SubmittedApplicationsSortCriteria}
						//	/>
						//}
						onRowClick={(res) => {
							console.log(res);
							setshowFormSubmission({ data: res?.row, open: true });
						}}
						//searchText={searchText}
						//searchComponent={<SearchComponent searchText={searchText} handleChange={searchPitchCompanies} />}
						dataGridProps={{ disableColumnMenu: true }}
					/>
					<ViewFormFlyout
						open={showFormSubmission?.open}
						submissionData={showFormSubmission?.data}
						viewMode="SUBMISSION"
						formString={eventForm?.formStructure}
						onClose={() => {
							setshowFormSubmission({
								open: false,
								data: null,
							});
						}}
					/>
					{applicationsExist && (
						<Button
							variant="DS1"
							color="black"
							sx={{ alignSelf: "flex-end", my: 2, mb: 5 }}
							disabled={requestingApplicationsExport}
							startIcon={requestingApplicationsExport && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
							onClick={() => handleExportApplications()}
						>
							Export Applications
						</Button>
					)}
				</Box>
			)}
			{currentTab === 3 && (
				<Box sx={{ display: "flex", flexDirection: "column" }}>
					<TableController
						key={`accepted_startups_${tableKey}`}
						autoHeight={false}
						requestData={(currentPage, pageSize) => getAcceptedCompanies(currentPage, pageSize, searchText)}
						columnDefs={AcceptedStartupColumns()}
						title="Accepted Startups"
						description={"These companies have been accepted to the event."}
						showCount
						countLabel={{ singular: "Startup", plural: "Startups" }}
						icon={
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
						}
						noResultsMessage={
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: "16px",
									alignSelf: "stretch",
									flex: 1,
									justifyContent: "center",
								}}
							>
								<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
									<path
										d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
										stroke={theme?.palette?.primary?.main}
										stroke-width="1.5"
										stroke-linecap="round"
										stroke-linejoin="round"
									/>
								</svg>
								<Typography variant="Text/sm/Semibold">No accepted startups found</Typography>
							</Box>
						}
						//sortComponent={
						//	<SortComponent
						//		sortOptions={SubmittedApplicationsSortOptions}
						//		onSortChange={(obj) => handleSortChange(obj)}
						//		currentSort={SubmittedApplicationsSortCriteria}
						//	/>
						//}
						//onRowClick={(res) => {
						//	console.log(res);
						//	setshowFormSubmission({ data: res?.row, open: true });
						//}}
						//searchText={searchText}
						//searchComponent={<SearchComponent searchText={searchText} handleChange={searchPitchCompanies} />}
						checkboxSelection={true}
						rowSelectionModel={selectedRows}
						setRowSelectionModel={handleRowSelection}
						dataGridProps={{
							disableColumnMenu: true,
						}}
					/>
					<ViewFormFlyout
						open={showFormSubmission?.open}
						submissionData={showFormSubmission?.data}
						viewMode="SUBMISSION"
						formString={eventForm?.formStructure}
						onClose={() => {
							setshowFormSubmission({
								open: false,
								data: null,
							});
						}}
					/>
					{applicationsExist && (
						<Button
							variant="DS1"
							color="black"
							sx={{ alignSelf: "flex-end", my: 2, mb: 5 }}
							disabled={requestingApplicationsExport}
							startIcon={requestingApplicationsExport && <CircularProgress sx={{ color: "inherit" }} size={"1.25rem"} />}
							onClick={() => handleExportApplications()}
						>
							Export Applications
						</Button>
					)}
				</Box>
			)}
			{currentTab === 4 && (
				<TableController
					key={`scorecards_${tableKey}`}
					autoHeight={false}
					requestData={(currentPage, pageSize) => getEventScorecards(currentPage, pageSize)}
					columnDefs={ScorecardColumns((uuid) => openEventScoreCardEdit(uuid))}
					title="Event Scorecards"
					description={"These scorecards have been configured for the event."}
					showCount
					countLabel={{ singular: "Scorecard", plural: "Scorecards" }}
					icon={
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<path
								d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
								stroke={theme?.palette?.primary?.main}
								stroke-width="1.5"
								stroke-linecap="round"
								stroke-linejoin="round"
							/>
						</svg>
					}
					noResultsMessage={
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								gap: "16px",
								alignSelf: "stretch",
								flex: 1,
								justifyContent: "center",
							}}
						>
							<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
								<path
									d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
									stroke={theme?.palette?.primary?.main}
									stroke-width="1.5"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</svg>
							<Typography variant="Text/sm/Semibold">No scorecards found</Typography>
						</Box>
					}
					//sortComponent={
					//	<SortComponent
					//		sortOptions={SubmittedApplicationsSortOptions}
					//		onSortChange={(obj) => handleSortChange(obj)}
					//		currentSort={SubmittedApplicationsSortCriteria}
					//	/>
					//}
					onRowClick={(res) => {
						history.push(ROUTES_PATH.VOTING_SCORECARD + "/admin/" + res?.row?.uuid);
					}}
					//searchText={searchText}
					//searchComponent={<SearchComponent searchText={searchText} handleChange={searchPitchCompanies} />}

					dataGridProps={{
						disableColumnMenu: true,
					}}
				/>
			)}
			<CreateScoreCardModal
				open={createScorecardPopup || openScorecardEdit}
				onClose={(e) => {
					if (e && e?.updateTable) {
						setTableKey((prevKey) => prevKey + 1);
					}
					setCreateScorecardPopup(false);
					setopenScorecardEdit(false);
					setscoreCardObj({});
				}}
				companyIds={selectedRows}
				eventUUID={eventList[selectedSlide]?.uuid}
				isEdit={openScorecardEdit}
				scoreCardObj={scoreCardObj}
			/>
			<MessageHandlerModal
				isOpen={inviteConfirmPopup}
				onCancel={() => setInviteConfirmPopup(false)}
				onOk={() => {
					handleSendEventInvite();
				}}
				okText="Yes"
				cancelText={"Cancel"}
				heading={`Send Invites to Selected Prospects?`}
				text={`Are you sure you want to send invites out to selected prospects? Recipients of the invite will receive an email about the event and it’s details.`}
				messageType="primary"
			/>
		</>
	);
};

export default AppliedStartups;
