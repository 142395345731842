import React, { useEffect, useState } from "react";
import { Typography, Box, Button, Grid, InputAdornment, IconButton, Switch, CircularProgress } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import moment from "moment";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CommonSlideDialog from "../../../../common/CommonSlideDialog";
import UpdatedTextInputField from "../../../../common/UpdatedTextInputField";
import services from "../../../../services";
import { toastContainer } from "../../../../utils/toast";
import messages from "../../../../constants/messages";
import { ClockIcon } from "@mui/x-date-pickers";
import UpdatedAutocompleteInput from "../../../../common/UpdatedAutocompleteInput";
import { REGEX } from "../../../../constants/regex";
import { AcceptedStartupColumns } from "./AppliedStartupColumns";
import TableController from "../../../../components/Tables/TableController";

export default function ScorecardForm({ open, onClose, companyIds, eventUUID, scoreCardObj, isEdit = false }) {
	const emailItems = React.createRef();
	const [emails, setEmails] = useState({ users: [] });
	const [sortCriteriaStartups, setSortCriteriaStartups] = useState({ colName: "", direction: "" });
	const [selectedRows, setSelectedRows] = useState([]);
	const [isError, setIsError] = useState(false);

	const onChangeHandler = (e, key) => {
		const value = e.target.value;
		const currentInput = { ...emails };
		currentInput[key] = value;
		setEmails(currentInput);
	};
	const handleOnChange = (value) => {
		if (!REGEX.EMAIL.test(value) && value !== "") {
			setIsError(true);
		} else {
			setIsError(false);
		}
	};

	const schema = yup
		.object({
			name: yup.string().required("Name is required"),
			voteScorecardStatus: yup.boolean(),
			judgeEmails: yup
				.array()
				.of(yup.string().email().required("At least one judge is required"))
				.min(1, "At least one judge is required")
				.required("At least one judge is required"),
			startDate: yup.string().required("Start date is required"),
			startTime: yup.string().required("Start time is required"),
			closeDate: yup.string().required("Close date is required"),
			closeTime: yup.string().required("Close time is required"),
			eventUuid: yup.string().required("Event ID is required"),
			targetCompanyIds: yup
				.array()
				.of(yup.number().integer().required("Startups are required"))
				.min(1, "At least one startup is required")
				.required("Startup array is required"),
		})
		.test("is-start-before-end", "Start date and time must be before end date and time", (values, context) => {
			if (!values.startDate || !values.startTime || !values.closeDate || !values.closeTime) return true;

			const start = moment(`${values.startDate} ${values.startTime}`, "YYYY-MM-DD HH:mm:ss");
			const end = moment(`${values.closeDate} ${values.closeTime}`, "YYYY-MM-DD HH:mm:ss");

			if (!start.isBefore(end)) {
				if (values.startDate == values.closeDate)
					return context.createError({
						path: "closeTime",
						message: "Close time must be after start time",
					});
				else
					return context.createError({
						path: "closeDate",
						message: "Close date must be after start date",
					});
			} else return true;
		});

	const [loading, setLoading] = useState(false);

	const {
		handleSubmit,
		control,
		getValues,
		setValue,
		watch,
		reset,
		formState: { errors, isValid },
	} = useForm({
		mode: "onSubmit",
		defaultValues: {
			name: isEdit ? scoreCardObj?.name || "" : "",
			judgeEmails: isEdit ? scoreCardObj?.judgeEmails || [] : [],
			targetCompanyIds: isEdit ? scoreCardObj?.targetCompanyIds || [] : companyIds,
			eventUuid: isEdit ? scoreCardObj?.eventUuid || "" : eventUUID,
			startDate: isEdit ? scoreCardObj?.startDate || "" : "",
			startTime: isEdit ? scoreCardObj?.startTime || "" : "",
			closeDate: isEdit ? scoreCardObj?.closeDate || "" : "",
			closeTime: isEdit ? scoreCardObj?.closeTime || "" : "",
			voteScorecardStatus: isEdit ? scoreCardObj?.voteScorecardStatus || false : false,
		},
		resolver: yupResolver(schema),
	});

	const name = watch("name") || [null];
	const startDate = watch("startDate") || null;
	const startTime = watch("startTime") || null;
	const closeDate = watch("closeDate") || null;
	const closeTime = watch("closeTime") || null;
	const voteScorecardStatus = watch("voteScorecardStatus", false);
	const targetCompanyIds = watch("targetCompanyIds") || null;

	useEffect(() => {
		setEmails({ users: [] });
		reset();
	}, [open]);

	useEffect(() => {
		if (scoreCardObj && isEdit) {
			const existing_emails =
				isEdit && Array.isArray(scoreCardObj?.judgeEmails) && scoreCardObj?.judgeEmails?.length > 0
					? { users: [...scoreCardObj?.judgeEmails] }
					: { users: [] };
			setEmails(existing_emails);

			const existingTargets =
				isEdit && Array.isArray(scoreCardObj?.targetCompanyIds) && scoreCardObj?.targetCompanyIds?.length > 0 ? scoreCardObj?.targetCompanyIds : [];

			setSelectedRows(existingTargets);

			reset({
				name: isEdit ? scoreCardObj?.name || "" : "",
				judgeEmails: isEdit ? scoreCardObj?.judgeEmails || [] : [],
				targetCompanyIds: isEdit ? scoreCardObj?.targetCompanyIds || [] : companyIds,
				eventUuid: isEdit ? scoreCardObj?.eventUuid || "" : eventUUID,
				startDate: isEdit ? scoreCardObj?.startDate || "" : "",
				startTime: isEdit ? scoreCardObj?.startTime || "" : "",
				closeDate: isEdit ? scoreCardObj?.closeDate || "" : "",
				closeTime: isEdit ? scoreCardObj?.closeTime || "" : "",
				voteScorecardStatus: isEdit && scoreCardObj?.voteScorecardStatus === "PUBLISHED" ? true : false,
			});
		}
	}, [scoreCardObj]);

	useEffect(() => {
		setValue("judgeEmails", emails?.users ? [...emails?.users] : []);
	}, [emails]);

	const onSubmit = async () => {
		try {
			setLoading(true);
			const payload = {
				name: getValues("name"),
				judgeEmails: getValues("judgeEmails"),
				targetCompanyIds: getValues("targetCompanyIds"),
				eventUuid: getValues("eventUuid"),
				startDate: getValues("startDate"),
				startTime: getValues("startTime"),
				closeDate: getValues("closeDate"),
				closeTime: getValues("closeTime"),
				voteScorecardStatus: getValues("voteScorecardStatus") ? "PUBLISHED" : "DRAFT",
			};

			let resData = null;

			if (isEdit) {
				resData = await services.votingEventScorecardUpdate({
					...payload,
					scorecardUuid: scoreCardObj?.scorecardUuid,
				});
			} else {
				resData = await services.votingEventScorecardCreate(payload);
			}
			onClose({ updateTable: true });
			reset();
			toastContainer(isEdit ? messages.VOTING_SCORECARD_UPDATED_SUCCESS : messages.VOTING_SCORECARD_CREATED_SUCCESS);
		} catch (err) {
			toastContainer(err?.response.data?.message, "error");
		} finally {
			setLoading(false);
		}
	};

	const CommonDateTimePicker = ({ value, onChange, disableFuture = false, disablePast = false, maxDate, minDate, isTime = false, error }) => {
		const [openPicker, setOpenPicker] = useState(false);

		return (
			<LocalizationProvider dateAdapter={AdapterMoment}>
				{isTime ? (
					<TimePicker
						value={value ? moment(value, "HH:mm:ss") : null}
						onAccept={(newValue) => {
							onChange(moment(newValue).format("HH:mm:ss"));
						}}
						open={openPicker}
						onOpen={() => setOpenPicker(true)}
						onClose={() => setOpenPicker(false)}
						closeOnSelect={false} // Important to avoid premature closing
						slots={{
							OpenPickerIcon: ClockIcon,
						}}
						slotProps={{
							actionBar: { actions: ["cancel", "accept"] },
							textField: {
								error: error,
								onClick: () => setOpenPicker(true),
								variant: "outlined",
								InputProps: {
									endAdornment: (
										<InputAdornment position="end" sx={{ mr: 1.5 }}>
											<IconButton edge="end">
												<ClockIcon sx={{ color: "#000" }} />
											</IconButton>
										</InputAdornment>
									),
									sx: {
										borderRadius: "30px",
										height: "60px",
										fontWeight: "500",
										fontSize: "16px",
										lineHeight: "24px",
										padding: "1.5px 6px",
										cursor: "pointer",
										backgroundColor: "#FFF",
									},
								},
								sx: {
									width: "100%",
									borderRadius: "30px",
									backgroundColor: "white",
									"& .MuiOutlinedInput-root": {
										padding: "6px",
										borderRadius: "30px",
									},
									"& input::placeholder": {
										fontFamily: '"PoppinsMedium", Helvetica, Arial, sans-serif',
										fontSize: "16px",
										lineHeight: "24px",
									},
								},
							},
						}}
					/>
				) : (
					<DatePicker
						value={value ? moment(value, "YYYY-MM-DD") : null}
						onAccept={(newValue) => {
							onChange(moment(newValue).format("YYYY-MM-DD"));
						}}
						disableFuture={disableFuture}
						disablePast={disablePast}
						maxDate={maxDate}
						minDate={minDate}
						open={openPicker}
						onOpen={() => setOpenPicker(true)}
						onClose={() => setOpenPicker(false)}
						closeOnSelect={false}
						format="MM/DD/YYYY"
						slots={{
							OpenPickerIcon: CalendarTodayOutlinedIcon,
						}}
						slotProps={{
							actionBar: { actions: ["cancel", "accept"] },
							textField: {
								error: error,
								onClick: () => setOpenPicker(true),
								variant: "outlined",
								InputProps: {
									endAdornment: (
										<InputAdornment position="end" sx={{ mr: 1.5 }}>
											<IconButton edge="end">
												<CalendarTodayOutlinedIcon sx={{ color: "#000" }} />
											</IconButton>
										</InputAdornment>
									),
									sx: {
										borderRadius: "30px",
										height: "60px",
										fontWeight: "500",
										fontSize: "16px",
										lineHeight: "24px",
										padding: "1.5px 6px",
										cursor: "pointer",
										backgroundColor: "#FFF",
									},
								},
								sx: {
									width: "100%",
									borderRadius: "30px",
									backgroundColor: "white",
									"& .MuiOutlinedInput-root": {
										padding: "6px",
										borderRadius: "30px",
									},
									"& input::placeholder": {
										fontFamily: '"PoppinsMedium", Helvetica, Arial, sans-serif',
										fontSize: "16px",
										lineHeight: "24px",
									},
								},
							},
						}}
					/>
				)}
			</LocalizationProvider>
		);
	};

	const getAcceptedCompanies = async (pageNo, perPage, searchText = "") => {
		try {
			let page = pageNo + 1;
			const response = await services.getPitchCompaniesApi({
				page,
				perPage,
				isApplied: true,
				eventUuid: eventUUID,
				filter: sortCriteriaStartups?.colName,
				sort: sortCriteriaStartups?.direction,
				searchText,
				isAccepted: true,
			});
			if (response) {
				const { totalElements, models } = response?.data?.data;

				if (!models) {
					return {
						data: {
							totalElements: totalElements,
							models: [],
						},
					};
				}

				const newModels = models.map((model) => ({
					...model,
					name: {
						name: model.companyName,
						img: model.logoUrl,
					},
					location: model?.city && model?.state ? `${model?.city}, ${model?.state}` : "",
					industry: model?.industry && JSON.parse(model?.industry),
				}));

				return {
					data: {
						totalElements: totalElements,
						models: newModels,
					},
				};
			}
		} catch (error) {
			console.error("Error fetching accepted companies:", error);
		}
	};

	const handleRowSelection = (selectedIds) => {
		setSelectedRows(selectedIds);
	};

	useEffect(() => {
		if (isEdit && selectedRows.length > 0) {
			setValue("targetCompanyIds", selectedRows);
		}
	}, [selectedRows]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Box>
				{isEdit && !scoreCardObj ? (
					<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
						<CircularProgress />
					</Box>
				) : (
					<Grid container rowSpacing={2} columnSpacing={7}>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant="Text/sm/Medium">{"Scorecard Title*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="name"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<UpdatedTextInputField
											control={control}
											id={"name"}
											value={value}
											inputProps={{ maxLength: 255 }}
											name={name}
											sx={{
												"& .MuiOutlinedInput-notchedOutline": {
													border: errors.name ? "1px solid #F04438 !important" : "1px solid #DDDDDD",
												},
											}}
											handleChange={onChange}
											placeholder={"Type here"}
											textFieldProps={{
												fullwidth: true,
												width: "100%",
												height: "60px",
											}}
										/>
									)}
								/>
								{errors?.name && (
									<Typography component={"p"} variant="Text/xs/Regular" sx={{ color: "#F04438" }}>
										{errors?.name?.message}
									</Typography>
								)}
							</Box>
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Start Date*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="startDate"
									control={control}
									render={({ field: { onChange, value } }) => (
										<CommonDateTimePicker
											value={value}
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											error={!!errors.startDate}
										/>
									)}
								/>
								{errors.startDate && (
									<Typography component={"p"} variant="Text/xs/Regular" sx={{ color: "#F04438" }}>
										{errors.startDate.message}
									</Typography>
								)}

								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The date when the scorecard becomes available for judges to begin scoring.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Start Time*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="startTime"
									control={control}
									render={({ field: { onChange, value } }) => (
										<CommonDateTimePicker
											value={value}
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											isTime
											error={!!errors.startTime}
										/>
									)}
								/>
								{errors.startTime && (
									<Typography component={"p"} variant="Text/xs/Regular" sx={{ color: "#F04438" }}>
										{errors.startTime.message}
									</Typography>
								)}

								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The exact time when the scorecard will be accessible to judges (associated with the Start Date).
								</Typography>
							</Box>
						</Grid>

						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Close Date*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="closeDate"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDateTimePicker
											value={value}
											type={"closeDate"}
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											maxDate={null}
											minDate={closeDate && moment(closeDate)}
											error={!!errors.closeDate}
										/>
									)}
								/>
								{errors.closeDate && (
									<Typography component={"p"} variant="Text/xs/Regular" sx={{ color: "#F04438" }}>
										{errors.closeDate.message}
									</Typography>
								)}
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The deadline date for judges to complete and submit their scores.
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
							<Typography variant="Text/sm/Medium">{"Close Time*"}</Typography>
							<Box sx={{ mt: 1 }}>
								<Controller
									name="closeTime"
									control={control}
									render={({ field: { onChange, value, name } }) => (
										<CommonDateTimePicker
											value={value}
											type={"closeTime"}
											onChange={onChange}
											disableFuture={false}
											disablePast={true}
											maxDate={null}
											minDate={closeTime && moment(closeTime)}
											isTime
											error={!!errors.closeTime}
										/>
									)}
								/>
								{errors.closeTime && (
									<Typography component={"p"} variant="Text/xs/Regular" sx={{ color: "#F04438" }}>
										{errors.closeTime.message}
									</Typography>
								)}
								<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
									The exact time when the scorecard will close and no further scoring submissions will be accepted (associated with the Close
									Date).
								</Typography>
							</Box>
						</Grid>
						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Typography variant="Text/sm/Medium">{"Manage Judges*"}</Typography>
							<Box sx={{ display: "flex", gap: 2, py: 1 }}>
								<Box>
									<UpdatedAutocompleteInput
										fullWidth
										handleInputChange={onChangeHandler}
										inputKey={"users"}
										autoCompleteData={[]}
										inputValid={(key, input) => {
											if (input.length > 0 && input.length <= 200) {
												return true;
											} else {
												return false;
											}
										}}
										inputValue={emails ? [...emails?.users] : []}
										errorMessage={messages.MULTITAG_INPUT_INVALID}
										sx={{
											borderRadius: "30px",
											display: "grid",

											"& .MuiOutlinedInput-root ": {
												borderRadius: "30px",
												fontFamily: "PoppinsRegular",
												fontSize: "0.875rem",
												lineHeight: "1.25rem",
											},
											mb: "8px",
										}}
										typingEnabled
										multiple
										handleOnBlurEvent={true}
										inputRef={emailItems}
										isEmailField={true}
										handleOnChange={handleOnChange}
									/>
									{errors.judgeEmails && (
										<Typography component={"p"} variant="Text/xs/Regular" sx={{ color: "#F04438" }}>
											{errors.judgeEmails.message}
										</Typography>
									)}
									<Typography variant="Text/xs/Regular" sx={{ color: "#475467" }}>
										Enter the email addresses of judges to receive the voting scorecard. Use the Enter key to separate tags or click the [+
										Add] button to add emails. Invitations will be sent only after the publish toggle is enabled and changes are saved.
									</Typography>
								</Box>
								<Box>
									<Button
										sx={{
											width: "max-content",
											height: "52px",
										}}
										variant="PurpleRoundedOutline"
										onClick={() => emailItems.current.blur()}
										disabled={isError}
									>
										+ Add
									</Button>
								</Box>
							</Box>
						</Grid>

						{isEdit && (
							<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
								<Box
									sx={{
										...(errors.targetCompanyIds && {
											"& > .MuiBox-root": {
												border: "1px solid red !important",
											},
										}),
									}}
								>
									<TableController
										key={`accepted_startups_inModal`}
										autoHeight={false}
										requestData={(currentPage, pageSize) => getAcceptedCompanies(currentPage, pageSize, "")}
										columnDefs={AcceptedStartupColumns()}
										title="Selected Startups"
										description={"These companies have been accepted to the event and are available to be selected for this scorecard."}
										showSelectedCount
										countLabel={{ singular: "Startup", plural: "Startups" }}
										icon={
											<Box
												sx={(theme) => ({
													"& > svg": {
														stroke: theme?.palette?.primary?.main,
													},
												})}
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
													<path
														d="M20 28V20.8C20 20.0533 20 19.6799 19.8547 19.3947C19.7268 19.1438 19.5229 18.9398 19.272 18.812C18.9868 18.6667 18.6134 18.6667 17.8667 18.6667H14.1333C13.3866 18.6667 13.0132 18.6667 12.728 18.812C12.4771 18.9398 12.2732 19.1438 12.1453 19.3947C12 19.6799 12 20.0533 12 20.8V28M25.3333 28V8.26667C25.3333 6.77319 25.3333 6.02646 25.0427 5.45603C24.787 4.95426 24.3791 4.54631 23.8773 4.29065C23.3069 4 22.5601 4 21.0667 4H10.9333C9.43986 4 8.69312 4 8.12269 4.29065C7.62093 4.54631 7.21298 4.95426 6.95732 5.45603C6.66667 6.02646 6.66667 6.77319 6.66667 8.26667V28M28 28H4M12.6667 10.6667H12.68M19.3333 10.6667H19.3467M13.3333 10.6667C13.3333 11.0349 13.0349 11.3333 12.6667 11.3333C12.2985 11.3333 12 11.0349 12 10.6667C12 10.2985 12.2985 10 12.6667 10C13.0349 10 13.3333 10.2985 13.3333 10.6667ZM20 10.6667C20 11.0349 19.7015 11.3333 19.3333 11.3333C18.9651 11.3333 18.6667 11.0349 18.6667 10.6667C18.6667 10.2985 18.9651 10 19.3333 10C19.7015 10 20 10.2985 20 10.6667Z"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
											</Box>
										}
										noResultsMessage={
											<Box
												sx={(theme) => ({
													display: "flex",
													flexDirection: "column",
													alignItems: "center",
													gap: "16px",
													alignSelf: "stretch",
													flex: 1,
													justifyContent: "center",
													"& > svg": {
														stroke: theme?.palette?.primary?.main,
													},
												})}
											>
												<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
													<path
														d="M28 28L23.3335 23.3333M26.6667 15.3333C26.6667 21.5926 21.5926 26.6667 15.3333 26.6667C9.07411 26.6667 4 21.5926 4 15.3333C4 9.07411 9.07411 4 15.3333 4C21.5926 4 26.6667 9.07411 26.6667 15.3333Z"
														stroke-width="1.5"
														stroke-linecap="round"
														stroke-linejoin="round"
													/>
												</svg>
												<Typography variant="Text/sm/Semibold">No accepted startups found</Typography>
											</Box>
										}
										checkboxSelection={true}
										rowSelectionModel={selectedRows}
										setRowSelectionModel={handleRowSelection}
										dataGridProps={{
											disableColumnMenu: true,
										}}
									/>
								</Box>
								{errors?.targetCompanyIds && (
									<Typography component={"p"} variant="Text/xs/Regular" sx={{ color: "#F04438", my: 1 }}>
										{errors?.targetCompanyIds?.message}
									</Typography>
								)}
							</Grid>
						)}

						<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
							<Controller
								name="voteScorecardStatus"
								control={control}
								render={({ field: { onChange, value, name } }) => (
									<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
										<Typography variant="Text/sm/Medium">Publish Scorecard?</Typography>

										<Switch
											color="primary"
											onChange={(e) => onChange(e?.target?.checked)}
											checked={value}
											inputProps={{ "aria-label": "controlled" }}
										/>
									</Box>
								)}
							/>
						</Grid>
					</Grid>
				)}
			</Box>
			<Box
				sx={{
					gap: 2,
					display: "flex",
					alignItems: "center",
					justifyContent: "flex-end",
					mt: 3,
				}}
			>
				<Button onClick={onClose} color="clear" variant="DS1">
					Cancel
				</Button>
				<Button
					startIcon={loading && <CircularProgress size={"1.25rem"} sx={{ color: "inherit" }} />}
					variant="DS1"
					onClick={handleSubmit(onSubmit)}
					disabled={loading}
				>
					{isEdit ? "Save" : "Create"}
				</Button>
			</Box>
		</form>
	);
}
