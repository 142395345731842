import * as React from "react";
import { Box, Typography, FormControl, InputAdornment, IconButton, FormHelperText } from "@mui/material";
import { StyledTextFieldNew } from "./style";
import { ErrorOutlineOutlined } from "@mui/icons-material";
import { ArrowDropDown } from "@mui/icons-material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const UpdatedTextInputField = ({ label, handleChange, inputValue, placeholder, textFieldProps, id, error, inputProps, handleBlur, ...otherProps }) => {
	return (
		<Box sx={{ minWidth: 120, width: "100%" }}>
			<Typography variant="count_title">{label}</Typography>
			<StyledTextFieldNew
				id={id}
				value={inputValue}
				error={!!error}
				placeholder={placeholder}
				fullWidth={textFieldProps?.fullWidth}
				height={textFieldProps?.height}
				width={textFieldProps?.width}
				onChange={handleChange}
				onBlur={handleBlur}
				inputProps={inputProps}
				sx={{
					mt: 1,
					"& .MuiOutlinedInput-root": {
						p: "6px",

						border: (theme) => (!!error ? `5px solid ${theme.palette.secondary.errorTextFieldRed} !important` : "none"),
					},
					"& input::placeholder": {
						fontFamily: '"PoppinsMedium", "Helvetica", "Arial", sans-serif',
						fontSize: "16px",
						lineHeight: "24px",
					},
				}}
				InputProps={{
					...inputProps,
					startAdornment: error ? (
						<InputAdornment position="start">
							<IconButton>
								<ErrorOutlineOutlined sx={{ color: (theme) => theme.palette.secondary.errorTextFieldInfoRed }} />
							</IconButton>
						</InputAdornment>
					) : null,
					// Commenting out... why on earth was this hardcoded for a globally reused input...?
					//endAdornment: (
					//	<InputAdornment position="end">
					//		<IconButton>{<VisibilityOff />}</IconButton>
					//	</InputAdornment>
					//),
				}}
				{...otherProps}
			/>
			{!!error && (
				<FormHelperText sx={{ mt: 1 }} error>
					<Typography variant="Text/sm/Regular" color={"error.main"}>
						{error}
					</Typography>
				</FormHelperText>
			)}
		</Box>
	);
};

export default UpdatedTextInputField;
