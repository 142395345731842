import { Box, Typography, ListItem, List, Link, Button } from "@mui/material";
import ROUTES_PATH from "../../../constants/routes";

const AccountProfileDesc = () => (
	<Typography variant="Text/md/Regular">
		Your community wants to learn more about you. Share your founder story and what inspires you, to increase your chance at being discovered by investors
		and mentors.
	</Typography>
);
const StartupProfileDesc = () => (
	<Typography variant="Text/md/Regular">
		Investors are actively seeking investment-related information on your startup profile page to help drive decisions, share information, work with your
		startup, or make investment decisions.
	</Typography>
);

const PitchDeckDesc = () => (
	<Typography variant="Text/md/Regular">
		If you're raising money for your business, having an impressive pitch increases your chances of being discovered. A great pitch deck gets potential
		investors excited about your idea and engages them in a conversation about your business, hopefully leading to an investment.
	</Typography>
);

const IncorporateNow = () => (
	<Box>
		<Box ml={2}>
			<List
				sx={{
					listStyleType: "disc",
					"& .MuiListItem-root": {
						display: "list-item",
					},
				}}
			>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="custom070">Gain credibility.</Typography>{" "}
					<Typography variant="subhead1">
						Investors are more likely to invest in a business that is incorporated. This shows that you are serious about your business and that you
						have taken the necessary steps to protect it.
					</Typography>
				</ListItem>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="custom070">Easier to raise money.</Typography>{" "}
					<Typography variant="subhead1">
						You can issue shares of stock to investors. This allows you to raise money without having to give up control of your business.
					</Typography>
				</ListItem>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="custom070">Necessary to do business.</Typography>{" "}
					<Typography variant="subhead1">
						You can open a business bank account, sign contracts, and hire employees. This makes it easier to operate your business and to grow it.
					</Typography>
				</ListItem>
			</List>
		</Box>

		<Box mt={2} sx={{ background: "#F2EFFF", p: 3, borderRadius: "12px" }}>
			<Link target="_blank" underline="none" href="https://get.firstbase.io/startupos">
				<Typography variant="custom070" color="#7B61FF">
					Click here to Incorporate now with Firstbase.io.
				</Typography>{" "}
			</Link>
			<Typography variant="subhead1"> Use Coupon Code “</Typography>
			<Typography variant="custom070">PARTNER10</Typography>
			<Typography variant="subhead1">” to receive a 10% discount</Typography>
			<Button sx={{ borderRadius: "20px", background: "#7A5AF8", mt: 2, width: "174px" }}>Incorporate now</Button>
		</Box>
	</Box>
);

const StartupOSGrid = () => (
	<Box>
		<Typography variant="Text/md/Regular">
			Startup Grid provides financial and product metrics that are important to investors to identify trends and assess the viability of your startup.
		</Typography>
		<Box ml={2}>
			<List
				sx={{
					mt: 2,
					listStyleType: "disc",
					"& .MuiListItem-root": {
						display: "list-item",
					},
				}}
			>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="subhead1">
						Data visualizations are a great way to illustrate your financial and product metrics in a clear and concise way.
					</Typography>
				</ListItem>
				<ListItem sx={{ p: 0 }}>
					<Typography variant="subhead1">
						Startup Grid is a shortcut to share your financial and product metrics with investors. The more transparent you are, the more likely
						they are to trust you and to invest in your company.
					</Typography>
				</ListItem>
			</List>
		</Box>
	</Box>
);

const ConnectInvestors = () => (
	<Typography variant="Text/md/Regular">
		Increase your startup’s visibility with our network of investors who are eager to discover and invest in promising startups like yours.
	</Typography>
);

const ViewMatchedMentors = () => (
	<Typography variant="Text/md/Regular">
		Don’t go it alone. Build your startup support system with mentors matched to your goals and needs. Take advantage of invaluable insights and connect
		with industry leaders. Connect and grow your mentor community today!
	</Typography>
);

const StartupOSFeed = () => (
	<Typography variant="Text/md/Regular">
		Tap into a wealth of knowledge, support, and inspiration from fellow startups. Meet new people, learn new things and get involved in our community.
	</Typography>
);

const LatestEvents = () => (
	<Typography variant="Text/md/Regular">
		Take your startup journey to the next level by joining our exclusive lineup of StartupOS hosted events tailored specifically for you. Don't miss the
		chance to network, learn from industry experts, and share your entrepreneurial success. Check out our event schedule today!
	</Typography>
);

const AIKnowledgeCenter = () => (
	<Typography variant="Text/md/Regular">Review useful resources tailored for founders, mentors, and investors curated by AI and StartupOS. </Typography>
);

const DiscoveryBoard = () => (
	<Typography variant="Text/md/Regular">
		Discover, pin, and organize ideas, inspiration, and content. Create your Discovery board experience by curating and collecting images, links, and videos
		that interest you! Capture findings and share it with your team today. 
	</Typography>
);

const CompletePowerUp = () => (
	<Box>
		<Typography variant="Text/md/Regular">
			PowerUps are our learn-by-doing exercises, designed to build competency to better understand your business model, pricing, market size and all of
			the things that you need to navigate your journey to product-market-fit.
		</Typography>
		<Box mt={2} p={3} sx={{ background: "#F2EFFF", borderRadius: "12px" }}>
			<Typography variant="Text/md/Regular">Explore our most popular PowerUps:</Typography>
			<Box ml={3.5}>
				<List
					sx={{
						my: 2,
						listStyleType: "disc",
						"& .MuiListItem-root": {
							display: "list-item",
						},
					}}
				>
					<ListItem sx={{ p: 0 }}>
						<Typography variant="subhead1">Problem solution fit</Typography>
					</ListItem>
					<ListItem sx={{ p: 0 }}>
						<Typography variant="subhead1">Value proposition</Typography>
					</ListItem>
					<ListItem sx={{ p: 0 }}>
						<Typography variant="subhead1">Persona builder</Typography>
					</ListItem>
					<ListItem sx={{ p: 0 }}>
						<Typography variant="subhead1">TAM/SAM/SOM</Typography>
					</ListItem>
				</List>
			</Box>

			<Button sx={{ borderRadius: "20px", background: "#7A5AF8", width: "340px" }}>Check PowerUp library today!</Button>
		</Box>
	</Box>
);

const NeedHelp = () => (
	<Box display="flex" flexDirection="column" gap={1.25} mt={-1}>
		<Link underline="none" href="/home/to-do">
			<Typography variant="custom070" color="#7B61FF">
				Founder Home Page
			</Typography>{" "}
		</Link>
		{/* <Link underline="none" href="#">
			<Typography variant="custom070" color="#7B61FF">
				Startup Profile Guided Tour
			</Typography>{" "}
		</Link>
		<Link underline="none" href="#">
			<Typography variant="custom070" color="#7B61FF">
				Community Guided Tour
			</Typography>{" "}
		</Link> */}
	</Box>
);

export const PLATFORM_JOURNEY = [
	{
		id: 0,
		title: "Tell Your Story",
		items: [
			{ id: 0, disabled: true, title: "Complete your Account Profile", desc: <AccountProfileDesc />, route: ROUTES_PATH.MY_ACCOUNT_PROFILE },
			{
				id: 1,
				disabled: true,
				title: "Complete your Startup Profile",
				desc: <StartupProfileDesc />,
				route: ROUTES_PATH.MY_STARTUP_PROFILE,
			},
		],
	},
	{
		id: 1,
		title: "Investor Readiness",
		items: [
			{ id: 2, disabled: true, title: "Upload your Startup Pitch Deck", desc: <PitchDeckDesc />, route: ROUTES_PATH.MY_STARTUP_PROFILE },
			{
				id: 3,
				disabled: true,
				title: "Incorporate Now",
				desc: <IncorporateNow />,
				route: ROUTES_PATH.MY_STARTUP_PROFILE,
			},
			{ id: 4, disabled: true, title: "Update your StartupOS Grid", desc: <StartupOSGrid />, route: ROUTES_PATH.STARTUPGRID },
		],
	},
	{
		id: 2,
		title: "Build Your Network",
		items: [
			{ id: 5, disabled: true, title: "Message an Investor", desc: <ConnectInvestors />, route: ROUTES_PATH.NETWORK },
			{
				id: 6,
				disabled: true,
				title: "Schedule a meeting with a Mentor",
				desc: <ViewMatchedMentors />,
				route: ROUTES_PATH.NETWORK,
			},
			{ id: 7, disabled: true, title: "Post to the StartupOS Feed", desc: <StartupOSFeed />, route: ROUTES_PATH.FEED_PATH },
			{ id: 8, disabled: true, title: "Check latest events", desc: <LatestEvents />, route: ROUTES_PATH.EVENTS_BASEPATH },
		],
	},
	{
		id: 3,
		title: "Level up!",
		items: [
			{ id: 9, disabled: true, title: "Complete a PowerUp", desc: <CompletePowerUp />, route: ROUTES_PATH.STARTUPS_HOME_POWERUP_LIBRARY },
			{
				id: 10,
				disabled: true,
				title: "Search our Knowledge Center",
				desc: <AIKnowledgeCenter />,
				route: ROUTES_PATH.RESOURCE_CENTER,
			},
			{ id: 11, disabled: true, title: "Post to your Discovery Board", desc: <DiscoveryBoard />, route: ROUTES_PATH.RESEARCH_CURATOR_EXPAND },
		],
	},
	// {
	// 	id: 4,
	// 	title: "Need Help?",
	// 	items: [{ id: 12, disabled: true, title: "StartupOS Guided Tours", desc: <></>, route: "", disableArrow: true }],
	// },
];
