import React from "react";

import CommonSlideDialog from "../../../../common/CommonSlideDialog";

import ScorecardForm from "./ScorecardForm";

export default function CreateScoreCardModal({ open, onClose, companyIds, eventUUID, scoreCardObj, isEdit = false }) {
	return (
		<CommonSlideDialog
			open={open}
			slidingEffect={false}
			onClose={onClose}
			keepMounted={false}
			icon={
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path
						d="M14.6665 5.33333H9.0665C6.82629 5.33333 5.70619 5.33333 4.85054 5.7693C4.09789 6.15279 3.48597 6.76472 3.10248 7.51737C2.6665 8.37301 2.6665 9.49312 2.6665 11.7333V22.9333C2.6665 25.1735 2.6665 26.2936 3.10248 27.1493C3.48597 27.9019 4.09789 28.5139 4.85054 28.8974C5.70619 29.3333 6.82629 29.3333 9.0665 29.3333H20.2665C22.5067 29.3333 23.6268 29.3333 24.4825 28.8974C25.2351 28.5139 25.847 27.9019 26.2305 27.1493C26.6665 26.2936 26.6665 25.1735 26.6665 22.9333V17.3333M10.6665 21.3333H12.8992C13.5514 21.3333 13.8776 21.3333 14.1845 21.2596C14.4566 21.1943 14.7167 21.0866 14.9553 20.9404C15.2244 20.7755 15.455 20.5449 15.9162 20.0837L28.6665 7.33333C29.7711 6.22876 29.7711 4.4379 28.6665 3.33333C27.5619 2.22876 25.7711 2.22876 24.6665 3.33332L11.9162 16.0837C11.4549 16.5449 11.2243 16.7755 11.0594 17.0446C10.9132 17.2832 10.8055 17.5433 10.7401 17.8154C10.6665 18.1223 10.6665 18.4484 10.6665 19.1006V21.3333Z"
						stroke="#7B61FF"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			}
			title="Configure Voting Scorecard"
			description="Configure the voting scorecard by updating the details below!"
			titleVariant="Text/md/Semibold"
			maxWidth="md"
			btnCloseStyle={{ margin: "0 24px 0 0" }}
			PaperProps={{
				sx: {
					px: 2,
					py: 3,
					borderRadius: "30px",
					width: 1,
				},
			}}
			footerStyle={{ px: { xs: 2, md: 5 }, pb: { xs: 2, md: 2 }, pt: 0 }}
			scroll_type="paper"
		>
			{open && <ScorecardForm {...{ open, onClose, companyIds, eventUUID, scoreCardObj, isEdit }} />}
		</CommonSlideDialog>
	);
}
