import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, FormControlLabel, InputLabel, Typography, Box, Select, MenuItem, Checkbox, OutlinedInput, ListItemText } from "@mui/material";

import services from "../../../../../services";
import { setInvestorDashboardData } from "../../../../../modules/actions";
import { KeyboardArrowDown } from "@mui/icons-material";
import { Can } from "../../../../../services/abilities/context";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const CohortFilter = () => {
	const filteredValue = useSelector((state) => state?.investorDashboard?.filteredValue) || [];
	const [cohortList, setCohortList] = useState([]);
	const [isPitchApplyAccepted, setisPitchApplyAccepted] = useState(false);
	const [currentCohorts, setCurrentCohorts] = useState([]);
	const dispatch = useDispatch();
	let cohortFilter = filteredValue.filter((filteredItem) => filteredItem.type === "cohorts");
	let pitchApplyAcceptedFilter = filteredValue.filter((filteredItem) => filteredItem.type === "pitchApplyAccepted");

	useEffect(() => {
		if (cohortList.length === 0) {
			getCohortsList();
		}
	}, [cohortList]);

	useEffect(() => {
		console.log(pitchApplyAcceptedFilter);
		setisPitchApplyAccepted((prev) => {
			const newValue = pitchApplyAcceptedFilter[0]?.isPitchApplyAccepted || false;
			return prev === newValue ? prev : newValue;
		});
	}, [pitchApplyAcceptedFilter]);

	useEffect(() => {
		if (cohortList.length > 0 && cohortFilter && cohortFilter.length > 0) {
			setCurrentCohorts((prev) => {
				const newValue = cohortFilter[0]?.checkboxValue || [];
				return JSON.stringify(prev) === JSON.stringify(newValue) ? prev : newValue;
			});

			setisPitchApplyAccepted((prev) => {
				const newValue = pitchApplyAcceptedFilter[0]?.isPitchApplyAccepted || false;
				return prev === newValue ? prev : newValue;
			});
		}
	}, [cohortList, cohortFilter]);

	useEffect(() => {
		if (cohortFilter && cohortFilter?.length === 0) {
			setCurrentCohorts([]);
			setisPitchApplyAccepted(false);
		}
	}, [filteredValue]);

	const getCohortsList = async () => {
		const list = await services.getCohorts();
		setCohortList(list.data?.data || []);
	};

	const handleOnChange = (e) => {
		setCurrentCohorts(e.target.value);

		const currentCohortIds = [];
		e.target.value.forEach((item) => {
			currentCohortIds.push(cohortList.filter((cohort) => cohort.name === item)[0]?.id);
		});

		let newObj = {
			type: "cohorts",
			cohortIds: currentCohortIds,
			checkboxValue: e.target.value,
		};
		const tempFilteredValue = [...filteredValue];
		let index = tempFilteredValue.findIndex((item) => item.type === newObj.type);
		if (index !== -1) {
			tempFilteredValue[index] = newObj;
		} else {
			tempFilteredValue.push(newObj);
		}

		dispatch(
			setInvestorDashboardData({
				filteredValue: tempFilteredValue,
			})
		);
	};

	const handleIsAcceptedChange = (e) => {
		setisPitchApplyAccepted(e?.target?.checked);

		try {
			const newObj = {
				type: "pitchApplyAccepted",
				isPitchApplyAccepted: e?.target?.checked,
			};

			// Make a shallow copy of the existing array
			let tempFilteredValue = [...filteredValue];

			// Find index of the item with type "pitchApplyAccepted"
			let index = tempFilteredValue.findIndex((item) => item.type === newObj.type);

			if (index !== -1) {
				if (e?.target?.checked) {
					// If it already exists and is checked, update it
					tempFilteredValue[index] = newObj;
				} else {
					// If it exists and is unchecked, remove it
					tempFilteredValue.splice(index, 1);
				}
			} else {
				// If it does not exist and is checked, add it
				if (e?.target?.checked) {
					tempFilteredValue.push(newObj);
				}
			}

			// Dispatch the updated state
			dispatch(
				setInvestorDashboardData({
					filteredValue: tempFilteredValue,
				})
			);
		} catch (error) {
			console.log("Caught error with show only applied startups. Error:", error);
		}
	};

	return (
		<Box mb={5}>
			<Typography
				sx={{
					color: (theme) => theme.palette.secondary.black,
					width: "fit-content",
				}}
				variant="testHeading"
			>
				Cohort
			</Typography>
			<FormControl fullWidth sx={{ width: 1, mt: 2 }}>
				<Select
					labelId="Cohort"
					multiple
					fullWidth
					displayEmpty
					value={currentCohorts || []}
					onChange={handleOnChange}
					IconComponent={KeyboardArrowDown}
					input={
						<OutlinedInput
							placeholder="Select Multiple"
							sx={{
								borderRadius: "100px",
								border: "1px solid",
								borderColor: "#D0D5DD",
								"&.Mui-focused fieldset.MuiOutlinedInput-notchedOutline": {
									borderColor: "transparent", // Remove border color when focused or expanded
								},
								"& .MuiSelect-icon": {
									right: "20px",
									fontSize: "25px",
								},
								"& .MuiInputBase-input": {
									padding: "12px 20px",
									fontSize: "16px",
									fontFamily: '"PoppinsRegular", "Helvetica", "Arial", sans-serif',
								},
							}}
						/>
					}
					renderValue={(selected) => {
						if (selected?.length === 0) {
							return (
								<span
									style={{
										color: "#667085",
										fontFamily: '"PoppinsSemibold", "Helvetica", "Arial", sans-serif',
									}}
								>
									Select Multiple
								</span>
							);
						}

						return selected?.join(", ");
					}}
					MenuProps={MenuProps}
				>
					{cohortList?.map((option) => (
						<MenuItem key={option?.id} value={option?.name}>
							<Checkbox checked={currentCohorts?.indexOf(option?.name) > -1} />
							<ListItemText primary={option?.name} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<Can I="view" a="PitchApplyAcceptedFilter">
				<Box sx={{ mt: 2 }}>
					<FormControlLabel
						sx={{ width: 1, m: 0, borderRadius: "100px" }}
						control={<Checkbox checked={isPitchApplyAccepted} onChange={handleIsAcceptedChange} />}
						label={<Typography variant="Text/xs/Semibold">Show Only Accepted Startups</Typography>}
					/>
				</Box>
			</Can>
		</Box>
	);
};

export default CohortFilter;
