import { USER_TYPES } from "../../utils/userTypes";

const permissionMapping = {
	"view-opportunity-dashboard": { action: "view", subject: "OpportunityDashboard" },
	"view-pitch-apply-accepted-filter": { action: "view", subject: "PitchApplyAcceptedFilter" },
};

export function defineOpportunityDashboardAbilities(user, can, cannot) {
	// Loop over user permissions to define abilities
	user?.permissions?.forEach((permission) => {
		const mapping = permissionMapping[permission?.name];
		if (mapping) {
			can(mapping?.action, mapping?.subject);

			// If the permission is for managing, also grant viewing rights
			if (mapping.action === "manage") {
				can("view", mapping?.subject);
			}
		}

		// If user has the attribute, explicitly block them from seeing other startups in opp-dash; ie. limited to current partner code.
		if (permission?.name === "opp-hide-other-startups") {
			cannot("view", "OppOtherPartners");
		}
	});
}
