import React, { useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import HomeTourIcon from "../../components/Investor/Workspace/Assets/new-home-tour.png";
import { TourTooltip } from "../../components/Profile/ProfileContent";

export default function PageTitle({ icon, label, iconComp, showTour = true }) {
	return (
		<Stack spacing={2} direction={"row"} alignItems={"center"}>
			{icon && <Box component={"img"} src={icon} alt="dashbaord" sx={{ width: "30px", height: "30px" }} />}
			{iconComp && <Box sx={{ width: "30px", height: "30px" }}>{iconComp}</Box>}
			<Typography variant="growth_card_subtitle" color="secondary.black">
				{label}
			</Typography>
			{showTour && (
				<TourTooltip title="Click to restart the tour" placement="top-end">
					<Box sx={{ width: "50px", height: "50px", cursor: "pointer" }} component={"img"} src={HomeTourIcon} alt="tour" />
				</TourTooltip>
			)}
		</Stack>
	);
}
