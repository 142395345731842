import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData } from "../../modules/actions";
import { USER_TYPES } from "../../utils/userTypes";
import jwt_decode from "jwt-decode";
import { Backdrop, Box, CircularProgress, Container, Typography, useMediaQuery } from "@mui/material";
import { toastContainer } from "../../utils/toast";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { PPT_ICON_V1 } from "../../constants";
import BoltPitchdeckReviewSection from "../../components/Profile/Components/UpdatedPitchDeckSection/BoltPitchdeckReviewSection";
import { Card as PowerUpCard } from "../PowerPack/components/cards/Card";

export default function PitchDeckWorkspaceContainer() {
	const history = useHistory();
	const [loggingIn, setloggingIn] = useState(false);
	const queryParams = new URLSearchParams(window.location.search);
	const token = queryParams.get("pitchDeckReviewToken");
	// http://localhost:3000/pitch-deck-workspace?pitchDeckReviewToken=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqb2JJZCI6IjNjYzIwZDIyLTg1YzAtNGE0OS05MjA4LWNkNzBmZGMwMDRjMCIsIm5hbWUiOiJNaWtlIEdhcnJpc29uIiwic3RhcnR1cE5hbWUiOiJQaWxsb3dzIDQgSGFtc3RlcnMiLCJlbWFpbCI6Im1nYXJyaXNvbisxQHN0YXJ0dXBvcy5jb20iLCJpYXQiOjE3MzgyNzUyMzF9.qYskBonO_xFuYNmJwxzLgQoiMAU26dwbo_GF9mM_uXk
	const dispatch = useDispatch();
	const { pitchDeckJobId, userName, startupName, userType, email } = useSelector((state) => state?.auth);
	const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("xl"));

	useEffect(() => {
		dispatch(setAuthData({ userType: USER_TYPES.PITCHDECK_CUSTOMER }));
		processLogin();
	}, []);

	useEffect(() => {
		if (pitchDeckJobId) {
			if (loggingIn)
				setTimeout(() => {
					setloggingIn(false);
				}, 500); // Prevent loading flash on startup
		} else processLogin();
	}, [pitchDeckJobId]);

	const processLogin = () => {
		try {
			if (!pitchDeckJobId) {
				setloggingIn(true);
				if (token) {
					const decodedData = jwt_decode(token);
					if (!decodedData || !decodedData?.jobId || !decodedData?.name || !decodedData?.startupName || !decodedData?.email)
						throw "Malformed token provided -- redirecting to login.";

					dispatch(
						setAuthData({
							pitchDeckJobId: decodedData?.jobId,
							userName: decodedData?.name,
							startupName: decodedData?.startupName,
							email: decodedData?.email,
						})
					);
				} else {
					throw "No token provided -- redirecting to login.";
				}
			} else {
				setloggingIn(false);
			}
		} catch (e) {
			console.log("Error decoding JWT failed with:", e);
			dispatch(setAuthData(null));
			toastContainer("Failed to log into Pitch Deck Review", "error");
			history.replace("/login");
		}
	};

	return (
		<Fragment>
			<Backdrop
				sx={(theme) => ({ color: "#fff", zIndex: theme?.zIndex?.drawer + 1, backdropFilter: "blur(8px)" })}
				open={loggingIn}
				onClick={() => setloggingIn(false)}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<Box
				sx={(theme) => ({
					display: "flex",
					justifyContent: "center",
				})}
			>
				<Container sx={{ p: 3 }}>
					<Box
						sx={{
							my: "2.5rem",
							display: "flex",
							gap: 2,
							...(isSmallScreen && { flexWrap: "wrap" }),
						}}
					>
						<Box
							sx={{
								//maxWidth: isSmallScreen ? "auto" : "430px",
								width: "100%",
								display: "flex",
								flexDirection: "column",
								gap: 2,
							}}
						>
							<PowerUpCard>
								<Box display={"flex"} alignItems={"center"} gap={1}>
									<Box component={"img"} src={PPT_ICON_V1} alt="ppt" />
									<Typography variant="Text/md/Semibold">Your Pitch Deck Analysis History</Typography>
								</Box>
								<Typography variant="Text/sm/Regular" component={"p"}>
									Review your previous pitch deck analyses to track improvements and insights over time. Each report provides actionable
									feedback to refine your deck and strengthen your fundraising efforts. Ready to make more progress? Submit your pitch deck
									for another analysis today!
								</Typography>
								{loggingIn ? (
									<CircularProgress />
								) : (
									<BoltPitchdeckReviewSection
										profileData={{ pitchDeckJobId, userName, startupName, userType, email }}
										//setRunReportButton={(comp) => setrunPDReportButton(comp)}
									/>
								)}
							</PowerUpCard>
						</Box>
					</Box>
				</Container>
			</Box>
		</Fragment>
	);
}
