import React, { Fragment, useContext, useEffect, useState } from "react";

import { Avatar, Box, Button, CircularProgress, Dialog, List, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { ProfileContext } from "../..";
import VideoPlayerComponent from "../../../../common/videoPlayer";
import MissingAsset from "../MissingAsset";
import GridLayout from "./GridLayout";
import { VideoContainer } from "./style";
import axios from "axios";
import { Check, CheckCircle, CheckCircleOutlineRounded, ChevronRight, Close, HighlightOffRounded, InfoOutlined } from "@mui/icons-material";
import JSON5 from "json5";
import moment from "moment";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import useWebSocket from "react-use-websocket";
import useDimensions from "react-cool-dimensions";
import { USER_TYPES } from "../../../../utils/userTypes";

const formatDate = (utcString) => {
	const date = moment.utc(utcString);

	if (date.isSame(moment(), "day")) {
		return date.local().format("[Today at] h:mma");
	} else {
		return date.local().format("M/DD/YY [at] h:mma");
	}
};

function BoltPitchdeckReviewSection({ setRunReportButton, profileData: propProfileData }) {
	const context = useContext(ProfileContext);
	const contextProfileData = context ? context.profileData : undefined;

	// Use the prop value if provided; otherwise, fallback to the context value
	const profileData = propProfileData || contextProfileData;

	const [selectedReport, setselectedReport] = useState(null);
	const [selectedReportID, setselectedReportID] = useState(null);
	const [mostRecentStarted, setMostRecentStarted] = useState(null);
	const [mostRecentFailed, setMostRecentFailed] = useState(null);
	const [completedJobs, setCompletedJobs] = useState([]);
	const [jobsLoading, setjobsLoading] = useState(false);
	const [jobRequestedLoading, setjobRequestedLoading] = useState(false);
	const [reportLoading, setReportLoading] = useState(null);
	const authDetails = useSelector((state) => state?.auth);
	const decoded = authDetails?.idToken ? jwtDecode(authDetails?.idToken) : {};

	const [isSmallWidth, setIsSmallWidth] = useState(false);
	const { observe, unobserve, width, height, entry } = useDimensions({
		breakpoints: { SM: 650 },
		updateOnBreakpointChange: true,
	});

	useEffect(() => {
		if (width >= 650) {
			setIsSmallWidth(false);
		} else {
			setIsSmallWidth(true);
		}
	}, [width]);

	const { sendJsonMessage, lastJsonMessage, readyState } = useWebSocket(
		`${process.env.REACT_APP_BOLT_WEBSOCKET_BASE}?access_token=${encodeURI(`Bearer ${authDetails?.idToken}`)}`,
		{
			share: true, // Enable the share option to use a single WebSocket connection
			shouldReconnect: (closeEvent) => true, // Function to determine whether to attempt reconnection
			retryOnError: true, // Enable automatic retry on connection errors
			reconnectInterval: 5000, // Interval (in milliseconds) between reconnection attempts
		}
	);

	// Function to handle incoming messages with a 'notificationPayload'
	const handleNotification = (message) => {
		if (message?.job_status == "COMPLETED" || message?.job_status == "FAILED") {
			getReportList();
		}
	};

	useEffect(() => {
		// Check if there's a last JSON message and it has a 'notificationPayload'
		if (lastJsonMessage && lastJsonMessage.notificationPayload) {
			handleNotification(lastJsonMessage.notificationPayload);
		}
	}, [lastJsonMessage]);

	useEffect(() => {
		observe();
		getReportList();
		if (typeof setRunReportButton == "function")
			setRunReportButton(
				<Button
					variant="DS1"
					color="primary"
					disabled={jobRequestedLoading || mostRecentStarted || jobsLoading || !profileData?.pitchDeckLink}
					onClick={() => runReport()}
				>
					{(jobRequestedLoading || mostRecentStarted || jobsLoading) && <CircularProgress size={"24px"} sx={{ color: "white" }} />} Start Analysis
				</Button>
			);
	}, []);

	useEffect(() => {
		if (typeof setRunReportButton == "function")
			setRunReportButton(
				<Button
					variant="DS1"
					color="primary"
					disabled={jobRequestedLoading || mostRecentStarted || jobsLoading || !profileData?.pitchDeckLink}
					onClick={() => runReport()}
				>
					{jobRequestedLoading && <CircularProgress size={"24px"} sx={{ color: "white" }} />} Start Analysis
				</Button>
			);
	}, [jobRequestedLoading, mostRecentStarted, jobsLoading]);

	useEffect(() => {
		if (!selectedReport) setReportLoading(null);
	}, [selectedReport]);

	const getReportList = async () => {
		setjobsLoading(true);

		const result = await axios
			.get(`${process.env.REACT_APP_AWS_BOLT_AI_PD_REVIEW}`, {
				params: {
					...(authDetails?.userId && { user_id: authDetails?.userId }),
					...(authDetails?.userType == USER_TYPES.PITCHDECK_CUSTOMER && {
						external_email: authDetails?.email,
					}),
				},
			})
			.then((resp) => {
				try {
					const data = resp.data;

					// Separate the jobs by status
					const completedJobsList = data.filter((job) => job.job_status === "COMPLETED");
					const startedJobs = data.filter((job) => job.job_status === "STARTED" || job.job_status === "RUNNING");
					const failedJobs = data.filter((job) => job.job_status === "FAILED");

					// Get the most recent STARTED job based on start_time
					let mostRecentStartedJob = startedJobs.reduce((mostRecent, job) => {
						return !mostRecent || new Date(job.start_time) > new Date(mostRecent.start_time) ? job : mostRecent;
					}, null);

					// Check if the most recent STARTED job was started more than 8 minutes ago
					const currentTime = new Date(); // Current time in system timezone
					if (mostRecentStartedJob) {
						const startTime = new Date(mostRecentStartedJob.start_time); // Parse UTC time to Date
						const currentTimeUTC = new Date(
							currentTime.getUTCFullYear(),
							currentTime.getUTCMonth(),
							currentTime.getUTCDate(),
							currentTime.getUTCHours(),
							currentTime.getUTCMinutes(),
							currentTime.getUTCSeconds()
						);
						const timeDifference = (currentTimeUTC.getTime() - startTime.getTime()) / (1000 * 60); // Difference in minutes

						if (timeDifference > 20) {
							mostRecentStartedJob = null;
						}
					}

					setMostRecentStarted(mostRecentStartedJob);

					// Get the most recent FAILED job based on complete_time
					let mostRecentFailedJob = failedJobs.reduce((mostRecent, job) => {
						return !mostRecent || new Date(job.complete_time) > new Date(mostRecent.complete_time) ? job : mostRecent;
					}, null);

					// Check if the most recent FAILED job has a later complete_time than any COMPLETED job
					const latestCompletedJob = completedJobsList.reduce((latest, job) => {
						return !latest || new Date(job.complete_time) > new Date(latest.complete_time) ? job : latest;
					}, null);

					if (mostRecentFailedJob && latestCompletedJob) {
						if (new Date(mostRecentFailedJob.complete_time) <= new Date(latestCompletedJob.complete_time)) {
							mostRecentFailedJob = null;
						}
					}

					if (!mostRecentStartedJob) {
						setMostRecentFailed(mostRecentFailedJob);
					} else {
						setMostRecentFailed(null);
					}

					// Sort COMPLETED jobs from latest to oldest based on complete_time
					const sortedCompletedJobs = completedJobsList.sort((a, b) => new Date(b.complete_time) - new Date(a.complete_time));

					setCompletedJobs(sortedCompletedJobs);
				} catch (e) {
					console.log("Failed to process job list. Failed with error:", e);
				}
			})
			.catch((e) => {
				console.log("Failed to get previous Bolt AI PD reports. Failed with error:", e);
			})
			.finally(() => {
				setjobsLoading(false);
			});
	};

	const getReportPayload = async (report_url, job_id, job_type) => {
		setReportLoading(job_id);

		const fetchAndSetReport = async (url, isJson = false) => {
			try {
				const resp = await axios.get(url);
				if (isJson && typeof resp?.data === "string") {
					setselectedReport(JSON5.parse(resp.data));
				} else {
					setselectedReport(resp?.data);
				}
				setselectedReportID(job_id);
			} catch (error) {
				console.log("Failed to fetch report. Error:", error);
			} finally {
				setReportLoading(null);
			}
		};

		const defaultPDFUrl = `${process.env.REACT_APP_BOLT_AI_PD_CLOUDFRONT_URL}/${job_id}/${job_id}_bolt_pd_review.pdf`;
		const defaultJSONUrl = `${process.env.REACT_APP_BOLT_AI_PD_CLOUDFRONT_URL}/${job_id}/${job_id}_report.json`;

		try {
			if ((job_type === "pdf" || job_type === "all") && report_url) {
				setselectedReport({ pdf_link: report_url });
				setselectedReportID(job_id);
			} else if (job_type === "json" && report_url) {
				await fetchAndSetReport(report_url, true);
			} else {
				const isPdf = await fetch(report_url || defaultPDFUrl, { method: "HEAD" })
					.then((resp) => {
						if (resp?.ok) return true;
						else return false;
					})
					.catch((e) => {
						console.log("Error in PDF check: ", e);
						return false;
					});
				console.log("IS PDF???", isPdf);
				if (isPdf) {
					setselectedReport({ pdf_link: report_url || defaultPDFUrl });
				} else {
					await fetchAndSetReport(defaultJSONUrl, true);
				}
			}
		} catch (error) {
			console.error("Error in getReportPayload:", error);
		} finally {
			setReportLoading(null);
		}
	};

	const deleteReport = async () => {
		if (!selectedReportID) {
			setselectedReport(null);
			setselectedReportID(null);
		} else {
			const report_id = selectedReportID;
			setselectedReportID(null);
			setselectedReport(null);

			setjobsLoading(true);

			const result = await axios
				.delete(`${process.env.REACT_APP_AWS_BOLT_AI_PD_REVIEW}?user_id=${authDetails?.userId}&job_id=${report_id}`)
				.catch((e) => {
					console.log("Failed to delete Bolt PD Report. Failed with error:", e);
					setjobsLoading(false);
				})
				.then(() => {
					getReportList();
				});
		}
	};

	const JobItem = ({ item }) => (
		<ListItem
			onClick={() => {
				console.log("CLICKED!:", item);
				if (item?.job_status == "COMPLETED" && !reportLoading) getReportPayload(item?.report_url, item?.job_id, item?.report_type);
			}}
			sx={{
				borderRadius: "12px",
				border: "1px solid var(--Gray-100, #F2F4F7)",
				backgroundColor: "#FCFCFD",
				...(item?.job_status == "COMPLETED" &&
					!reportLoading && {
						cursor: "pointer",
						transition: "all .2s",
						"&:hover": {
							backgroundColor: "#F2F4F7",
						},
					}),
			}}
			secondaryAction={
				item?.job_status == "COMPLETED" && (
					<Avatar sx={{ background: "#F2F4F7", boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)" }}>
						{reportLoading == item?.job_id ? <CircularProgress size={"24px"} /> : <ChevronRight size={"24px"} sx={{ color: "#344054" }} />}
					</Avatar>
				)
			}
		>
			<ListItemAvatar>
				<Avatar
					sx={{
						...(item?.job_status == "COMPLETED" && {
							background: "#D1FADF",
						}),
						...(item?.job_status == "FAILED" && {
							background: "#FEE4E2",
						}),
					}}
				>
					{item?.job_status == "COMPLETED" && <CheckCircleOutlineRounded sx={{ color: "#039855" }} />}
					{item?.job_status == "FAILED" && <HighlightOffRounded sx={{ color: "#F04438" }} />}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				primary={
					<Typography component={"p"} variant="Text/sm/Semibold">
						{item?.job_status == "LOADING-JOBS" && "Checking for previous reports..."}
						{item?.job_status == "STARTED" && "Reviewing Pitch Deck"}
						{item?.job_status == "COMPLETED" && "Report Ready"}
						{item?.job_status == "FAILED" && "Failed"}
					</Typography>
				}
				secondary={
					<Typography component={"p"} variant="Text/xs/Regular">
						{item?.job_status == "FAILED" && !item?.complete_time
							? "Previous request for a Bolt AI pitch deck review had failed. Please try again."
							: item?.job_status != "STARTED" && formatDate(item?.complete_time)}
					</Typography>
				}
			/>
		</ListItem>
	);

	const ReportSelector = () => {
		return (
			<Box sx={{ height: "500px", overflowY: "scroll", width: "100%" }}>
				{jobsLoading ? (
					<Box
						sx={{ display: "flex", flex: 1, height: "100%", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "20px" }}
					>
						<CircularProgress />
						<Typography variant="Text/sm/Regular">Checking for previous reports...</Typography>
					</Box>
				) : mostRecentStarted ? (
					<Box
						sx={{
							display: "flex",
							flex: 1,

							height: "100%",
							justifyContent: "center",
							alignItems: "center",
							flexDirection: "column",
							gap: "20px",
						}}
					>
						<CircularProgress />
						<Typography variant="Text/sm/Regular">Reviewing Pitch Deck...</Typography>
						<Typography variant="Text/sm/Regular" sx={{ color: "#98A2B3", maxWidth: "380px", textAlign: "center" }}>
							Bolt is currently reviewing your pitch deck. Bolt will notify you once complete. Feel free to check back later.
						</Typography>
					</Box>
				) : (
					<List
						sx={{
							li: {
								mt: "8px",
							},
							"li:first-child": {
								mt: "0px",
							},
						}}
					>
						{mostRecentFailed && <JobItem item={mostRecentFailed} />}

						{completedJobs?.length > 0 ? (
							completedJobs.map((item, ind) => <JobItem item={item} />)
						) : (
							<ListItem>
								<Typography variant="Text/sm/Regular" align="center" component={"p"} sx={{ width: "100%" }}>
									No Reports Available
								</Typography>
							</ListItem>
						)}
					</List>
				)}
			</Box>
		);
	};

	const runReport = async () => {
		setjobRequestedLoading(true);
		const result = await axios
			.post(process.env.REACT_APP_AWS_BOLT_AI_PD_REVIEW, {
				user_id: authDetails?.userId,
				auth0_id: decoded?.sub,
				pitch_deck_link: profileData?.pitchDeckLink,
				startup_name: profileData?.companyName,
			})
			.then((resp) => {
				setTimeout(() => {
					getReportList();
				}, "1000");
				setjobRequestedLoading(false);
			})
			.catch((e) => {
				setjobRequestedLoading(false);
				console.log("Failed to request report. Failed with error:", e);
			});
	};

	const ReviewReportItem = ({ item }) => (
		<Box sx={{ display: "flex", flex: 1, gap: 3, flexWrap: "wrap" }}>
			<Box component={"img"} src={`${process.env.REACT_APP_BOLT_AI_PD_CLOUDFRONT_URL}/${item?.image_key}`} sx={{ maxHeight: "180px" }} />
			<Box sx={{ display: "flex", flex: 1, gap: 2, flexWrap: "wrap" }}>
				<Box
					sx={{
						borderRadius: "12px",
						border: "1px solid #F2F4F7",
						background: "#FCFCFD",
						display: "flex",
						flex: 1,
						gap: 2,
						p: 2,
					}}
				>
					<Avatar sx={{ background: "#DED7FF" }}>
						<InfoOutlined sx={{ color: "#7B61FF" }} />
					</Avatar>
					<Box>
						<Typography component={"p"} variant="Text/xs/Semibold" sx={{ mb: "8px" }}>
							Overview
						</Typography>
						<Typography component={"p"} sx={{ color: "#475467" }} variant="Text/xs/Regular">
							{item?.bolt_report?.review}
						</Typography>
					</Box>
				</Box>

				<Box
					sx={{
						borderRadius: "12px",
						border: "1px solid #F2F4F7",
						background: "#FCFCFD",
						display: "flex",
						flex: 1,
						gap: 2,
						p: 2,
					}}
				>
					<Avatar sx={{ background: "#D1FADF" }}>
						<CheckCircleOutlineRounded sx={{ color: "#00CE7E" }} />
					</Avatar>
					<Box>
						<Typography component={"p"} variant="Text/xs/Semibold" sx={{ mb: "8px" }}>
							Suggested Improvements
						</Typography>
						<Typography component={"p"} sx={{ color: "#475467" }} variant="Text/xs/Regular">
							{item?.bolt_report?.suggested_improvements}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);

	return (
		<Fragment>
			<ReportSelector />
			<Dialog
				ref={observe}
				maxWidth={"lg"}
				open={selectedReport}
				onClose={() => {
					setselectedReport(null);
					setselectedReportID(null);
				}}
				PaperProps={{
					sx: {
						borderRadius: "20px",
						p: 4,
						width: "100%",
					},
				}}
			>
				<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
					<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
						<path
							d="M5.00008 28.3334V21.6667M5.00008 8.33341V1.66675M1.66675 5.00008H8.33341M1.66675 25.0001H8.33341M16.3334 3.00008L14.0212 9.0119C13.6452 9.98954 13.4572 10.4784 13.1648 10.8895C12.9057 11.2539 12.5873 11.5723 12.2229 11.8315C11.8117 12.1238 11.3229 12.3118 10.3452 12.6878L4.33341 15.0001L10.3452 17.3123C11.3229 17.6883 11.8117 17.8763 12.2229 18.1687C12.5873 18.4278 12.9057 18.7462 13.1648 19.1106C13.4572 19.5218 13.6452 20.0106 14.0212 20.9883L16.3334 27.0001L18.6457 20.9883C19.0217 20.0106 19.2097 19.5218 19.502 19.1106C19.7612 18.7462 20.0796 18.4278 20.444 18.1687C20.8551 17.8763 21.344 17.6883 22.3216 17.3123L28.3334 15.0001L22.3216 12.6878C21.344 12.3118 20.8551 12.1238 20.444 11.8315C20.0796 11.5723 19.7612 11.2539 19.502 10.8895C19.2097 10.4784 19.0217 9.98954 18.6457 9.0119L16.3334 3.00008Z"
							stroke="#7B61FF"
							stroke-width="2"
							stroke-linecap="round"
							stroke-linejoin="round"
						/>
					</svg>

					<Button
						variant="DS1"
						color="clear"
						iconButton={true}
						small={true}
						onClick={() => {
							setselectedReport(null);
							setselectedReportID(null);
						}}
					>
						<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
							<path d="M24 8L8 24M8 8L24 24" stroke="#98A2B3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
						</svg>
					</Button>
				</Box>
				<Box sx={{ mt: "12px" }}>
					<Typography variant="Text/md/Semibold">Bolt AI Pitch Deck Review</Typography>
				</Box>

				{selectedReport && selectedReport?.pdf_link ? (
					<Box sx={{ mt: "12px" }}>
						<iframe
							src={selectedReport?.pdf_link}
							id="iframe-pdf"
							frameBorder="0"
							style={{
								width: "100%",
								height: isSmallWidth ? "600px" : "800px",
								borderRadius: "30px",
								border: "none",
								margin: 0,
								padding: 0,
								overflow: "hidden",
							}}
						></iframe>
					</Box>
				) : (
					<Fragment>
						<Box sx={{ mt: "12px" }}>
							Bolt has reviewed each slide of your pitch deck presentation. See below for Bolt's critiques and suggested improvements you could
							make to increase the performance of your presentation.
						</Box>
						<Box sx={{ mt: 3, display: "flex", flexDirection: "column", gap: 3 }}>
							{Array.isArray(selectedReport) && selectedReport?.map((item, ind) => <ReviewReportItem item={item} />)}
						</Box>
					</Fragment>
				)}

				<Box sx={{ mt: "12px", display: "flex", alignItems: "center", justifyContent: "flex-end", gap: "8px" }}>
					<Button
						variant="DS1"
						onClick={() => {
							deleteReport();
						}}
						color="destructive"
					>
						Delete Report
					</Button>
					<Button
						variant="DS1"
						onClick={() => {
							setselectedReport(null);
							setselectedReportID(null);
						}}
						color="primary"
					>
						Close
					</Button>
				</Box>
			</Dialog>
		</Fragment>
	);
}

export default BoltPitchdeckReviewSection;
