import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MessageHandlerModal from "../modal/MessageHandler";
import { dismissAccount406Modal } from "../../modules/actions";
import { useHistory } from "react-router-dom";
import ROUTES_PATH from "../../constants/routes";

function AppOverlayMessage() {
	const [showMessage, setshowMessage] = useState(false);
	const [messageContent, setmessageContent] = useState("");
	const { show, message } = useSelector((state) => state?.accountApprovalOverlay);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		setshowMessage(show);
		setmessageContent(message);
	}, [show]);

	const handleClose = () => {
		setshowMessage(false);
		dispatch(dismissAccount406Modal());
		history.push("/");
	};

	return (
		<MessageHandlerModal
			isOpen={showMessage}
			onOk={() => {
				handleClose();
			}}
			onCancel={() => {
				handleClose();
			}}
			okText="Return to Profile"
			heading={`Heads Up`}
			text={messageContent}
			messageType="primary"
			icon={
				<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
					<path
						d="M16 10.6667V16M16 21.3333H16.0133M10.4 28H21.6C23.8402 28 24.9603 28 25.816 27.564C26.5686 27.1805 27.1805 26.5686 27.564 25.816C28 24.9603 28 23.8402 28 21.6V10.4C28 8.15979 28 7.03969 27.564 6.18404C27.1805 5.43139 26.5686 4.81947 25.816 4.43597C24.9603 4 23.8402 4 21.6 4H10.4C8.15979 4 7.03969 4 6.18404 4.43597C5.43139 4.81947 4.81947 5.43139 4.43597 6.18404C4 7.03969 4 8.15979 4 10.4V21.6C4 23.8402 4 24.9603 4.43597 25.816C4.81947 26.5686 5.43139 27.1805 6.18404 27.564C7.03969 28 8.15979 28 10.4 28Z"
						stroke="#7B61FF"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</svg>
			}
		/>
	);
}

export default AppOverlayMessage;
