export const ROUTES_PATH = {
	APP_WEB_URL: "https://startupos.com",
	HOME_PAGE_URL: process.env.REACT_APP_HOME_URL,
	SIGN_UP: "/signup",
	LOG_IN: "/login",
	LOG_IN_NEW: "/login-new",
	CREATE_ACCOUNT: "/create-account",
	EMAIL_VERIFICATION: "/email-verification",
	SOCIAL_LOGIN: "/social-signup",
	ONBOARDING: "/onboarding/:step",
	FORGOT_PASSWORD: "/forgot-password",
	CREATE_PASSWORD: "/create-password",
	RESEND_EMAIL: "/resend-email",
	AUTHORIZE: "/authorize",
	ONBOARDING_BASEPATH: "/onboarding",
	AUTHORIZE_SUCCESS: "/success",
	DASHBOARD: "/dashboard",
	REQUEST_FORM: "/request-form",
	INVALID_EMAIL: "/invalid-email",
	REQUEST_SUBMITTED: "/request-submitted",
	TELL_US_MORE: "/tell-us-more",
	ACCOUNT_NOT_FOUND: "/account-not-found",
	VERIFY_EMAIL: "/verify-email",
	INVALID_INVITE: "/invalid-invite",
	WORKSPACE: "/home",
	OLD_WORKSPACE: "/old-workspace",
	FOUNDER_WORKSPACE: "/home",
	WORKSPACE_POWERPACKS: "/home",
	WORKSPACE_POWERUPS: "/home",
	WORKSPACE_PLUGINS: "/workspace/plugins",
	WORKSPACE_WORK_HISTORY: "/workspace/work-history",

	POWERPACK: "/powerpack/:id",
	VALUE_PROPOSITON: "/value-proposition",
	VALUE_PROPOSITON_ADD: "/value-proposition-add",
	FRAME_YOUR_STATEMENT: "/frame-your-statement",

	//network
	NETWORK: "/network",
	PAYWALL: "/paywall",

	ONBOARDING_COMPANY_NAME: "/onboarding/company-name",
	ONBOARDING_LOCATION: "/onboarding/location",
	ONBOARDING_PRODUCT_SERVICE: "/onboarding/product-service",
	ONBOARDING_STARTUP_JOURNEY: "/onboarding/startup-journey",
	ONBOARDING_FOUNDER_EXPERIENCE_LEVEL: "/onboarding/experience-level",
	ONBOARDING_FOUNDER_SUPERPOWER: "/onboarding/founder-super-power",
	ONBOARDING_TIME_COMMITMENT: "/onboarding/time-commitment",
	STARTUP_MYPROFILE: "/mystartup/myprofile/:id?",
	STARTUP_MYWORK: "/workspace/mywork",
	MVP_PRIORITIZER: "/workspace/mvp-prioritizer",
	PERSONA_BUILDER: "/workspace/persona-builder",
	WORKSPACE_POWERUP_DETAILS: "/workspace/powerUp/:id",
	WORKSPACE_POWERPACK_DETAILS: "/workspace/powerPack/:id",

	//testing route
	WORKSPACE_POWERUP_TESTING_DETAILS: "/workspace/dev/:id",

	WORKSPACE_POWERUP_BUILDER_DETAILS: "/partner/powerup/:id/preview",
	STORYTELLER_POWERUP_SUMMARY: "/workspace/storyteller/summary/:moduleId/:versionId",
	VALUEPROP_POWERUP_SUMMARY: "/workspace/value-prop/summary/:moduleId/:versionId",
	PERSONA_BUILDER_POWERUP_SUMMARY: "/workspace/persona-builder/:moduleId/:versionId/:type",
	TAMSAMSOM_POWERUP_SUMMARY: "/workspace/tamsamsom/summary",
	REVENUW_MODELER_POWERUP_SUMMARY: "/workspace/revenuemodeler/summary",
	WORKSPACE_POWERUP_EXCERCISE: "/workspace/powerup/:moduleUuid/:id/:version/:excercise",
	TAM_SAM_SOM_POWERUP: "/workspace/powerup/md-tam/:id/:version/:excercise",
	IDEA_VALIDATION_INTERVIEW: "/workspace/powerup/4",
	IDEA_VALIDATION_INTERVIEW_POWERUP: "/workspace/powerup/md-ideavalidation/:id/:version/:excercise",
	INITIAL_PERSONNA_BUILDER_POWERUP: "/workspace/powerup/md-persona/:id/:version/:excercise",
	REVENUE_MODLER: "/workspace/powerup/md-revenue/:id/:version/:excercise",
	BRANDSTORMING_POWERUP: "/workspace/powerup/md-brandstorm/:id/:version/:excercise",
	BRANDSTORMING_SUMMARY: "/workspace/brandstorming/summary",
	PROBLEM_SOLUTION_SUMMARY: "/workspace/problem-solution/summary",
	INTERVIEWING_YOUR_CANDIDATE: "/workspace/powerup/4/interviewing-your-candidate",

	AGGREGATE_NOTES: "/workspace/powerup/4/aggregate-notes",
	BROWSE_INTERVIEW_CANDIDATES: "/workspace/powerup/4/browse-candidates/:id/:version/browse",
	//BROWSE_INTERVIEW_CANDIDATES: "/workspace/powerup/4/browse-candidates",
	PROBLEM_SOLUTION_POWERUP: "/workspace/powerup/md-prob-sol/:id/:version/:excercise",
	MENTOR_CHECKIN_DETAILS: "/workspace/plugin/mentor-check-in",
	MARKET_TEST_EXERCISE: "/workspace/market-test/:id",
	CANDIDATE_DETAILS: "/workspace/browse-candidates/view-profile/:id",
	MARKET_TEST: "/workspace/market-test",

	PITCH_DECK_COMPILER: "/workspace/powerUp/64",

	MENTOR_DASHBOARD: "/mentor/dashboard",
	MENTOR_NETWORK: "/mentor/network",
	INSTRUCTOR_NETWORK: "/instructor/network",
	MENTOR_PROFILE: "/profile/:id?",
	FOUNDER_PROFILE: "/profile/:id?",
	MENTOR_NETWORK_STARTUP_PROFILE: "/mentor/network/startup-profile/:id",

	ADVISOR_DASHBOARD: "/advisor/dashboard",
	INVESTOR_DASHBOARD: "/investor/dashboard",
	INVESTOR_WORKSPACE_STARTUP: "/investor/workspace/startups",
	INVESTOR_WORKSPACE: "/home",

	FOUNDER_DASHBOARD: "/founder/dashboard",

	// Partner
	PARTNER_DASHBOARD: "/partner/dashboard",
	PARTNER_POWERUPS: "/partner/powerups",
	PARTNER_POWERUP: "/partner/powerup/:id/edit",
	PARTNER_POWERUP_PREVIEW: "/partner/powerup/:id/preview",
	PARTNER_POWERUP_VIEW: "/partner/powerup/:id",
	PARTNER_POWERUP_SUBMISSION: "/partner/powerup/:id/submission/:sid",
	PARTNER_ADMIN_DASHBOARD: "/partner/admin",
	PARTNER_ADMIN_QA: "/partner/admin-qa",

	INSTRUCTOR_DASHBOARD: "/instructor/dashboard",
	STUDENT_DASHBOARD: "/student/dashboard",

	ADVISOR_NETWORK: "/advisor/network",
	ADVISOR_PROFILE: "/advisor/profile",
	ADVISOR_NETWORK_STARTUP_PROFILE: "/advisor/network/startup-profile/:id",
	JOIN: "/join",
	MENTORS: "/mentors",
	ADVISORS: "/advisors",

	CANDIDATE_DASHBOARD: "/candidate/dashboard",
	CANDIDATE_PROFILE: "/candidate/profile",
	EXTERNAL_CANDIDATE_DASHBOARD: "/external-dashboard",
	MYPROFILE_CANDIDATE: "/candidate/myprofile",
	STATIC_SUMMARY: "/workspace/mywork/:id/summary",
	CHANGE_EMAIL: "/change-email",
	RESEARCH_CURATOR_EXPAND: "/discovery-board-expand",
	CREATE_RESEARCH_CURATOR: "/create-discovery-item",
	CREATE_QUICK_NOTE: "/create-quick-note",
	QUICK_NOTE_DETAILS: "/quick-note-details",

	RESEARCH_CURATOR_NOTE: "/discovery-board/note/:id",
	TELL_US_ABOUT_YOURSELF: "/new-onboarding/tell-us-about-yourself",
	TELL_US_ABOUT_STARTUP: "/new-onboarding/tell-us-about-startup",

	WHO_ARE_YOU: "/new-onboarding/who-are-you",
	WHAT_BRINGS_YOU_HERE: "/new-onboarding/what-brings-you-here",
	STARTUPS_YOU_HELP: "/new-onboarding/startups-you-help",
	YOUR_SUPERPOWER: "/new-onboarding/your-superpower",
	HOW_MANY_DEALS: "/new-onboarding/how-many-deals-in-year",
	INDUSTRY_FOCUS: "/new-onboarding/industry-focus",
	WHAT_TYPE_OF_INVESTOR: "/new-onboarding/what-type-of-investor",
	WHATS_YOUR_EXPERTISE: "/new-onboarding/whats-your-expertise",
	FIRST_THING_YOU_SEE_IN_STARTUP: "/new-onboarding/what-is-first-thing-look-in-startup",
	WHAT_IS_INVESTMENT_RANGE: "/new-onboarding/what-is-your-investment-range",
	RECIEVED_FUNDING: "/new-onboarding/recieved-funding",
	ARE_YOU_AFFILIATE: "/new-onboarding/are-you-affiliate",
	SKILLS_AND_EXPERTISE: "/new-onboarding/skills-and-expertise",

	SCHEDULAR_AVAILABILITY: "/schedular/availability",
	MEETINGS_BASEPATH: "/meetings",
	BOOKED_MEETING_LIST: "/meetings/bookings",
	SCHEDULED_MEETING: "/meetings/bookings/scheduled/:id",
	MEETING_DETAILS_FORM: "/schedular/schedule-details",
	SCHEDULE_MEETING_CALENDAR: "/schedular/meeting-calendar/:id",
	MEETING_DETAILS_FORM: "/schedular/schedule-details",
	MEETING_TYPES_SCHEDULE: "/meeting-types-schedule",
	MEETING_SCHEDULED: "/meetings/meeting-scheduled/:id",
	MESSAGES: "/messages",
	LOG_IN_TEMP: "/login-backdoor",
	MVP_PRIORITIZER: "/mvp-prioritizer",
	POWERUP_PERSONA_BUILDER: "/powerup-persona-builder",
	CREATE_MATRIX: "/create-matrix",
	POWERUP_CONTROLLER: "/workspace/power-ups",
	TOOL_CONTROLLER: "/workspace/tool/:id",

	MY_STARTUP_FORM: "/my-startup-form",
	EVENTS_BASEPATH: "/events",
	EVENTS_DETAILS: "/events/:id",
	PARTNER_BASEPATH: "/partners",

	SURVEY: "/survey/:id",
	VOUCH: "/vouch",
	VOUNCH_POWERUP: "/partner/powerup/100026/preview",

	// Admin
	IMPERSONATE: "/user-support-access",
	ADMIN: "/admin-login",

	SCOUTING_REPORT: "/scouting-report",

	// Notifications
	NOTIFICATIONSLIST: "/my-notifications",

	STARTUP_PROFILE: "/startup-profile/:id",
	MY_STARTUP_PROFILE: "/startup-profile",
	EDIT_MY_STARTUP_PROFILE: "/startup-profile/edit",

	ACCOUNT_PROFILE: "/account-profile/:id",
	MY_ACCOUNT_PROFILE: "/account-profile",
	EDIT_MY_ACCOUNT_PROFILE: "/account-profile/edit",

	// Public Profile
	STARTUP_PUBLIC_PROFILE: "/startup/:uuid",
	ACCOUNT_PUBLIC_PROFILE: "/account/:uuid",

	PUBLIC_LEAN_CANVAS: "/public/powerups/:id",

	STARTUPS_HOME: "/home",
	STARTUPS_HOME_POWERUP_LIBRARY: "/home/powerups",
	STARTUPS_HOME_WORK_HISTORY: "/home/work-history",
	FEED_PATH: "/feed",
	FEED_COMMENT_PATH: "/feed/:id",

	BIDASHBOARD: "/bidashboard",
	RESOURCE_CENTER: "/resource-center",

	// New onboarding
	ONBOARD: "/onboard",
	ONBOARD_TEAMMATE: "/teammate",
	NEW_ONBOARD: "/new-onboard",
	NEW_ONBOARD_CUSTOMIZE: "/signup-detail",
	STARTUPGRID: "/startupgrid",
	STARTUPGRID_BY_ID: "/startupgrid/:id",

	MENTOR_PROFILE_NEW: "/mentor-profile/:id?",
	MY_MENTOR_PROFILE: "/mentor-profile",
	MENTOR_HOME: "/mentor-home",
	NEW_HOME_GET_STARTED: "/dev-home",

	EVENT_DASHBOARD: "/event/dashboard",
	POWERPACK_DASHBOARD: "/admin-powerpack",

	QUICK_SIGHT: "/stats",

	ADMIN_POWERPACK_DASHBOARD: "/admin-powerpack/dashboard",
	ADMIN_POWERPACK_ADD: "/admin-powerpack/add",
	ADMIN_POWERPACK_EDIT: "/admin-powerpack/edit",
	ADMIN_POWERPACK_SETTINGS: "/admin-powerpack/settings",

	ADMIN_COMPANY_USERS_IMPORT: "/admin-company-users-import",

	ADMIN_FORM_MANAGER: "/admin-form-manager",

	PITCHDECK_WORKSPACE_ROOT: "/pitch-deck-workspace",

	PITCHDECK_WORKSPACE_HOME: "/pitch-deck-workspace/home",

	VOTING_SCORECARD: "/scorecard",
};

export default ROUTES_PATH;
