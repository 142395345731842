import axios from "axios";
import * as actions from "./modules/actions";
import { getToken } from "./utils/auth0-spa";
import ROUTES_PATH from "./constants/routes";

let store;

export const createInstanceandInjectStore = (_store) => {
	store = _store;

	const instance = axios.create({
		baseURL: `${window.location.protocol}//`,
	});

	instance.all = axios.all;
	instance.spread = axios.spread;

	axios.interceptors.request.use(
		(config) => {
			const token = store?.getState()?.auth?.idToken;
			if (token) {
				config.headers["Authorization"] = "Bearer " + token;
				if (store?.getState()?.auth?.isAdmin && store?.getState()?.auth?.impersonateProviderId) {
					config.headers["impersonateUser"] = store?.getState()?.auth?.impersonateProviderId;
				}
			}

			if (config.url.includes(`${process.env.REACT_APP_VOUCH_ENV}.vouch.us`) && store?.getState()?.auth?.vouchToken) {
				config.headers["Authorization"] = "Bearer " + store?.getState()?.auth?.vouchToken?.access_token;
			}

			return config;
		},
		(error) => {
			Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		(response) => {
			return response;
		},
		async function (error) {
			const originalRequest = error.config;
			if (error.response.status === 401) {
				return getToken({
					detailedResponse: true,
				})
					.then((value) => {
						originalRequest._retry = true;

						store?.dispatch(actions.setAuthData({ idToken: value?.id_token }));

						originalRequest.headers["Authorization"] = "Bearer " + value?.id_token;
						if (store?.getState()?.auth?.isAdmin && store?.getState()?.auth?.impersonateProviderId) {
							originalRequest.headers["impersonateUser"] = store?.getState()?.auth?.impersonateProviderId;
						}

						return instance(originalRequest);
					})
					.catch((e) => {
						console.log(e);
					});
			} else if (error.response.status === 406) {
				if (error?.response?.data?.message?.includes("Access denied. Your account is currently under review and requires approval."))
					if (window?.location?.pathname !== ROUTES_PATH.MY_MENTOR_PROFILE) {
						store.dispatch(
							actions.showAccount406Modal({
								show: true,
								message: "Your account is currently under review and requires approval to access this feature.",
							})
						);
					} else {
						store.dispatch(actions.dismissAccount406Modal());
					}
			}
			return Promise.reject(error);
		}
	);
	return instance;
};
