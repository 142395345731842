import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Button, IconButton, Typography, useMediaQuery, Link } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import CommonSlideDialog from "../../../common/CommonSlideDialog";
import theme from "../../../constants/Theme";
import services from "../../../services";
import { handleMixPanel } from "../../../utils/mixPanelEventHandling";
import CircleDisabledIcon from "./check-circle-disabled.svg";
import CircleIcon from "./check-circle.svg";
import { Accordion, AccordionDetails, AccordionSummary } from ".";
import { PLATFORM_JOURNEY } from "./journey";
import { accountProfileProgress, startupProfileProgress } from "../../../utils/profilePercentageCalculation";

export default function PlatformJourney({ open, onClose }) {
	const history = useHistory();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [expanded, setExpanded] = useState("panel1");
	const [data, setData] = useState(PLATFORM_JOURNEY);

	useEffect(() => {
		getJourneyData();
	}, []);

	const getJourneyData = async () => {
		try {
			const journeyData = await services.getPlatformJourneyApi();
			const userDetailsResp = await services.getUserProfile();
			const accountProfilePercentage = accountProfileProgress(userDetailsResp?.data?.data).totalPercentage;
			const startupProfilePercentage = startupProfileProgress(userDetailsResp?.data?.data?.companyModel).totalPercentage;
			data[0].items[0].disabled = Math.round(accountProfilePercentage || 0) === 100 ? false : true;
			data[0].items[1].disabled = Math.round(startupProfilePercentage || 0) === 100 ? false : true;

			data[1].items[0].disabled = !journeyData?.data?.data?.isPitchDeckUploaded;
			data[1].items[1].disabled = !journeyData?.data?.data?.isIncorporated;
			data[1].items[2].disabled = !journeyData?.data?.data?.isStartupGridUpdated;

			data[2].items[0].disabled = !journeyData?.data?.data?.hasMessagedInvestor;
			data[2].items[1].disabled = !journeyData?.data?.data?.hasScheduledWithMentor;
			data[2].items[2].disabled = !journeyData?.data?.data?.hasPostedToFeed;
			data[2].items[3].disabled = !journeyData?.data?.data?.hasViewedEvents;

			data[3].items[0].disabled = !journeyData?.data?.data?.hasCompletedAPowerUp;
			data[3].items[1].disabled = !journeyData?.data?.data?.hasSearchedKnowledgeCenter;
			data[3].items[2].disabled = !journeyData?.data?.data?.hasPostedToDiscoveryBoard;

			setData([...data]);
		} catch (e) {
			console.log("getJourneyData error", e);
		}
	};

	const handleChange = (panel) => (event, newExpanded) => {
		setExpanded(newExpanded ? panel : false);
	};

	const handleClose = () => {
		onClose();
	};

	const handleRedirect = (route, title) => {
		history.push(route);

		handleMixPanel("Platform Journey", {
			Title: title,
			Route: route,
		});
	};

	const NeedHelp = () => {
		const handleRedirectHomePage = () => {
			// Before calling this, Stonly tour modal is opened first.
			console.log("----handleRedirectHomePage----");
			onClose();
		};

		return (
			<Box display="flex" flexDirection="column" gap={1.25} mt={-1}>
				<Typography variant="custom070" color="#7B61FF" sx={{ cursor: "pointer" }} onClick={handleRedirectHomePage}>
					Founder Home Page
				</Typography>{" "}
				{/* <Link underline="none" href="#">
				<Typography variant="custom070" color="#7B61FF">
					Startup Profile Guided Tour
				</Typography>{" "}
			</Link>
			<Link underline="none" href="#">
				<Typography variant="custom070" color="#7B61FF">
					Community Guided Tour
				</Typography>{" "}
			</Link> */}
			</Box>
		);
	};

	return (
		<CommonSlideDialog
			open={open}
			onClose={onClose}
			title={<Typography variant="Text/xl/Semibold">StartupOS Platform Guide</Typography>}
			maxWidth="sm"
			PaperProps={{
				sx: {
					position: "fixed",
					bottom: 0,
					right: 0,
					m: 0,
					px: 2,
					py: 2,
					borderRadius: "30px",
					width: isSmallScreen ? "100%" : theme.breakpoints.values.sm,
				},
			}}
			dialogAction={
				<Box display="flex" sx={{ pr: 3 }} alignItems="center">
					<Box>
						<Button
							sx={{
								borderRadius: "100px",
								background: "#7B61FF",
								color: "#fff",
							}}
							onClick={handleClose}
						>
							Close
						</Button>
					</Box>
				</Box>
			}
		>
			<Box>
				<Box mt={2} mb={4}>
					<Typography variant="subhead1">
						Explore our guide or create your own path! Our guide will help you navigate through key startup milestones, from ideation to fundraising
						to growth. Both paths offer access to a community of investors and mentors who can offer advice and support.
					</Typography>
				</Box>

				{data.map((journey) => (
					<Box key={journey.id} mt={6}>
						<Box mb={1}>
							<Typography variant="custom070">{journey.title}</Typography>
						</Box>
						{journey.items.map((item) => (
							<Accordion key={item.id} expanded={expanded === item.id} onChange={handleChange(item.id)}>
								<AccordionSummary expanded={expanded === item.id ? "true" : "false"}>
									<Box
										ml={1.5}
										width="100%"
										display="flex"
										alignItems="center"
										justifyContent="space-between"
										onClick={() => (item?.disableArrow !== true ? handleRedirect(item.route, item.title) : handleChange(item.id))}
									>
										<Box display="flex" alignItems="center" gap={1.5}>
											<Typography variant="subhead1">{item.title}</Typography>
											{item?.disableArrow !== true && (
												<IconButton sx={{ p: 0 }}>
													<ArrowForwardIcon sx={{ color: "#000" }} />
												</IconButton>
											)}
										</Box>
										{item?.disableArrow !== true && <img src={item.disabled ? CircleDisabledIcon : CircleIcon} alt="CircleIcon" />}
									</Box>
								</AccordionSummary>
								<AccordionDetails>
									<Typography variant="subhead1">{item.title !== "StartupOS Guided Tours" ? item.desc : <NeedHelp />}</Typography>
								</AccordionDetails>
							</Accordion>
						))}
					</Box>
				))}
			</Box>
		</CommonSlideDialog>
	);
}
