import React, { useEffect, useMemo, useState } from "react";
import { useHistory, generatePath } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Avatar, Button, styled, Tooltip, tooltipClasses, ClickAwayListener } from "@mui/material";
import PremiumIcon from "../Assets/new-home-premium-icon.svg";
import PrimaryIcon from "../Assets/new-home-powerup-icon.svg";
import SecondaryIcon from "../Assets/new-home-startuplabs-icon.svg";
import ThirdIcon from "../Assets/new-home-activity-icon.svg";
import VouchImg from "../Assets/new-home-vouch.png";
import SurveyIcon from "../../../../components/Investor/Workspace/Assets/new-home-survey-icon.svg";
import InterviewsIcon from "../../../../components/Investor/Workspace/Assets/new-home-interviews-icon.svg";
import NextIcon from "../../../../components/Investor/Workspace/Assets/new-home-powerup-next-icon.svg";
import HeartIcon from "../../../../components/Investor/Workspace/Assets/new-home-heart-icon.svg";
import ROUTES_PATH from "../../../../constants/routes";
import PaymentPlanDialog from "../../../PaymentPlanDialog";
import { getRoleValue } from "../../../../utils/commonFunctions";
import { handleMixPanel } from "../../../../utils/mixPanelEventHandling";
import { setNetworkingDashboardData } from "../../../../modules/actions/networkingActions";
import { USER_TYPES } from "../../../../utils/userTypes";
import { useWindowSize } from "../../../../utils/windowResize";

const CustomTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)({
	[`& .${tooltipClasses.tooltip}`]: {
		// marginRight: "100px",
		// marginLeft: "100px",
		marginBottom: "60px",
		borderRadius: "30px",
		padding: 0,
	},
	[`.MuiTooltip-popper`]: {
		borderRadius: "30px",
	},
});

export default function HomeCard({
	variant = "primary",
	id,
	isSpecialVariant = false,
	isSaved = false,
	uuid,
	title = "",
	subTitle = "",
	redirectUrl = "",
	cardType,
	cardStatus,
	taskStatus,
	linkedEntityId,
	desc = "",
	imgSrc = "",
	isAI = false,
	isPremium = false,
	onDetail,
	onHeart,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const size = useWindowSize();
	const isAdmin = useSelector((store) => store?.auth?.isAdmin);
	const isPremiumPlanAccess = useSelector((store) => store?.auth?.isPremiumPlanAccess);
	const [openValidationDetail, setOpenValidationDetail] = useState(false);
	const [isHeart, setIsHeart] = useState(false);
	const [showPlanModal, setShowPlanModal] = useState(false);
	const authData = useSelector((state) => state?.auth);

	useEffect(() => {
		if (variant === "partner") {
			setIsHeart(isSaved);
		}
	}, [variant, isSaved]);

	const tooltipDirection = useMemo(() => {
		return size.width > 600 ? "left-end" : "top";
	}, [size]);

	const handleTooltipClose = () => {
		setOpenValidationDetail(false);
	};

	const handleTooltipOpen = () => {
		setOpenValidationDetail(!openValidationDetail);
	};

	const isSurvey = () => {
		return (
			title === "Net Promoter Score Survey" ||
			title === "Product Market Fit Survey" ||
			title === "Category Awareness Survey" ||
			title === "Likert Scale Survey" ||
			title === "Logo Testing Survey" ||
			title === "Ad Testing Survey"
		);
	};

	const getSurveyId = () => {
		let pluginUuidData = uuid ? uuid : linkedEntityId;

		return pluginUuidData.replace("SURVEY_", "");
	};

	const handleHeart = async () => {
		setIsHeart(!isHeart);
		onHeart(id, isHeart, title);
	};

	const handleStart = () => {
		handleMixPanel("Clicked Card", {
			"Clicking user name": authData?.userName,
			"Clicking user id": authData?.userId,
			"Clicked card title": title,
			"Clicked linked activity id": linkedEntityId,
		});
		// if ((isPremium && (isPremiumPlanAccess || isAdmin)) || !isPremium) {
			if (cardType === "POWERUP_BUILDER") {
				if (cardStatus && cardStatus !== "COMING_SOON" && cardStatus !== "NOT_READY") {
					history.push({
						pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_BUILDER_DETAILS, {
							id: linkedEntityId,
						}),
					});
				}
			} else if (cardType === "POWERUP") {
				if (cardStatus && cardStatus !== "COMING_SOON" && cardStatus !== "NOT_READY") {
					history.push({
						pathname: generatePath(ROUTES_PATH.WORKSPACE_POWERUP_DETAILS, {
							id: linkedEntityId,
						}),
					});
				}
			} else if (cardType === "SURVEY") {
				if (title === "Price Sensitive Survey" || title === "Price Sensitivity Survey") {
					// do nothing
				} else if (title === "Mentor Checkin") {
					history.push({
						pathname: generatePath("/workspace/plugin/mentor-check-in"),
					});
				} else if (title === "Mentor Plugin: Fine Tuning" || title === "Fine Tuning") {
					history.push({
						pathname: generatePath("/workspace/plugin/mentor-check-in"),
					});
				} else if (
					title === "Idea Validation Interview" ||
					title === "Market Test: Persona Interview" ||
					title === "Market Test: Discovery Interview"
				) {
					history.push({
						pathname: generatePath(ROUTES_PATH.MARKET_TEST, {
							id: linkedEntityId,
						}),
					});
				} else if (isSurvey(title)) {
					history.push({
						pathname: generatePath(ROUTES_PATH.TOOL_CONTROLLER, {
							id: getSurveyId(),
						}),
					});
				}
			} else if (cardType === "ACTIVITY") {
				if (redirectUrl.includes("startupos.com")) {
					if (title === "Message an Investor") {
						dispatch(
							setNetworkingDashboardData({
								currentTab: USER_TYPES.INVESTOR_USER,
							})
						);
					} else if (title === "Meet a mentor") {
						dispatch(
							setNetworkingDashboardData({
								currentTab: USER_TYPES.MENTOR_USER,
							})
						);
					}
					window.location.href = redirectUrl;
				} else {
					window.open(redirectUrl, "_blank");
				}
			}
		// } else if (isPremium === true && !isPremiumPlanAccess) {
		// 	setShowPlanModal(true);
		// }
	};

	const handleClosePlanModal = () => {
		setShowPlanModal(false);
	};

	const handleEvent = () => {
		if (variant === "secondary") {
			onDetail(uuid);
		}
	};

	const avatar = useMemo(() => {
		let avatarIcon = variant === "primary" ? PrimaryIcon : variant === "secondary" || variant === "coming" ? SecondaryIcon : ThirdIcon;

		let avatarColor = variant === "primary" ? "#7B61FF" : variant === "secondary" || variant === "coming" ? "#095CED" : "#DE0085";

		if (variant === "Survey" || (cardType === "SURVEY" && variant === "coming")) {
			avatarIcon = SurveyIcon;
			avatarColor = "#DE0085";
		} else if (variant === "Interviews" || (cardType === "INTERVIEW" && variant === "coming")) {
			avatarIcon = InterviewsIcon;
			avatarColor = "#000000";
		}

		if (isSpecialVariant) {
			avatarIcon = PrimaryIcon;
			avatarColor = "#7B61FF";
		}

		if (title === "Business Insurance for Startups") {
			return (
				<Box display="flex" alignItems="center" gap={1.5}>
					<Avatar src={avatarIcon} sx={{ bgcolor: avatarColor, width: "20px", height: "20px", padding: "10px" }} />
					<img src={VouchImg} alt="Vouch" />
				</Box>
			);
		}

		return <Avatar src={avatarIcon} sx={{ bgcolor: avatarColor, width: "20px", height: "20px", padding: "10px" }} />;
	}, [variant, isSpecialVariant, cardType, title]);

	const bgStyle = useMemo(() => {
		if (variant === "third") {
			return {
				display: "flex",
				justifyContent: "flex-end",
			};
		}

		return {
			backgroundImage: `url(${imgSrc})`,
			backgroundSize: "cover",
			backgroundPosition: "center",
			backgroundRepeat: "no-repeat",
		};
	}, [imgSrc, variant]);

	const btnTitle = useMemo(() => {
		if (cardType === "POWERUP" || cardType === "POWERUP_BUILDER") {
			return "Launch";
		} else {
			if (title === "Meet a mentor") {
				return "Schedule";
			} else if (title === "Incorporate with Firstbase.io") {
				return "View";
			} else if (title === "Explore Our Marketplace") {
				return "View";
			} else if (title === "Message an Investor") {
				return "Message";
			} else if (title === "Sign Up for an Event") {
				return "View";
			} else if (title === "Explore Our Resource Center") {
				return "View";
			} else if (title === "Explore Our Network") {
				return "View";
			} else {
				if (cardStatus === "PUBLISHED") {
					if (taskStatus === "STARTED") {
						return "In Progress";
					} else if (taskStatus === "COMPLETED") {
						return "Completed";
					} else {
						return "Start";
					}
				}
			}
		}

		return "Start";
	}, [cardStatus, taskStatus]);

	return (
		<Box
			position="relative"
			display="flex"
			flexDirection="column"
			width="319px"
			sx={{
				gap: 2,
				padding: "32px",
				background: "#fff",
				borderRadius: "24px",
				"&:hover": { cursor: "pointer" },
				boxShadow: "0px 10px 25px 0px #0000000D",
			}}
			onClick={handleEvent}
		>
			<Box display="flex" alignItems="center" justifyContent="space-between">
				{avatar}
				{/* {isPremium && (
					<Box
						display="flex"
						alignItems="center"
						gap={0.5}
						sx={{ background: "#DE0487", padding: "4px 10px 4px 10px", borderRadius: "100px", cursor: "pointer" }}
					>
						<img src={PremiumIcon} alt="Premium" />
						<Typography variant="label_large" color="#FFFFFF">
							Premium
						</Typography>
					</Box>
				)} */}
				{/* <Box
					display="flex"
					alignItems="center"
					gap={0.5}
					sx={{ background: "#DE0487", padding: "4px 10px 4px 10px", borderRadius: "100px", cursor: "pointer" }}
				>
					<img src={PremiumIcon} alt="Premium" />
					<Typography variant="label_large" color="#FFFFFF">
						Premium
					</Typography>
				</Box> */}
				{variant === "partner" && <img src={isHeart ? HeartIcon : NextIcon} alt="Heart" onClick={handleHeart} />}
			</Box>

			{variant !== "third" && (
				<Typography variant="main_title" mb={1.5}>
					{title}
				</Typography>
			)}
			<Box position="relative" height={variant !== "third" ? "260px" : "318px"} borderRadius={3} sx={bgStyle}>
				{isAI && (
					<Box position="absolute" top="20px" left="20px" sx={{ background: "#fff", padding: "4px 10px 4px 10px", borderRadius: "100px" }}>
						<Typography variant="label_large">AI Powered</Typography>
					</Box>
				)}
				{variant === "third" && (
					<Box
						position="relative"
						height="134px"
						width="116px"
						sx={{
							backgroundImage: `url(${imgSrc})`,
							backgroundSize: "cover",
							backgroundPosition: "center",
							backgroundRepeat: "no-repeat",
						}}
					/>
				)}
				{variant === "third" && (
					<Box
						position="absolute"
						bottom="32px"
						left="-32px"
						sx={{ p: "16px 32px 16px 32px", background: "#DE0085", borderRadius: "0px 50px 50px 0px", whiteSpace: "nowrap" }}
					>
						<Typography variant="poppinsSemiBold20" color="#fff">
							{title}
						</Typography>
					</Box>
				)}
				{variant === "third" && subTitle !== "" && (
					<Box position="absolute" bottom="4px" left="0px">
						<Typography variant="Text/xs/Semibold" color="#000">
							{subTitle}
						</Typography>
					</Box>
				)}
			</Box>
			<Box display="flex" gap={2}>
				{variant !== "secondary" ? (
					<>
						<ClickAwayListener onClickAway={handleTooltipClose}>
							<CustomTooltip
								disableHoverListener
								disableTouchListener
								// disableFocusListener
								placement={tooltipDirection}
								onClose={handleTooltipClose}
								open={openValidationDetail}
								title={
									<Box
										sx={(theme) => ({
											background: "#FFFFFF",
											width: "340px",
											boxShadow: "0px 10px 85px rgba(0, 0, 0, 0.09)",
											borderRadius: "30px",
											p: "30px",
											textAlign: "left",
											[theme.breakpoints.down("sm")]: {
												width: "240px",
											},
										})}
									>
										<Typography variant="custom070" color="#000" sx={{ display: "block", mb: 2 }}>
											{title}
										</Typography>
										<Typography variant="Text/sm/Regular">{desc}</Typography>
									</Box>
								}
							>
								<BtnAction color="secondary" sx={{ background: "#F2F4F7", color: "#344054" }} onClick={handleTooltipOpen}>
									Details
								</BtnAction>
							</CustomTooltip>
						</ClickAwayListener>

						{variant !== "partner" &&
							(variant === "coming" ? (
								<BtnAction variant="text" sx={{ whiteSpace: "nowrap", "&:hover": { color: "#667085" } }}>
									Coming Soon
								</BtnAction>
							) : (
								<BtnAction onClick={handleStart} sx={{ background: "#7B61FF" }}>
									{btnTitle}
								</BtnAction>
							))}
					</>
				) : (
					<BtnAction color="secondary" sx={{ background: "#F2F4F7", color: "#344054" }}>
						PowerPack Details
					</BtnAction>
				)}
			</Box>
			{showPlanModal && <PaymentPlanDialog open={showPlanModal} isRedirect={false} onClose={handleClosePlanModal} />}
		</Box>
	);
}

const BtnAction = styled(Button)((props) => ({
	borderRadius: "100px",
	maxWidth: "inherit",
	height: "48px",
	fontSize: "16px",
	fontWeight: 600,
	lineHeight: 24,
	"&:hover": {
		color: "#fff",
	},
}));
